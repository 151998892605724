import { AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild } from "@angular/core";
import * as Chart from "chart.js";
import { ChartOptions, ChartType } from 'chart.js';
import { Vehicle } from "../../model/vehicle.object";

@Component({
  selector: "vehicle-status",
  templateUrl: './r-vehicle-status-graph.component.html',
  styleUrls: ['./r-vehicle-status-graph.component.css']
})
export class RVehicleStatusGraphComponent implements AfterViewInit, OnDestroy {

  public statusChart: Chart;
  @ViewChild('statusChart', {static: false}) _chartRef: ElementRef;

  private _width: number = 50;
  @Input()
  public set width(value: number) {
    this._width = value;
  }
  public get width(): number {
    return this._width;
  }
  
  private _modalMode: boolean = false;
  @Input()
  public set modalMode(value: boolean) {
    this._modalMode = value;
  }
  public get modalMode(): boolean {
    return this._modalMode;
  }

  private _vehicle: Vehicle;
  get vehicle(): Vehicle {
    return this._vehicle;
  }
  @Input()
  set vehicle(vehicle: Vehicle) {
    this._vehicle = vehicle;
    this._dataSets = this.createDataSets();
    this.initStatusChart();

    // redraw each minute
    this._graphRedrawInterval = window.setInterval(
      () => {
        this._dataSets = this.createDataSets();
        this.initStatusChart();
      },
      60000
    );
  }

  private _graphRedrawInterval: any;
  private _dataSets = null;
  get dataSets(): any {
    if (!this._dataSets) {
      this._dataSets = this.createDataSets();
    }
    return this._dataSets;
  }

  private _activeHover = null;
  get activeHover(): string {
    return this._activeHover;
  }


  constructor() {
    // this.barChartOptions.onHover = (event, items) => {
    //   this.onPartHover(event, items);
    // }
  }

  ngAfterViewInit(): void {
    this._dataSets = this.createDataSets();
    this.initStatusChart();
  }

  ngOnDestroy(): void {
    if (this._graphRedrawInterval) {
      window.clearInterval(this._graphRedrawInterval);
    }
  }

  initStatusChart(): void {
    // reference itself
    let self = this;

    if (this.statusChart) {
      this.statusChart.destroy();
      this.statusChart = null;
    }
    if (this._chartRef) {
      this.statusChart = new Chart(this._chartRef.nativeElement, {
        type: 'pie',
        data: {
          labels: [],
          datasets: this._dataSets
        },
        options: {
          aspectRatio: 1,
          cutoutPercentage: 0,
          responsive: false,
          animation: {
            duration: 2000
          },
          layout: {
            padding: {
              left: 1,
              right: 1,
              top: 1,
              bottom: 1
            }
          },
          legend: {
            display: false,
          },
          tooltips: {
            // chart js truncates tooltips outside of canvas - using boostrap tooltips instead..
            enabled: false,
          },
          onHover: (event, items) => {
            if (items && items.length) {
              let item: any = items[0];
              if (item._index == 0 || item._index == 1) {
                this._activeHover = 'fuel';
              }
              else if (item._index == 2 || item._index == 3) {
                this._activeHover = 'mobile';
              }
              else if (item._index == 4 || item._index == 5) {
                this._activeHover = 'load';
              }
            }
          }
        }
      });
    }
  }

  private createDataSets(): Array<any> {
    if (!this._vehicle) {
      return null;
    }
    let tankPercentage = this.vehicle.tank_level;
    if (!tankPercentage) {
      tankPercentage = this.vehicle.fuelPercentage | 0;
    }
    let emptyTankPercentage = 100 - tankPercentage;

    let totalLoad = this.vehicle.tonnage_percentage | 0;
    let emptyLoad = 100 - totalLoad;

    let mobileBatteryCharged = 0;
    if (this.vehicle.health) {
      mobileBatteryCharged = this.vehicle.health.battery_level;
    }
    let emptyMobileBattery = 100 - mobileBatteryCharged;
    // this._labels = [];
    return [{
      data: [
        emptyTankPercentage,
        tankPercentage,
        emptyMobileBattery,
        mobileBatteryCharged,
        totalLoad,
        emptyLoad
      ],
      backgroundColor: [
        'rgba(0, 0, 0, 0.0)',
        '#AACCFF',
        'rgba(0, 0, 0, 0.0)',
        '#DDAFE9',
        '#C6E9AF',
        'rgba(0, 0, 0, 0.0)',
      ],
      borderColor: 'black',
      borderWidth: '1px'
    }];
  }

  // onPartHover(event, activeItems) {
  //   this._activeHover = null;
  //   if (activeItems && activeItems.length) {
  //     let item = activeItems[0];
  //     if ([0, 1].indexOf(item._index) > -1) {
  //       this._activeHover = 'fuel';
  //     }
  //     if ([4, 5].indexOf(item._index) > -1) {
  //       this._activeHover = 'load';
  //     }
  //     if ([2, 3].indexOf(item._index) > -1) {
  //       this._activeHover = 'mobile';
  //     }
  //   }
  // }


  // public barChartOptions: ChartOptions = {
  //   animation: {
  //     duration: 0,
  //   },
  //   responsive: false,
  //   layout: {
  //     padding: {
  //       left: 1,
  //       right: 1,
  //       top: 1,
  //       bottom: 1
  //     }
  //   },
  //   legend: {
  //     display: false,
  //   },
  //   aspectRatio: 1,
  //   cutoutPercentage: 0,
  //   tooltips: {
  //     mode: 'nearest',
  //     enabled: true,
  //     cornerRadius: 4,
  //     bodyFontSize: 11,
  //     displayColors: false,
  //     // caretSize: 4,
  //     // xPadding: 16,
  //     // yPadding: 10,
  //     // backgroundColor: 'rgba(0, 150, 100, 0.9)',
  //     // titleFontStyle: 'normal',
  //     // titleMarginBottom: 15
  //     callbacks: {
  //       title: function() { return null; },
  //       label: function(tooltipItem, data) {
  //         if (tooltipItem.index >= 0) {
  //           if (tooltipItem.index == 0 || tooltipItem.index == 1) {
  //             return 'fuel';
  //           }
  //           else if (tooltipItem.index == 2 || tooltipItem.index == 3) {
  //             return 'mobile';
  //           }
  //           else if (tooltipItem.index == 4 || tooltipItem.index == 5) {
  //             return 'Vytížení vozidla: ' + this._vehicle.tonnage_percentage + '%';
  //           }
  //         }
  //         return '';
  //         // if (tooltipItem.index >= 0 && tooltipItem.index < tmpGroups.length) {
  //         //   let result: Array<string> = [];
  //         //   // company
  //         //   result.push(tmpGroups[tooltipItem.index].compBookCompany);
  //         //   // empty row here
  //         //   result.push('');
  //         //   // formatted price
  //         //   result.push(
  //         //     'Cena celkem: ' +
  //         //     Numbers.toOurPriceFormat(tmpGroups[tooltipItem.index].obligationsContractedPrice) +
  //         //     ' ' + self.homeCurrency
  //         //   );
  //         //   return result;
  //         // }
  //         // return '';
  //       }
  //     }
  //   }
  // };


  // public barChartType: ChartType = 'pie';
  // public barChartLegend = true;
  // public barChartPlugins = [];
  // private _graphRedrawInterval: number;

  
  // private _dataSets = null;
  // get dataSets(): any {
  //   if (!this._dataSets) {
  //     this._dataSets = this.createDataSets();
  //   }
  //   return this._dataSets;
  // }

  // private _labels = null;
  // get labels(): any {
  //   return this._labels;
  // }


}