import { ChangeDetectorRef, Component, Input } from "@angular/core";
import { Label } from "ng2-charts";
import { ChartOptions, ChartType } from 'chart.js';

// import { VehicleService } from "../../service/vehicle.service";
import { VehicleNewService } from "src/app/service/vehicle-new.service";
import { AetrHistoryCollectionObject } from "../../model/aetr-history-collection.object";
import { Vehicle } from "../../model/vehicle.object";
import { DateTools } from "../../tools/DateTools";
import { AetrActivityName } from "src/app/config";


@Component({
  selector: 'div.vehicle-activity-component',
  templateUrl: './r-vehicle-activity.component.html',
  styleUrls: ['./r-vehicle-activity.component.css']
})
export class RVehicleActivityComponent {

  public barChartOptions: ChartOptions = null;
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [];
  private _range: Label[] = [];
  private _dataSet: Array<any> = [];

  private _vehicle: Vehicle;
  get vehicle(): Vehicle {
    return this._vehicle;
  }
  @Input()
  set vehicle(value: Vehicle) {
    this._vehicle = value;
    if (!(this._vehicle.aetrHistoryLazyLoad instanceof Function)) {
      this._vehicle.aetrHistoryLazyLoad = this._vehicleService.createAetrHistoryLazyload(this._vehicle);
      // custom detection signal
      this.detectChanges();
    }
  }

  ngOnInit() {
  }

  constructor(
    private _vehicleService: VehicleNewService,
    private _cdr: ChangeDetectorRef
  ) {
    this.barChartOptions = {
      responsive: true,
      // We use these empty structures as placeholders for dynamic theming.
      scales: {
        xAxes: [{
          stacked: true
        }], 
        yAxes: [{
          stacked: true,
          ticks: {
            max: 24,
            min: 0,
          }
        }]
      },
      tooltips: {
        mode: 'nearest',
        enabled: true,
        callbacks: {
          label(tooltipItem: Chart.ChartTooltipItem, data: Chart.ChartData): string | string[] {
            let value = tooltipItem.value;
            if (data.datasets[tooltipItem.datasetIndex]['labelType'] === 'P') {
              value = data.datasets[tooltipItem.datasetIndex]['modified'][tooltipItem.index];
            }
            let label = AetrActivityName[data.datasets[tooltipItem.datasetIndex]['labelType']] + ': ';
            label += DateTools.getHumanReadHoursAndMinutes(parseFloat(value) * 60 * 60 * 1000);
            return label;
          }
        },
        cornerRadius: 4,
        caretSize: 4,
        xPadding: 16,
        yPadding: 10,
        backgroundColor: 'rgba(0, 150, 100, 0.9)',
        titleFontStyle: 'normal',
        titleMarginBottom: 15
      }
    }
    // custom detection signal
    this.detectChanges();
  }
  
  detectChanges(): void {
    // detect changes 100 ms after change
    window.setTimeout(
      () => {
        this._cdr.detectChanges();
      }, 100
    );
  }

  get displayRange(): Label[] {
    if (this._range.length === 0) {

      let range: Label[] = [];
      for (let i = 20; i > 0; i--) {
        let day = new Date();
        day.setDate(day.getDate() - i);
        range.push(AetrHistoryCollectionObject.dateToString(day))
      }
      range.push(AetrHistoryCollectionObject.dateToString(new Date));
      this._range = range;
    }
    return this._range;
  }

  get dataSets(): Array<any> {
    let toReturn = [];
    if (this._dataSet.length === 0 && this.vehicle.aetrHistory() && this.vehicle.aetrHistory().getActivityByDays().length > 0) {
      let dataSet = [
        { data: [], 
          labelType: 'D', 
          label: '', 
          backgroundColor: "#2873ae", 
          hoverBackgroundColor: "#2873ae"
        },
        {
          data: [],
          labelType: 'P',
          modified: [],
          label: '',
          backgroundColor: "#f0ad4e",
          hoverBackgroundColor: "#f0ad4e"
        },
        {
          data: [], 
          labelType: 'S', 
          label: '', 
          backgroundColor: "grey", 
          hoverBackgroundColor: "grey"
        },
      ];
      this.displayRange.forEach(
        (day, index) => {
          let activity = this.vehicle.aetrHistory().getActivityByDateString(day.toString());
          let dayTotal = 0;
          let dayTypes = [];
          if (activity) {
            activity.percentage.forEach(
              percentage => {
                dataSet.forEach(
                  set => {
                    if (dayTypes.indexOf(percentage.type) === -1) {
                      dayTypes.push(percentage.type);
                    }
                    if (percentage.type === 'P' && set.labelType === 'P') {
                      set.modified[index] = percentage.timeModified / 60 / 60;
                    }
                    if (set.labelType === percentage.type) {
                      set.data.push(percentage.time / 60 / 60);
                      dayTotal += percentage.time / 60 / 60;
                    }
                  }
                )
              }
            );
            if (dayTypes.length === 1 && dayTypes.indexOf('S') > -1) {
              dataSet[2].data[index] = dayTotal = 24;
            }
            if (dayTypes.length < 3) {
              dataSet.forEach(
                set => {
                  if (set.data.length - 1 != index) {
                    set.data.push(0);
                  }
                }
              )
            }
          } 
          else {
            dataSet[2].data.push(24);
          }
          if (dayTotal < 24) {
            dataSet[2].data[index] += (24 - dayTotal);
          }
        }
      );
      dataSet.forEach(
        set => {
          set.label = AetrActivityName[set.labelType];
        }
      );
      this._dataSet = dataSet;
      toReturn = this._dataSet;
    } 
    else if (this._dataSet.length > 0) {
      toReturn = this._dataSet;
    }
    return toReturn;
  }

  // fillPercentage(perc: number): number {
  //   return (100 - perc);
  // }

  // activityDuration(activity: any) {
  //   if (activity.type)
  //     return DateTools.getHumanReadHoursAndMinutes(isNaN(activity.time) ? 0 : activity.time * 1000);
  // }
}
