import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IS_DEMO, ItineraryType, ServiceConfiguration } from "../config";
import { AuthenticationService } from './authentication.service';
import { HttpClientService } from './http-client.service';
import { NotificationService } from './notification-service';
import { WarehouseEvent } from '../model/warehouse-event.object';

@Injectable({
  providedIn: 'root'
})
export class WarehouseService {

  private _loading: boolean = false;

  constructor(
    private _http: HttpClientService,
    private _authServ: AuthenticationService,
    private _notificationServ: NotificationService
  ) {
  }
  

  // method for getting all warehouse events of selected car in specified time interval
  // NOT USING ATTRIBUTES BUT CUSTOM INSTANCES
  // tf - time_from, tt - time_to, ck - car_key, dk - driver_key, on - order_number, ok - order_key
  getEvents(filterObj: any): Observable<Array<WarehouseEvent>> {
    let eventsSubject: BehaviorSubject<Array<WarehouseEvent>> = new BehaviorSubject(null);

    if (IS_DEMO || (!IS_DEMO && this._authServ.isAuthenticated())) {
      let url: string = ServiceConfiguration.warehouseEvent.api;
      // add url query
      if (filterObj) {
        let filterKeys: Array<string> = Object.keys(filterObj);
        filterKeys.forEach(
          (key, index) => {
            if (index == 0 && filterObj[key]) {
              url += '?' + key + '=' + filterObj[key];
            }
            else if (filterObj[key]) {
              url += '&' + key + '=' + filterObj[key];
            }
          }
        );
      }

      this._loading = true;

      this._http.get(url).subscribe(
        response => {
          let wevents = this.buildWarehouseEventsFromData(response);
          eventsSubject.next(wevents);
          this._loading = false;
        },
        error => {
          //handle error
          console.log(error);
          this._loading = false;
        },
      );
    }

    return eventsSubject.asObservable();
  }


  // method for creating new warehouse event
  createEvent(warehouseEvent: WarehouseEvent): Observable<WarehouseEvent> {
    let createEvent: BehaviorSubject<WarehouseEvent> = new BehaviorSubject(null);

    if (IS_DEMO || (!IS_DEMO && this._authServ.isAuthenticated())) {
      let url: string = ServiceConfiguration.warehouseEvent.api;

      this._http.post(url, warehouseEvent.apiObject).subscribe(
        response => {
          // alert
          let alertSuccess: string = $localize`Položka byla úspěšně `
          alertSuccess += warehouseEvent.type == ItineraryType.UNLOADING ? $localize`naskladněna.` : $localize`vyskladněna.`;
          this._notificationServ.alert(alertSuccess, 'success', 4000);
          
          // observable next
          createEvent.next(this.buildWarehouseEvent(response));
        },
        error => {
          // handle error
          console.log(error);
          // alert
          let alertError: string = $localize`Chyba při vytváření události `
          alertError += warehouseEvent.type == ItineraryType.UNLOADING ? $localize`naskladnění.` : $localize`vyskladnění.`;
          this._notificationServ.alert(alertError, 'error', 4000);
        }
      );
    }

    return createEvent.asObservable();
  }


  // method for creating events array
  private buildWarehouseEventsFromData(data: Array<any>): any {
    let wevents: Array<WarehouseEvent> = [];
    data.forEach(
      o => {
        let wevent: WarehouseEvent = this.buildWarehouseEvent(o);
        wevents.push(wevent);
      }
    );
    return wevents;
  }

  // method for creating a single event object
  private buildWarehouseEvent(o: any): WarehouseEvent {
    let wevent: WarehouseEvent = new WarehouseEvent();
    for (let key in o) {
      wevent[key] = o[key];
    }
    return wevent;
  }

}
