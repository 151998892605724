<div class="col-12 dashboard-head vehicle-list align-middle">
  <i class="rightMenuImg pointer" id="dashboard-carList" (click)="setDashboardFull()"
  ngbTooltip="Přepnout na FULL verzi zobrazení vozidel" 
  placement="bottom auto" container="body" i18n-ngbTooltip></i>
  <span *ngIf="!notConnected" class="float-left">
    {{lastVehicleLoad | date: 'HH:mm'}}
  </span>
  <span *ngIf="notConnected" class="float-left font-weight-bold" 
  style="cursor:default; font-size:30px; line-height:45px; color:red"
  ngbTooltip="Chyba spojení" container="body" placement="bottom auto" i18n-ngbTooltip>
    {{'{!}'}}
  </span>
  <div *ngIf="!loadingVehicles" id="dashboard-refresh" class="rightMenuImg pointer"
  (click)="refreshVehicles()" container="body" placement="bottom" 
  ngbTooltip="Obnovit data pro všechna auta" i18n-ngbTooltip></div>
  
  <div *ngIf="!loadingVehicles" id="dashboard-people" class="rightMenuImg pointer"
  container="body" placement="bottom" ngbTooltip="Filtr podle dispečera/auta"
  data-toggle="modal" data-target="#vehicleFilterModal" i18n-ngbTooltip></div>
  <!-- (click)="toggleFilterCollapse()" -->
</div>

<div class="w-100 dashboard-body">
  <div *ngIf="!validAccess">
    <div class="container text-center py-3">
      <h5>
        <ng-container i18n>Váš přístup vypršel. Máte-li zájem o další využívání, předplaťte si přístup</ng-container>
        <a href="javascript:void(0)" (click)="openSubsNewTab()" i18n>zde</a>.
      </h5>
    </div>
  </div>

  <div *ngIf="tariffOnlyAgenda">
    <div class="container text-center py-3">
      <h5 i18n>Váš tarif neumožňuje zobrazení vozidel</h5>
    </div>
  </div>

  <div *ngIf="loadingVehicles" class="spinner-wrapper pt-5 pb-4">
    <div class="spinner-border text-primary" style="width:3rem; height:3rem;" role="status">
      <span class="sr-only"></span>
    </div>
  </div>

  <div *ngIf="!loadingVehicles && validAccess && !tariffOnlyAgenda">
    <!-- header -->
    <div class="row vehicles font-weight-bold">
      <div class="col-2 vehicle-col pl-2" i18n>Stav</div>
      <div class="col-1 vehicle-col text-center" i18n>HW</div>
      <div class="col-3 vehicle-col" i18n>SPZ</div>
      <div class="col-3 vehicle-col" i18n>Řidič</div>
      <div class="col-3 vehicle-col" i18n>Místo</div>
    </div>

    <!-- vehicle rows -->
    <div *ngFor="let v of vehicles; let i=index; trackBy:vehiclesTrackBy;" class="row vehicles">
      <!-- 1st column -->
      <div class="col-2 vehicle-col">
        <div class="d-flex justify-content-center align-items-center">
          <!-- vytizeni -->
          <div class="progress progress-bar-vertical pointer position-relative" style="width: 10px"
          [ngbTooltip]="tonnagePercentageTooltip" placement="right auto" container="body"
          data-toggle="modal" data-target="#vehicleChartModal" (click)="vehicleToChart = v">
            <div class="progress-bar {{v.tonnage_overweight ? 'bg-danger' : 'bg-success'}}" 
            role="progressbar" aria-valuemin="0" aria-valuemax="100"
            [style.height.%]="v.tonnage_progress_bar">
              <span class="sr-only"></span>
            </div>

            <div class="progress-bar-text" style="padding-top: 1px">
              {{v.last_position?.order_number ? v.last_position.order_number.length : 0}}
            </div>
          </div>
          <ng-template #tonnagePercentageTooltip>
            <ng-container i18n>Vytížení vozidla</ng-container>: {{v.tonnage_percentage}}%

            <div class="mt-2" style="white-space: nowrap;">
              <div *ngIf="v.roadExceptions()" class="text-danger">
                Road exceptions info comes here!
              </div>
              <div *ngIf="v.last_position">
                <div>
                  {{v.last_position.cargo_weight}}t&nbsp;&nbsp;
                  {{v.last_position.formatted_time}}
                </div>
                <!-- Display max 10 order_numbers -->
                <!-- Hromadko mel v neporadku desitky nevylozenych, nelze zobrazit v tooltipu -->
                <div *ngFor="let order_number of v.last_position.order_number_truncated" class="mt-1">
                  <div *ngIf="v.last_position.agendas[order_number]">
                    {{order_number}} 
                    <span *ngIf="v.last_position.agendas[order_number].order_comp_book">
                      {{v.last_position.agendas[order_number].order_comp_book.company}}
                    </span>
                    
                    <div *ngFor="let itin of v.last_position.agendas[order_number].itinerary"
                    class="d-flex align-items-center">
                      <img class="icon" [src]="icons[itin.type_marker].url"/>
                      <div class="text-truncate ml-1">{{itin.address}}</div>
                    </div>
                  </div>
                  <!-- there could be some not unloaded agendas from past! -->
                  <div *ngIf="!v.last_position.agendas[order_number]" class="d-flex">
                    <div style="width:70px !important">{{order_number}}</div>
                    <div><strong>! <ng-container i18n>Starší zakázka nebyla vyložena</ng-container> !</strong></div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
          
          
          <!-- nadrz -->
          <div class="progress progress-bar-vertical pointer position-relative"
          [ngbTooltip]="fuelPercentageTooltip" placement="right auto" container="body"
          data-toggle="modal" data-target="#vehicleChartModal" (click)="vehicleToChart = v">
            <div class="progress-bar bg-warning" role="progressbar" 
            aria-valuemin="0" aria-valuemax="100"
            [style.height.%]="v.tankPercentage ? v.tankPercentage : (v.fuelPercentage ? v.fuelPercentage : 0)">
              <span class="sr-only"></span>
            </div>
          </div>
          <ng-template #fuelPercentageTooltip>
            <div style="width: 300px">
              <div class="d-flex">
                <div class="w-50"><ng-container i18n>Stav nádrže</ng-container>:</div>
                <div>{{v.tank_level ? v.tank_level : v.fuelPercentage}}%</div>
              </div>
              <div class="d-flex">
                <div class="w-50"><ng-container i18n>Dojezd</ng-container>:</div>
                <div>{{v.driveRange}}</div>
              </div>
              <div class="d-flex">
                <div class="w-50"><ng-container i18n>Do plné</ng-container>:</div>
                <div>{{v.lastRefuelingInfo}}</div>
              </div>
              <div class="d-flex">
                <div class="w-50"><ng-container i18n>Průměrná spotřeba</ng-container>:</div>
                <div>{{v.consumptionInfo}}</div>
              </div>
            </div>
          </ng-template>

          <!-- GPS jednotka -->
          <div class="progress progress-bar-vertical pointer position-relative"
          [ngbTooltip]="gpsDeviceTooltip" placement="right auto" container="body"
          data-toggle="modal" data-target="#vehicleChartModal" (click)="vehicleToChart = v">
            <div class="progress-bar bg-primary" role="progressbar" 
            aria-valuemin="0" aria-valuemax="100"
            [style.height.%]="v.health ? v.health.battery_level : 0">
              <span class="sr-only"></span>
            </div>
          </div>
          <ng-template #gpsDeviceTooltip>
            <div style="width: 250px">
              <div class="d-flex">
                <div class="w-75"><ng-container i18n>Baterie GPS jednotky</ng-container>:</div>
                <div>{{v.trackingDeviceBatteryChargeLevelFormatted}}</div>
              </div>
              <div class="d-flex">
                <div class="w-75"><ng-container i18n>Běh GPS jednotky</ng-container>:</div>
                <div>{{v.trackingDeviceRuntimeFormatted}}</div>
              </div>
              <div class="d-flex">
                <div class="w-75"><ng-container i18n>Běh aplikace TM</ng-container>:</div>
                <div>{{v.trackingTmRuntimeFormatted}}</div>
              </div>
            </div>
          </ng-template>

          <div>
            <img class="truck-status-icon pointer"
            [src]="v.truck_status_name" (click)="controlShowMapCargo(v)"/>
          </div>
        </div>

        <div class="text-center">
          <div *ngIf="v.last_position; else noPosition" 
          class="pointer" [style.color]="v.last_position.dateDiffColor()"
          data-toggle="modal" data-target="#vehicleChartModal" (click)="vehicleToChart = v">
            <span>&nbsp;{{v.last_position.getDateDiffString}}&nbsp;</span>
          </div>
          <ng-template #noPosition>
            <img src="assets/icons-new/question.svg" alt="question" height="18px"
            ngbTooltip="Žádná data o poslední pozici" container="body" placement="auto" i18n-ngbTooltip/>
          </ng-template>
        </div>
      </div>
      
      <!-- 2nd column -->
      <div class="col-1 vehicle-col text-center">
        <div *ngIf="delayedLoading || v.healthLoading">
          <div class="spinner-grow text-primary" role="status" style="width:1rem; height:1rem;" >
            <span class="sr-only"></span>
          </div>
        </div>
        <div *ngIf="!delayedLoading && !v.healthLoading">
          <!-- demo cars - always ok -->
          <img *ngIf="isDemoCar(v)" src="assets/icons-new/icon-status-ok.svg"
          placement="right auto" container="body" ngbTooltip="Vše OK" height="13px" i18n-ngbTooltip/>
          <!-- connection ok -->
          <!-- TODO chyba ExpressionChangedAfterItHasBeenCheckedError: 
          Expression has changed after it was checked. Previous value: 'true' -->
          <img *ngIf="v.health_connection && !isDemoCar(v)" [src]="v.health_icon_src"
          placement="right auto" container="body" [ngbTooltip]="healthTooltip" height="13px"/>
          <!-- connection old/error -->
          <img *ngIf="!v.health_connection && !isDemoCar(v)" class="pointer" 
          [src]="v.health_icon_src" (click)="initVehicleHotline(v)"
          data-toggle="modal" data-target="#hotlineEmail"
          placement="right auto" container="body" [ngbTooltip]="healthTooltip" height="13px"/>

          <ng-template #healthTooltip>
            <div style="white-space: nowrap;">
              {{v.health_icon_title}}
              <div *ngIf="v.health">{{v.health.formattedDate}}</div>
            </div>
          </ng-template>
        </div>
        <div *ngIf="v.fmsImport">
          <!-- connection ok - v.fmsImport.fmsOk -->
          <img *ngIf="v.fmsImport.fmsStatus == 'FMS_OK'" height="13px"
          src="assets/icons-new/ignition-on.svg"
          placement="auto" container="body" [ngbTooltip]="fmsTooltip"/>
          <!-- connection old - v.fmsImport.fmsNoConnection -->
          <img *ngIf="v.fmsImport.fmsStatus == 'FMS_NO_CONNECTION'" height="13px"
          src="assets/icons-new/ignition-off.svg" (click)="initVehicleHotline(v)"
          placement="auto" container="body" [ngbTooltip]="fmsTooltip"/>
          <!-- connection error - v.fmsImport.fmsError -->
          <img *ngIf="v.fmsImport.fmsStatus == 'FMS_ERROR'" height="13px"
          src="assets/icons-new/icon-status-noconnection.svg" (click)="initVehicleHotline(v)"
          placement="auto" container="body" [ngbTooltip]="fmsTooltip"/>
          
          <ng-template #fmsTooltip>
            <div *ngIf="v.fmsImport.fmsOk" i18n>Vše OK</div>
            <div *ngIf="v.fmsImport.fmsNoConnection">
              <ng-container i18n>Starší odpověď z</ng-container> {{v.fmsImport.import_type}}
            </div>
            <div *ngIf="v.fmsImport.fmsError">
              <ng-container i18n>Zastaven import dat</ng-container> {{v.fmsImport.import_type}}
            </div>
            <div>
              <span class="d-inline-block" style="width:100px">TruckManager:</span>
              <!-- {{vehicle.fmsImport.lastServiceTimeDate | date: 'dd.MM.yyyy HH:mm'}} -->
              {{v.fmsImport.lastImportTimeDate | date: 'dd.MM.yyyy HH:mm'}}
            </div>
            <div>
              <span class="d-inline-block" style="width:100px">{{v.fmsImport.import_type}}:</span>
              {{v.fmsImport.lastRecTimeDate | date: 'dd.MM.yyyy HH:mm'}}
            </div>
            <div>
              {{v.fmsImport.settings}}
            </div>
          </ng-template>
        </div>
      </div>
      
      <!-- 3rd column -->
      <div class="col-3 vehicle-col pointer" (click)="controlShowVehicleMessages(v)">
        <div class="text-nowrap" [ngbTooltip]="v.description" placement="left auto" container="body"
        [class.font-weight-bold]="('vehicle_list_highlight' | configuration:'vehicle') === 'plates'">
          {{v.number_plate}}
        </div>
        <div class="text-nowrap">
          {{v.number_plate_trailer}}
        </div>
      </div>
      
      <!-- 4th column -->
      <div class="col-3 vehicle-col">
        <div class="text-truncate pointer" (click)="controlShowVehicleActivity(v)" 
        [class.font-weight-bold]="('vehicle_list_highlight' | configuration:'vehicle') === 'drivers'">
          {{v.driver_name}}
        </div>
        <div class="text-truncate">
          {{v.driver2_name}}
        </div>
      </div>

      <!-- 5th column -->
      <div class="col-3 vehicle-col">
        <div *ngIf="v.last_position">
          <div class="text-truncate" class="d-flex">
            <div class="mr-auto pointer" (click)="controlShowMap(v)">
              {{v.last_position.city_country}}&nbsp;{{v.last_position.city_zip_short}}
            </div>

            <div class="pr-1">
              <img src="assets/icons-new/dashboard-regular.svg" 
              class="pointer" height="14px" placement="left auto" container="body"  
              [ngbPopover]="popoverVykonJizda" (click)="getAetr(v)"/>
              <ng-template #popoverVykonJizda>
                <div *ngIf="aetr_collection" class="d-flex" class="bg-warning p-1 rounded shadow">
                  <div *ngIf="aetr_collection?.pastSegment" class="mr-2">
                    <div *ngIf="aetr_collection.pastSegment.type == 'S'">
                      <b><ng-container i18n>Spánek</ng-container>:</b> {{duration(aetr_collection.pastSegment)}}h
                    </div>
                    <div *ngIf="aetr_collection.pastSegment.type == 'D'">
                      <!-- TODO výkon -->
                      <!-- <b>Výkon:</b> {{workloadCurrent}}h, -->
                      <b><ng-container i18n>Jízda</ng-container>:</b> {{duration(aetr_collection.pastSegment)}}h,
                      <b>{{doneDriving(aetr_collection.pastSegment)}}</b>
                    </div>
                  </div>
                  <div *ngIf="aetr_collection?.currentSegment">
                    <div *ngIf="aetr_collection.currentSegment.type == 'S'">
                      <b><ng-container i18n>Spánek</ng-container>:</b> {{workloadCurrent}}h
                    </div>
                    <div *ngIf="aetr_collection.currentSegment.type == 'D'">
                      <b><ng-container i18n>Výkon</ng-container>:</b> {{workloadCurrent}}h,
                      <b><ng-container i18n>Jízda</ng-container>:</b> {{duration(aetr_collection.currentSegment)}}h,
                      <b>{{doneDriving(aetr_collection.currentSegment)}}</b>
                    </div>
                  </div>
                </div>
                <div *ngIf="!aetr_collection" i18n>Žádné AETR data</div>
              </ng-template>
            </div>
            
            <div class="pr-1">
              <!-- minimenu -->
              <a class="text-center" href="javascript:void(0)"
              [ngbPopover]="minimenu" container="body" placement="left auto">
                <img src="assets/icons-new/bars-solid.svg" height="18px">
              </a>
              <ng-template #minimenu>
                <div class="d-flex bg-warning shadow rounded p-1">
                  <div class="px-1">
                    <img src="assets/icons-new/mail.svg" height="24px"
                    class="align-middle pointer" (click)="controlShowVehicleMessages(v)"
                    [ngbTooltip]="messagesTooltip" placement="left auto"/>
                    <ng-template #messagesTooltip>
                      <div class="text-nowrap" i18n>Zprávy</div>
                    </ng-template>
                  </div>
                  <div class="px-1">
                    <img src="assets/icons-new/map_location2.svg" height="24px"
                    class="align-middle pointer" (click)="controlShowMap(v)"
                    [ngbTooltip]="mapTooltip" placement="left auto"/>
                    <ng-template #mapTooltip>
                      <div class="text-nowrap" i18n>Zobrazit na mapě</div>
                    </ng-template>
                  </div>
                  <div class="px-1">
                    <img src="assets/icons-new/box_open.svg" height="24px"
                    class="align-middle pointer" (click)="controlShowMapCargo(v)"
                    [ngbTooltip]="lastCargoTooltip" placement="left auto"/>
                    <ng-template #lastCargoTooltip>
                      <div class="text-nowrap">
                        <ng-container i18n>Otevřít informace o aktuálních a nadcházejících nakl/vykl</ng-container>
                      </div>
                    </ng-template>
                  </div>
                  <div class="px-1">
                    <img src="assets/icons-new/info.svg" height="24px" 
                    class="align-middle pointer" (click)="controlShowVehicleInfo(v)"
                    [ngbTooltip]="vehicleInfoTooltip" placement="left auto"/>
                    <ng-template #vehicleInfoTooltip>
                      <div class="text-nowrap" i18n>Staré info o autě</div>
                    </ng-template>
                  </div>
                  <div *ngIf="!v.temporaryManualTracking.length" class="px-1">
                    <img src="assets/icons-new/target.svg" height="24px" 
                    class="pointer align-middle" (click)="configureManualTracking(v)"
                    [ngbTooltip]="destRouteTooltip" placement="left auto"/>
                    <ng-template #destRouteTooltip>
                      <div class="text-nowrap" i18n>Dojezdový cíl</div>
                    </ng-template>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>

          <div class="text-truncate pointer" (click)="controlShowMap(v)">
            {{v.last_position.city_name}}
          </div>
        </div>
      </div>

      
      <!------------------------------------------------------------------>
      <!-- Additional rows -->
      <!------------------------------------------------------------------>
      <!-- aetr row -->
      <div *ngIf="vehiclesConfig.aetr_show && v.driver_key" class="col-12 text-center no-padding pointer"
      (click)="controlShowVehicleActivity(v)">
        <div class="aetr aetr-collection container-fluid" [vehicle]="v"></div>
      </div>
      
      <!-- agenda row -->
      <div *ngIf="vehiclesConfig.gantt_show" class="col-12 ta-head no-padding">
        <gantt-drive-diagram 
          [currentDate]="lastLoadDate" 
          [vehicle]="v"
          (eventClicked)="controlAgendaDetail($event)">
        </gantt-drive-diagram>
      </div>

      <!-- destination route row -->
      <div *ngIf="v.temporaryManualTracking && v.temporaryManualTracking.length" class="col-12 no-padding">
        <vehicle-manual-tracking-result [vehicle]="v"></vehicle-manual-tracking-result>
      </div>

      <!-- vehicle detail "accordion" -->
      <div *ngIf="v.is_detail_visible" [style.height]="getVehicleDetailHeight(v)"
      class="col-12 google-map-wrapper no-padding {{v.isVehicleMessagesDetailType() ? 'for-messages' : ''}}">
        <div class="detail-hide bg-warning pointer text-center" (click)="hideAllDetails()"
        ngbTooltip="Skrýt detail" container="body" placement="top auto" i18n-ngbTooltip>
          <img src="assets/icons-new/navigate_up.svg" height="20px"/>
        </div>

        <!-- <div *ngIf="v.trackingDataLazyloading || v.latestServiceEventsLoading" class="text-center py-5">
          <div class="spinner-border text-primary" role="status" style="width: 2.5rem; height: 2.5rem;">
            <span class="sr-only"></span>
          </div>
        </div> -->

        <!-- <div *ngIf="v.isLastPositionMapDetailType()"  
          id="map_canvas_{{v.car_key}}" 
          class="google-map-container googleMapCar" 
          [style.height.px]="300"
          [vehicle]="v">
        </div> -->

        <div *ngIf="v.isLastPositionMapDetailType()">
          <ng-container *ngTemplateOutlet="vehicleGoogleMap, context: {v: v}">
          </ng-container>
        </div>

        <r-last-cargo-diary *ngIf="v.isLastCargoMapDetailType()" 
          [vehicle]="v" style="overflow-y: auto; height: 300px;">
        </r-last-cargo-diary>

        <div *ngIf="v.isAgendaDetailType()" 
          class="agenda-detail clearfix" 
          [agenda]="v.agenda_detail">
        </div>

        <vehicle-messages-list *ngIf="v.isVehicleMessagesDetailType()" 
          class="agenda-detail clearfix" [vehicle]="v">
        </vehicle-messages-list>

        <div *ngIf="v.isDiagnosticsDetailType()" 
          class="vehicle-diagnostics-component" [vehicle]="v">
        </div>

        <div *ngIf="v.isActivityDetailType()" 
          class="vehicle-activity-component container-fluid fill" [vehicle]="v">
        </div>

        <vehicle-info *ngIf="v.isInfoType()" 
          class="container-fluid fill" style="display:block; overflow:hidden" [vehicle]="v">
        </vehicle-info>
      </div>

      <div *ngIf="v.isVehicleMessagesDetailType() && vehiclesConfig.messages_show"
      class="vehicle-messages-fixed-footer col-12 transitions">
        <message-write [vehicle]="v"></message-write>
      </div>
    </div>
  </div>
</div>


<ng-template #vehicleGoogleMap let-v="v">
  <div id="map_canvas_dashboard" 
    class="google-map-container googleMapCar" 
    [style.height.px]="300"
    [vehicle]="v">
  </div>
</ng-template>


<!-- Modal for hotline email sending -->
<div class="modal fade" role="dialog" tabindex="-1" id="hotlineEmail">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header bg-warning">
        <h4 class="modal-title">
          <div *ngIf="vehicleHotline">
            <ng-container i18n>Podpora - SPZ</ng-container> {{vehicleHotline.number_plate}}
          </div>
        </h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div *ngIf="vehicleHotline">
          <div class="d-flex align-items-center mb-2">
            <img [src]="vehicleHotline.health_icon_src" class="mr-2" width="34px" height="34px"/>
            <div>
              <div i18n>GPS jednotka nemá datové spojení.</div>
              <div i18n>Přejete si, aby pracovník podpory kontaktoval řidiče?</div>
            </div>
          </div>
          <div>
            <h6>{{companyName}}</h6>
            <div class="form-row">
              <div class="col">
                <label class="mb-1" i18n>Řidič</label>
                <input class="form-control" type="text" [(ngModel)]="driverName"/>
              </div>
              <div class="col">
                <label class="mb-1" i18n>Telefon</label>
                <input class="form-control" type="text" [(ngModel)]="driverPhone"/>
              </div>
            </div>
            <div class="form-row mt-2">
              <div class="col">
                <label class="mb-1" i18n>GPS jednotka</label>
                <input class="form-control" type="text" 
                [value]="vehicleHotline.trackingDeviceCodenameFormatted" readonly>
              </div>
              <div class="col">
                <label class="mb-1" i18n>Poslední restart</label>
                <input class="form-control" type="text" 
                [value]="vehicleHotline.trackingDeviceRuntimeFormatted" readonly>
              </div>
              <div class="col">
                <label class="mb-1" i18n>Běh aplikace</label>
                <input class="form-control" type="text" 
                [value]="vehicleHotline.trackingTmRuntimeFormatted" readonly>
              </div>
              <div class="col">
                <label class="mb-1"><ng-container i18n>Verze</ng-container> TM</label>
                <input class="form-control" type="text" 
                [value]="vehicleHotline.trackingTmVersionFormatted" readonly>
              </div>
            </div>
            <!-- google recaptcha -->
            <div class="form-row mt-3">
              <div class="col">
                <label class="mb-1" i18n>Pro odeslání SMS řidiči potvrďte</label>
                <re-captcha (resolved)="recaptchaResolved($event)" [siteKey]="RECAPTCHA_KEY"></re-captcha>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-primary" type="button" (click)="sendEmailSMS()" 
        data-dismiss="modal" [disabled]="!recaptcha_resolved">
          <ng-container i18n>Ano</ng-container>
        </button>
        <button class="btn btn-outline-secondary" type="button" data-dismiss="modal">
          <ng-container i18n>Ne</ng-container>
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Modal for marking vehicles to be shown -->
<div class="modal fade" role="dialog" tabindex="-1" id="vehicleFilterModal">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header bg-warning shadow">
        <h5 class="modal-title" i18n>Výběr vozidel dle SPZ či dispečera</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <app-vehicle-filter></app-vehicle-filter>
      </div>
      <div class="modal-footer">
        <button class="btn btn-light" type="button" data-dismiss="modal" i18n>Zavřít</button>
      </div>
    </div>
  </div>
</div>

<!-- Modal for plot displaying -->
<div class="modal fade" role="dialog" tabindex="-1" id="vehicleChartModal">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header bg-warning">
        <h4 class="modal-title">
          <ng-container i18n>Informace o aktuálním stavu vozidla</ng-container>
          <span *ngIf="vehicleToChart">
            {{vehicleToChart.number_plate}} ({{vehicleToChart.driver_name}})
          </span>
        </h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div *ngIf="vehicleToChart" class="py-4">
          <div class="row">
            <div class="col-md-4 text-center">
              <vehicle-status class="fill full" 
                [vehicle]="vehicleToChart" 
                [width]="100" 
                [modalMode]="true">
              </vehicle-status>
            </div>
            <div class="col-md-8">
              <div style="white-space: nowrap;">
                <div *ngIf="vehicleToChart.roadExceptions()" class="text-danger">
                  Road exceptions info comes here!
                </div>
                <div *ngIf="vehicleToChart.last_position">
                  <div>
                    {{vehicleToChart.last_position.cargo_weight}}t&nbsp;&nbsp;
                    {{vehicleToChart.last_position.formatted_time}}
                  </div>

                  <div *ngIf="vehicleToChart.oldAgendas" class="mt-2">
                    <h6 i18n>Pozor, některé starší zakázky nebyly vyloženy.</h6>
                    <button class="btn btn-sm btn-warning" 
                    (click)="finishOldOrders(vehicleToChart.car_key)">
                      <ng-container i18n>Vyložit všechny staré zakázky</ng-container>
                    </button>
                  </div>
                  <div *ngFor="let order_number of vehicleToChart.last_position.order_number" class="mt-1">
                    <div *ngIf="vehicleToChart.last_position.agendas[order_number]">
                      {{order_number}} 
                      <span *ngIf="vehicleToChart.last_position.agendas[order_number].order_comp_book">
                        {{vehicleToChart.last_position.agendas[order_number].order_comp_book.company}}
                      </span>
                      
                      <div *ngFor="let itin of vehicleToChart.last_position.agendas[order_number].itinerary" 
                      class="d-flex align-items-center">
                        <img class="icon" [src]="icons[itin.type_marker].url"/>
                        <div class="text-truncate ml-1">{{itin.address}}</div>
                      </div>
                    </div>
                    <!-- there could be some not unloaded agendas from past! -->
                    <div *ngIf="!vehicleToChart.last_position.agendas[order_number]" class="d-flex align-items-center mt-2">
                      <div class="align-middle" style="width:70px !important">{{order_number}}</div>
                      <div class="align-middle"><strong>! Starší zakázka nebyla vyložena !</strong></div>
                      <div class="ml-2">
                        <button class="btn btn-sm btn-primary" (click)="sendInfoMessage(order_number)">
                          <ng-container i18n>Odeslat zprávu</ng-container>
                        </button>
                      </div>
                      <div class="ml-2">
                        <button class="btn btn-sm btn-warning" 
                        (click)="createServiceEvent(order_number, vehicleToChart)">
                          <ng-container i18n>Vyložit zakázku</ng-container>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-outline-secondary" type="button" data-dismiss="modal">
          <ng-container i18n>Zavřít</ng-container>
        </button>
      </div>
    </div>
  </div>
</div>