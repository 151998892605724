import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ChartsModule } from "ng2-charts";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { QRCodeModule } from 'angularx-qrcode';
import { ResizableModule } from "angular-resizable-element";
import { RecaptchaModule } from "ng-recaptcha";
// import { RecaptchaModule } from 'angular-google-recaptcha';


import { NoPermissionComponent } from "../components/no-permission/no-permission.component";
import { FileRenamingComponent } from "../components/file-renaming/file-renaming.component";
import { LeftAddressBookItemComponent } from "../components/left-address-book-item/left-address-book-item.component";
import { GoogleMapCarComponent } from "../components/google-map-car/google-map-car.component";
import { GoogleMapCarsAllComponent } from "../components/google-map-cars-all/google-map-cars-all.component";
import { GoogleMapObligationComponent } from "../components/google-map-obligation/google-map-obligation.component";
import { GoogleMapPolygonsComponent } from "../components/google-map-polygons/google-map-polygons.component";
import { GoogleMapFavouritesComponent } from "../components/google-map-favourites/google-map-favourites.component";
import { GoogleMapManualTrackingComponent } from "../components/google-map-manual-tracking/google-map-manual-tracking.component";
import { GoogleMapExpressComponent } from "../components/google-map-express/google-map-express.component";
import { RAetrRowComponent } from "../components/r-aetr-row/r-aetr-row.component";
import { RBroadcastMessageDialogComponent } from "../components/r-broadcast-message-dialog/r-broadcast-message-dialog.component";
import { RMessageAttachmentComponent } from "../components/r-message-attachment/r-message-attachment.component";
import { RMessageDayGroupedListComponent } from "../components/r-messages-day-grouped-list/r-messages-day-grouped-list.component";
import { RMessagesHistoryComponent } from "../components/r-messages-history/r-messages-history.component";
import { RMessagesListComponent } from "../components/r-messages-list/r-messages-list.component";
import { RMessagesNewDialogComponent } from "../components/r-messages-new-dialog/r-messages-new-dialog.component";
import { RMessagesNewComponent } from "../components/r-messages-new/r-messages-new.component";
import { RMessagesReadComponent } from "../components/r-messages-read/r-messages-read.component";
import { RMessageRowComponent } from "../components/r-message-row/r-message-row.component";
import { RMessageWriteComponent } from "../components/r-message-write/r-message-write.component";
import { RMultiMediaAttachmentComponent } from "../components/r-multi-media-attachment/r-multi-media-attachment.component";
import { RDispatcherBoardRowComponent } from "../components/r-dispatcher-board-row/r-dispatcher-board-row.component";
import { RVehicleActivityComponent } from "../components/r-vehicle-activity/r-vehicle-activity.component";
import { RVehicleManualTrackingResultComponent } from "../components/r-vehicle-manual-tracking-result/r-vehicle-manual-tracking-result.component";
import { RVehicleMessagesListComponent } from "../components/r-vehicle-messages-list/r-vehicle-messages-list.component";
import { Ta1ObligationEmailingComponent } from "../components/ta1-obligation-emailing/ta1-obligation-emailing.component";
import { Ta1OrderEmailingComponent } from "../components/ta1-order-emailing/ta1-order-emailing.component";
import { Ta3InvoiceEmailLogsComponent } from "../components/ta3-invoice-email-logs/ta3-invoice-email-logs.component";
import { TmServicesModalsComponent } from "../components-module-tm/tm-services-modals/tm-services-modals.component";

import { DateFormatPipe } from '../pipe/date-format.pipe';
import { SafeResourcePipe } from '../pipe/safe-resource.pipe';
import { SafeHtmlPipe } from '../pipe/safe-html.pipe';
import { SafeStylePipe } from '../pipe/safe-style.pipe';
import { ConfigurationPipe } from '../pipe/configuration.pipe';
import { PricePipe } from '../pipe/price.pipe';
import { KmPipe } from '../pipe/km.pipe';
import { CarTypePipe } from '../pipe/car-type.pipe';
import { ContentEditableDirective } from "../directive/content-editable.directive";

@NgModule({
  declarations: [
    NoPermissionComponent,
    FileRenamingComponent,
    LeftAddressBookItemComponent,
    GoogleMapCarComponent,
    GoogleMapCarsAllComponent,
    GoogleMapObligationComponent,
    GoogleMapPolygonsComponent,
    GoogleMapFavouritesComponent,
    GoogleMapManualTrackingComponent,
    GoogleMapExpressComponent,
    RAetrRowComponent,
    RBroadcastMessageDialogComponent,
    RDispatcherBoardRowComponent,
    RMessageAttachmentComponent,
    RMessageDayGroupedListComponent,
    RMessagesHistoryComponent,
    RMessagesListComponent,
    RMessagesNewDialogComponent,
    RMessagesNewComponent,
    RMessagesReadComponent,
    RMessageRowComponent,
    RMessageWriteComponent,
    RMultiMediaAttachmentComponent,
    RVehicleActivityComponent,
    RVehicleManualTrackingResultComponent,
    RVehicleMessagesListComponent,
    Ta1ObligationEmailingComponent,
    Ta1OrderEmailingComponent,
    Ta3InvoiceEmailLogsComponent,
    TmServicesModalsComponent,
    DateFormatPipe,
    SafeResourcePipe,
    SafeHtmlPipe,
    SafeStylePipe,
    ConfigurationPipe,
    PricePipe,
    KmPipe,
    CarTypePipe,
    ContentEditableDirective
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    ChartsModule,
    DragDropModule,
    MatNativeDateModule,
    MatDatepickerModule,
    QRCodeModule,
    ResizableModule,
    RecaptchaModule
    // RecaptchaModule.forRoot({siteKey: '6Lcvt8UUAAAAAHFAYec37VLmmHeSUi6TWtSBbLC5'}),
  ],
  providers: [
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    ChartsModule,
    DragDropModule,
    MatNativeDateModule,
    MatDatepickerModule,
    QRCodeModule,
    ResizableModule,
    RecaptchaModule,
    NoPermissionComponent,
    FileRenamingComponent,
    LeftAddressBookItemComponent,
    GoogleMapCarComponent,
    GoogleMapCarsAllComponent,
    GoogleMapObligationComponent,
    GoogleMapPolygonsComponent,
    GoogleMapFavouritesComponent,
    GoogleMapManualTrackingComponent,
    GoogleMapExpressComponent,
    RAetrRowComponent,
    RDispatcherBoardRowComponent,
    RMessageDayGroupedListComponent,
    RMessagesHistoryComponent,
    RMessagesListComponent,
    RMessagesNewDialogComponent,
    RMessagesNewComponent,
    RMessagesReadComponent,
    RMessageWriteComponent,
    RMultiMediaAttachmentComponent,
    RVehicleActivityComponent,
    RVehicleManualTrackingResultComponent,
    RVehicleMessagesListComponent,
    Ta1ObligationEmailingComponent,
    Ta1OrderEmailingComponent,
    Ta3InvoiceEmailLogsComponent,
    TmServicesModalsComponent,
    DateFormatPipe,
    SafeResourcePipe,
    SafeHtmlPipe,
    SafeStylePipe,
    ConfigurationPipe,
    PricePipe,
    KmPipe,
    CarTypePipe,
    ContentEditableDirective
  ]
})
export class SharedModule { }
