import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { HttpClientService } from "./http-client.service";
import { AuthenticationService } from "./authentication.service";
import { NotificationService } from "../service/notification-service";
import { IS_DEMO, ServiceConfiguration } from "../config";
// import {ServiceEventLight} from "../model/service-event-light.object";
// import { ServiceEventObject } from '../model/service-event.object';
import { ServiceEventObject } from '../model/service-event.object';


@Injectable({
  providedIn: 'root',
})
export class ServiceEventService {

  private _serviceEvents: BehaviorSubject<Array<ServiceEventObject>> = new BehaviorSubject(null);
  private _serviceEventsCache: Array<ServiceEventObject> = null;
  private _serviceEventsCacheFiltered: Array<ServiceEventObject> = null;
  private _updateSuccess: boolean = true;
  private _updatesMade: number = 0;

  constructor(
    private _http: HttpClientService,
    private _authService: AuthenticationService,
    private _notificationService: NotificationService
  ) {
  }

  private _loadingServiceEvents: boolean = false;
  get loadingServiceEvents(): boolean {
    return this._loadingServiceEvents;
  }

  private clearEventsCache(): void {
    this._serviceEventsCache = null;
    this._serviceEventsCacheFiltered = null;
  }

  // method for getting all service events of selected car in specified time interval
  // tf - time_from, tt - time_to, ck - car_key, dk - driver_key, on - order_number
  getServiceEvents(filterObj: any): Observable<Array<ServiceEventObject>> {
    this.clearEventsCache();

    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      let url: string = ServiceConfiguration.serviceEvent.api;
      // add url query
      if (filterObj) {
        let filterKeys: Array<string> = Object.keys(filterObj);
        filterKeys.forEach(
          (key, index) => {
            if (index == 0 && filterObj[key]) {
              url += '?' + key + '=' + filterObj[key];
            }
            else if (filterObj[key]) {
              url += '&' + key + '=' + filterObj[key];
            }
          }
        );
      }

      this._loadingServiceEvents = true;

      this._http.get(url).subscribe(
        response => {
          this._serviceEventsCache = ServiceEventService.buildServiceEventsFromData(response);
          this._serviceEventsCacheFiltered = this._serviceEventsCache.filter(e => e.type != '+' && e.type != '-');
          this._serviceEvents.next(this._serviceEventsCacheFiltered);
        },
        error => {
          //handle error
          console.log(error);
          this._loadingServiceEvents = false;
        },
        () => {
          // finally
          this._loadingServiceEvents = false;
        }
      );
    }

    return this._serviceEvents.asObservable();
  }


  // method for getting all service events of selected car in specified time interval
  // NOT USING ATTRIBUTES BUT CUSTOM INSTANCES
  // tf - time_from, tt - time_to, ck - car_key, dk - driver_key, on - order_number
  getServiceEventsSeparately(filterObj: any): Observable<Array<ServiceEventObject>> {
    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      let url: string = ServiceConfiguration.serviceEvent.api;
      // add url query
      if (filterObj) {
        let filterKeys: Array<string> = Object.keys(filterObj);
        filterKeys.forEach(
          (key, index) => {
            if (index == 0 && filterObj[key]) {
              url += '?' + key + '=' + filterObj[key];
            }
            else if (filterObj[key]) {
              url += '&' + key + '=' + filterObj[key];
            }
          }
        );
      }

      return this._http.get(url);
    }
  }

  
  // method for updating all service events that were loaded
  public updateServiceEvents(number_plate: string, d_key: number, d2_key: number): void {
    // init data to update
    let data: any = {
      driver_key: d_key,
      driver2_key: d2_key
    };

    // init flags for completion of all updates
    this._updateSuccess = true;
    this._updatesMade = 0;

    if (this._serviceEventsCacheFiltered) {
      this._serviceEventsCacheFiltered.forEach(
        se => {
          this.updateServiceEventCache(number_plate, se, data).subscribe();
        }
      );
    }
  }

    // method for updating a single service event
    public updateServiceEventFueling(sEvent: ServiceEventObject,dataToUpdate:any): Observable<any> {
      let updatedRecord: Subject<any> = new Subject();
      if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
        let url: string = ServiceConfiguration.serviceEvent.api + sEvent.service_key;
        this._http.put(url, dataToUpdate).subscribe(
          response => {
            this._notificationService.alert(
              $localize`Tankovaní upraveno %carKey" `.replace('%carKey', response.number_plate),
              'success',4000
            );
            updatedRecord.next(response);
          },
          error => {
            console.log(error);
            updatedRecord.next(error);
            this._updateSuccess = false;
            this._updatesMade += 1;
            // alert when last update is made

          },
          () => {
            this._updatesMade += 1;
            // alert when last update is made
            
          }
        );
      }
  
      return updatedRecord.asObservable();
    }

  // method for updating a single service event
  public updateServiceEventCache(number_plate: string, sEvent: ServiceEventObject, dataToUpdate: any): Observable<any> {
    let updatedRecord: Subject<any> = new Subject();

    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      let url: string = ServiceConfiguration.serviceEvent.api + sEvent.service_key;
      this._http.put(url, dataToUpdate).subscribe(
        response => {
          this._updatesMade += 1;
          // alert when last update is made
          if (this._updatesMade == this._serviceEventsCacheFiltered.length) {
            if (this._updateSuccess) {
              this._notificationService.alert(
                $localize`Událost Nakládky/Vykládky byla úspěšně vytvořena.`.replace('%SPZ', number_plate),
                'success', 4000
              );
            } else {
              this._notificationService.alert(
                $localize`Chyba úpravy řidičů vozidla %SPZ v historii. (service events)`.replace('%SPZ', number_plate),
                'error', 4000
              );
            }
          }
          updatedRecord.next(response);
        },
        error => {
          console.log(error);
          updatedRecord.next(error);
          this._updateSuccess = false;
          this._updatesMade += 1;
          // alert when last update is made
          if (this._updatesMade == this._serviceEventsCacheFiltered.length) {
            this._notificationService.alert(
              $localize`Chyba úpravy řidičů vozidla %SPZ v historii. (service events)`.replace('%SPZ', number_plate),
              'error', 4000
            );
          }
        }
      );
    }

    return updatedRecord.asObservable();
  }

  
  // DELETE /events/service/<POS_KEY>
  deleteServiceEvent(event: ServiceEventObject): Observable<boolean> {
    let result: BehaviorSubject<any> = new BehaviorSubject(false);
    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      let url: string = ServiceConfiguration.serviceEvent.api + event.service_key;

      this._http.delete(url).subscribe(
        response => {
          // let alert: string = 'Chyba při odstranění události - kontaktujte podporu.';
          // this._notificationService.alert(alert, 'error', 4000);
          result.next(true);
        },
        error => {
          console.error(error);
          let alert: string = $localize`Chyba při odstranění události - kontaktujte podporu.`;
          this._notificationService.alert(alert, 'error', 4000);
        }
      );
    }
    return result.asObservable();
  }

  // POST /events/service
  createServiceEvent(event: ServiceEventObject): Observable<ServiceEventObject> {
    let result: BehaviorSubject<ServiceEventObject> = new BehaviorSubject(null);
    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      let url: string = ServiceConfiguration.serviceEvent.api;

      this._http.post(url, event.postApiObject).subscribe(
        response => {
          if (response) {
            // alert
            let alert: string = $localize`Událost byla úspěšně vytvořena.`;
            this._notificationService.alert(alert, 'success', 4000);
            
            // observable next
            result.next(ServiceEventService.buildServiceEvent(response));
          }
        },
        error => {
          // handle error
          console.log(error);
          // alert
          let alert: string = $localize`Chyba při vytváření události.`;
          this._notificationService.alert(alert, 'error', 4000);
        }
      );
    }

    return result.asObservable();
  }

  // PUT /events/service
  updateServiceEvent(event: ServiceEventObject): Observable<ServiceEventObject> {
    let result: BehaviorSubject<ServiceEventObject> = new BehaviorSubject(null);
    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      let url: string = ServiceConfiguration.serviceEvent.api;
      url += "/" + event.service_key;

      this._http.put(url, event.putApiObject).subscribe(
        response => {
          if (response) {
            // alert
            let alert: string = $localize`Událost byla úspěšně upravena.`;
            this._notificationService.alert(alert, 'success', 4000);
            
            // observable next
            result.next(ServiceEventService.buildServiceEvent(response));
          }
        },
        error => {
          // handle error
          console.log(error);
          // alert
          let alert: string = $localize`Chyba při uprávě události.`;
          this._notificationService.alert(alert, 'error', 4000);
        }
      );
    }

    return result.asObservable();
  }

  // method for creating service events objects from api objects
  static buildServiceEventsFromData(data: Array<any>): any {
    let events: Array<ServiceEventObject> = [];
    data.forEach(
      se => {
        let sEvent = ServiceEventService.buildServiceEvent(se);
        events.push(sEvent);
      }
    );
    return events;
  }

  // method for creating a single service event
  static buildServiceEvent(se: any): ServiceEventObject {
    let sEvent: ServiceEventObject = new ServiceEventObject();
    for (let key in se) {
      sEvent[key] = se[key];
    }
    return sEvent;
  }
}
