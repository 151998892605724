import { ExternalImport } from "./external-import.object";

export class ExternalImportAvailable {

  static USER_COULD_REQUEST: Array<string> = [
    'VOLVO', 'RENLT', 'SCANI', 'DAF--', 'MBENZ'
  ];

  // emails to sending request
  static EMAILS: any = {
    'VOLVO': 'volvoconnect.cz@volvo.com',
    'RENLT': 'optifleet.cz@renault-trucks.com',
    'SCANI': 'miroslav.rejman@scania.cz',
    'DAF--': 'miroslav@zubina.cz',
    'MBENZ': 'office.fleetboardcz@daimler.com',
    'WDISP': 'info@truckmanager.eu',
    'LOGIS': 'michal.dusek@logiscare.com',
    'CMDSK': '',
    'DOZOR': '',
    'TCARS': '',
    'ONISY': '',
    'ORBTR': '',
    'WEBFL': ''
  };
  
  static EMAIL_SUBJECTS: any = {
    'VOLVO': 'Objednávka dat z VolvoConnect pro TruckManager',
    'RENLT': 'Objednávka dat z VolvoConnect pro TruckManager',
    'SCANI': 'Objednávka rFMS dat pro TruckManager',
    'DAF--': 'Objednávka rFMS dat pro TruckManager',
    'MBENZ': 'Objednávka rFMS dat pro TruckManager',
    'WDISP': 'Žádost o propojení systému TruckManager s Webdispečinkem',
    'LOGIS': 'Žádost o propojení systému TruckManager s LogisCare',
    'CMDSK': '',
    'DOZOR': '',
    'TCARS': '',
    'ONISY': '',
    'ORBTR': '',
    'WEBFL': ''
  };

  static TYPE_NAMES: any = {
    'VOLVO': 'VOLVO',
    'RENLT': 'RENAULT',
    'SCANI': 'SCANIA',
    'DAF--': 'DAF',
    'MBENZ': 'Mercedes-Benz',
    'WDISP': 'Webdispečink',
    'LOGIS': 'LogisCare',
    'CMDSK': 'Commander',
    'DOZOR': 'GPS Dozor',
    'TCARS': 'T-Cars System',
    'ONISY': 'ONI system',
    'ORBTR': 'ORBtrack',
    'WEBFL': 'WebFleet - TomTom'
  }
  
  static TYPE_SHORTNAMES: any = {
    'VOLVO': 'VOLVO',
    'RENLT': 'RENAULT',
    'SCANI': 'SCANIA',
    'DAF--': 'DAF',
    'MBENZ': 'MB',
    'WDISP': 'Webdispečink',
    'LOGIS': 'LogisCare',
    'CMDSK': 'Commander',
    'DOZOR': 'GPS Dozor',
    'TCARS': 'T-Cars',
    'ONISY': 'ONI system',
    'ORBTR': 'ORBtrack',
    'WEBFL': 'WebFleet'
  }

  static EMAIL_TEXTS: any = {
    'VOLVO': 'Závazně objednávám přístup k datům z VolvoConnect z našich níže uvedených vozidel:\n',
    'RENLT': 'Závazně objednávám přístup k datům z VolvoConnect z našich níže uvedených vozidel:\n',
    'SCANI': 'Závazně objednávame data z našich níže uvedených vozidel z FleetMagement systemu pro dopravní sw TruckManager.eu - tarif Location.\nSdělte nám prosím, kterému z našich účtů přidělíte roli Developer.\n',
    'DAF--': 'Závazně objednávame data z našich níže uvedených vozidel z Open Platform pro dopravní SW TruckManager.eu:\n',
    'MBENZ': 'Závazně objednávame přístup k rFMS datům z našich níže uvedených vozidel z FleetBoard systému pro dopravní SW TruckAgenda.eu - tarif...:\n',
    'WDISP': `Pro spuštění imporu dat je nutné ve vašem Webdispečinku provést tato nastavení:
V aplikaci Webdispečink vytvořte nového uživatele:\n
a) Nastaveni - Uživatelé - Uživatelé - v pravo nahoře Vložit uživatele
Přihlašovací jméno: vaše ID v TruckManageru
Heslo: zvolte sami
Ověření hesla: ****
Platný od: datum + stále platný
Práva: user
OK\n
b) Záložka Uživatel
E-mail: info@truckmanager.eu
Práva: user
OK\n
c) Záložka Práva
beze změny\n
d) Záložka Vozidla a řidiči
Vyberte seznam požadovaných vozidel - ideálně vše.
!!! Při zavedení nového vozidla nebo řidiče nutno doplnit.
Uložit\n
e) Povolení API:
Nastavení – Firma – Balíčky – povolit balíček API a prohlédnout ceník u tohoto balíčku\n
f) V TruckManageru je nutné doplnit v Moje auta 
- Parametry aut ID vašich vozidel ve Webdispečinku.`,
    'LOGIS': 'Žádám o zřízení přístupu k GPS datům z našich vozidel ze systému LogisCare pro systém TruckManager.eu:\n',
    'CMDSK': '',
    'DOZOR': '',
    'TCARS': '',
    'ONISY': '',
    'ORBTR': '',
    'WEBFL': ''
  }

  constructor() {
  }

  private _active: boolean;
  public get active(): boolean {
    return this._active;
  }
  public set active(value: boolean) {
    this._active = value;
  }

  private _auth_hashkey: string;
  public get auth_hashkey(): string {
    return this._auth_hashkey;
  }
  public set auth_hashkey(value: string) {
    this._auth_hashkey = value;
  }
  
  private _auto_add_cars: boolean;
  public get auto_add_cars(): boolean {
    return this._auto_add_cars;
  }
  public set auto_add_cars(value: boolean) {
    this._auto_add_cars = value;
  }

  private _auto_add_drivers: boolean;
  public get auto_add_drivers(): boolean {
    return this._auto_add_drivers;
  }
  public set auto_add_drivers(value: boolean) {
    this._auto_add_drivers = value;
  }
  
  private _import_type: string;
  public get import_type(): string {
    return this._import_type;
  }
  public set import_type(value: string) {
    this._import_type = value;
  }

  private _name: string;
  public get name(): string {
    return this._name;
  }
  public set name(value: string) {
    this._name = value;
  }

  private _pwd_secret: string;
  public get pwd_secret(): string {
    return this._pwd_secret;
  }
  public set pwd_secret(value: string) {
    this._pwd_secret = value;
  }

  private _pwd_value: string;
  public get pwd_value(): string {
    return this._pwd_value;
  }
  public set pwd_value(value: string) {
    this._pwd_value = value;
  }

  private _service_rec_required: boolean;
  public get service_rec_required(): boolean {
    return this._service_rec_required;
  }
  public set service_rec_required(value: boolean) {
    this._service_rec_required = value;
  }

  private _usr_secret: string;
  public get usr_secret(): string {
    return this._usr_secret;
  }
  public set usr_secret(value: string) {
    this._usr_secret = value;
  }
  
  private _usr_value: string;
  public get usr_value(): string {
    return this._usr_value;
  }
  public set usr_value(value: string) {
    this._usr_value = value;
  }

  // custom existing setup
  private _externalSetup: ExternalImport;
  public get externalSetup(): ExternalImport {
    return this._externalSetup;
  }
  public set externalSetup(value: ExternalImport) {
    this._externalSetup = value;
  }
}