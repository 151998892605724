import { Invoice } from "../model/invoice.object";
import { Person } from './person.object';
import { DateTools } from '../tools/DateTools';
import { Obligation } from './obligation.object';
import { Email } from './email.object';
import { InvoiceItem } from './invoice-item.object';
import { OrderTools } from "../tools/OrderTools";
import { ExpressRoute } from "./express-route.object";
import { Itinerary } from "./itinerary.object";

export class Order {
  private _order_key: number;
  get order_key(): number {
    return this._order_key;
  }
  set order_key(value: number) {
    this._order_key = value;
  }
  
  private _accept_token: string;
  public get accept_token(): string {
    return this._accept_token;
  }
  public set accept_token(value: string) {
    this._accept_token = value;
  }

  private _accepted_by: string;
  public get accepted_by(): string {
    return this._accepted_by;
  }
  public set accepted_by(value: string) {
    this._accepted_by = value;
  }

  private _accepted_from: string;
  public get accepted_from(): string {
    return this._accepted_from;
  }
  public set accepted_from(value: string) {
    this._accepted_from = value;
  }
  
  private _accepted_to: string;
  public get accepted_to(): string {
    return this._accepted_to;
  }
  public set accepted_to(value: string) {
    this._accepted_to = value;
  }
  
  private _accepted_time: string;
  public get accepted_time(): string {
    return this._accepted_time;
  }
  public set accepted_time(value: string) {
    this._accepted_time = value;
    if (this._accepted_time) {
      this._accepted_time_date = DateTools.apiIsoToDate(value);
    }
  }

  private _accepted_time_date: Date;
  public get accepted_time_date(): Date {
    return this._accepted_time_date;
  }
  public set accepted_time_date(value: Date) {
    this._accepted_time_date = value;
  }
  
  private _allow_einvoicing: boolean = true;
  public get allow_einvoicing(): boolean {
    return this._allow_einvoicing;
  }
  public set allow_einvoicing(value: boolean) {
    this._allow_einvoicing = value;
  }

  private _company_key: number;
  public get company_key(): number {
    return this._company_key;
  }
  public set company_key(value: number) {
    this._company_key = value;
  }
  
  private _contracted_price: number;
  get contracted_price(): number {
    return this._contracted_price;
  }
  set contracted_price(value: number) {
    this._contracted_price = value;
  }

  // custom attribute - rezijni cena k zakazce
  private _calculated_price: number = 0;
  public get calculated_price(): number {
    return this._calculated_price;
  }
  public set calculated_price(value: number) {
    this._calculated_price = value;
  }

  private _calculated_currency: string = '';
  public get calculated_currency(): string {
    return this._calculated_currency;
  }
  public set calculated_currency(value: string) {
    this._calculated_currency = value;
  }

  private _contract_terms: string;
  public get contract_terms(): string {
    return this._contract_terms;
  }
  public set contract_terms(value: string) {
    this._contract_terms = value;
  }
  
  private _created_time: string;
  get created_time(): string {
    return this._created_time;
  }
  set created_time(value: string) {
    this._created_time = value;
    if (value) {
      this.created_time_input = DateTools.isoForDatetimeInput(DateTools.apiIsoToDate(value));
      this.created_time_date = DateTools.apiIsoToDate(value);
    }
  }

  private _created_time_input: string;
  public get created_time_input(): string {
    return this._created_time_input;
  }
  public set created_time_input(value: string) {
    this._created_time_input = value;
  }
  
  private _created_time_date: Date;
  public get created_time_date(): Date {
    return this._created_time_date;
  }
  public set created_time_date(value: Date) {
    this._created_time_date = value;
  }
  
  private _currency: string;
  public get currency(): string {
    return this._currency;
  }
  public set currency(value: string) {
    this._currency = value;
  }

  // delivery_type CHAR(1),      
  // -- typ prepravy - NULL/A = standardni ode dveri ke dverim 
  // 'P' = vyzvednuti (svoz / PickUp)
  // 'D' = doruceni (rozvoz / delivery)
  private _delivery_type: string;
  public get delivery_type(): string {
    return this._delivery_type;
  }
  public set delivery_type(value: string) {
    this._delivery_type = value;
  }
  
  private _driver_name: string;
  public get driver_name(): string {
    return this._driver_name;
  }
  public set driver_name(value: string) {
    this._driver_name = value;
  }

  private _driver_phone: string;
  public get driver_phone(): string {
    return this._driver_phone;
  }
  public set driver_phone(value: string) {
    this._driver_phone = value;
  }

  private _email_msg_ids: Array<string> = [];
  public get email_msg_ids(): Array<string> {
    return this._email_msg_ids;
  }
  public set email_msg_ids(value: Array<string>) {
    this._email_msg_ids = value;
  }
  
  // OBSOLETE
  // array that should be sorted exactly as array _email_msg_ids
  // private _email_msg_headers: Array<InvoiceEmail> = [];
  // public get email_msg_headers(): Array<InvoiceEmail> {
  //     return this._email_msg_headers;
  // }
  // public set email_msg_headers(value: Array<InvoiceEmail>) {
  //     this._email_msg_headers = value;
  // }
  
  // headers has been added to api GET call
  private _email_headers: Array<Email> = [];
  public get email_headers(): Array<Email> {
    return this._email_headers;
  }
  public set email_headers(value: Array<Email>) {
    this._email_headers = value;
  }

  private _favourite: boolean;
  public get favourite(): boolean {
    return this._favourite;
  }
  public set favourite(value: boolean) {
    this._favourite = value;
  }
  
  private _invoices: Array<Invoice>;
  get invoices(): Array<Invoice> {
    return this._invoices;
  }
  set invoices(value: Array<Invoice>) {
    this._invoices = value;
  }

  // custom property for association of item with order (received invoice)
  private _invoiceItem: InvoiceItem;
  public get invoiceItem(): InvoiceItem {
    return this._invoiceItem;
  }
  public set invoiceItem(value: InvoiceItem) {
    this._invoiceItem = value;
  }

  private _invoice_maturity: number;
  public get invoice_maturity(): number {
    return this._invoice_maturity;
  }
  public set invoice_maturity(value: number) {
    this._invoice_maturity = value;
  }

  private _number: number;
  public get number(): number {
    return this._number;
  }
  public set number(value: number) {
    this._number = value;
  }

  private _obligations: Array<Obligation> = [];
  public get obligations(): Array<Obligation> {
    return this._obligations;
  }
  public set obligations(value: Array<Obligation>) {
    this._obligations = value;
    if (this._obligations && this._obligations.length) {
      // reinit itinerary
      this._itinerary = [];
      this._obligations.forEach(
        o => {
          if (o.itinerary && o.itinerary.length) {
            // save obligation reference
            o.itinerary.forEach(
              it => {
                it.obligation = o;
              }
            );

            if (this._delivery_type == 'P') {
              // pick up / collection order
              if (o.piece_shipment && o.itinerary_warehouse_collection) {
                // warehouse
                this._itinerary = this._itinerary.concat(o.itinerary_warehouse_collection);
              }
              else if (o.itinerary_transshipment_collection) {
                // transshipment
                this._itinerary = this._itinerary.concat(o.itinerary_transshipment_collection);
              }
            }
            else if (this._delivery_type == 'D') {
              //  delivery order
              if (o.piece_shipment && o.itinerary_warehouse_delivery) {
                // warehouse
                this._itinerary = this._itinerary.concat(o.itinerary_warehouse_delivery);
              }
              else if (o.itinerary_transshipment_delivery) {
                // transshipment
                this._itinerary = this._itinerary.concat(o.itinerary_transshipment_delivery);
              }
            }
            else {
              // standard order
              this._itinerary = this._itinerary.concat(o.itinerary);
            }
          }
        }
      );
      this._itinerary.sort((a, b) => (a.arrival_time > b.arrival_time) ? 1 : -1);
    }
  }

  // custom
  private _itinerary: Array<Itinerary> = [];
  public get itinerary(): Array<Itinerary> {
    return this._itinerary;
  }
  public set itinerary(value: Array<Itinerary>) {
    this._itinerary = value;
  }

  // custom
  private _obligation_note_price: string = null;
  public get obligation_note_price(): string {
    return this._obligation_note_price;
  }
  public set obligation_note_price(value: string) {
    this._obligation_note_price = value;
  }

  private _order_comp_book_key: number;
  public get order_comp_book_key(): number {
    return this._order_comp_book_key;
  }
  public set order_comp_book_key(value: number) {
    this._order_comp_book_key = value;
  }

  private _order_comp_book: any; // AddressBookItem
  get order_comp_book(): any {
    return this._order_comp_book;
  }
  set order_comp_book(value: any) {
    this._order_comp_book = value;
  }
  
  private _order_comp_name_long: string;
  get order_comp_name_long(): string {
    return this._order_comp_name_long;
  }
  set order_comp_name_long(value: string) {
    this._order_comp_name_long = value;
  }

  private _order_contact: any;
  public get order_contact(): any {
    return this._order_contact;
  }
  public set order_contact(value: any) {
    this._order_contact = value;
  }

  private _order_phone: any;
  public get order_phone(): any {
    return this._order_phone;
  }
  public set order_phone(value: any) {
    this._order_phone = value;
  }
  
  private _order_email: any;
  public get order_email(): any {
    return this._order_email;
  }
  public set order_email(value: any) {
    this._order_email = value;
  }

  private _person_key: number = null;
  public get person_key(): number {
    return this._person_key;
  }
  public set person_key(value: number) {
    this._person_key = value;
    // 0 cant be saved in api request
    if (this._person_key == 0) this._person_key = null;
  }

  private _person: Person;
  public get person(): Person {
    return this._person;
  }
  public set person(value: Person) {
    this._person = value;
  }

  private _order_number_standard: string;
  get order_number_standard(): string {
    return this._order_number_standard;
  }
  set order_number_standard(value: string) {
    this._order_number_standard = value;
  }
  
  // custom property for detecting if order has been marked/checked
  private _marked: boolean;
  public get marked(): boolean {
    return this._marked;
  }
  public set marked(value: boolean) {
    this._marked = value;
  }

  private _note: string;
  public get note(): string {
    return this._note;
  }
  public set note(value: string) {
    this._note = value;
  }
  
  private _note_price: string;
  public get note_price(): string {
    return this._note_price;
  }
  public set note_price(value: string) {
    this._note_price = value;
  }

  // custom
  private _tariffNotePrice: string = '';
  public get tariffNotePrice(): string {
    return this._tariffNotePrice;
  }
  public set tariffNotePrice(value: string) {
    this._tariffNotePrice = value;
    if (this._tariffNotePrice) {
      if (this._note_price && this._note_price.length) {
        this._note_price += ' ' + this._tariffNotePrice;
      }
      else {
        this._note_price = this._tariffNotePrice;
      }
    }
  }

  // custom property for binding obligation notes to textarea
  private _note_obligations: string;
  public get note_obligations(): string {
    return this._note_obligations;
  }
  public set note_obligations(value: string) {
    this._note_obligations = value;
  }
  
  private _series: number;
  public get series(): number {
    return this._series;
  }
  public set series(value: number) {
    this._series = value;
  }

  private _spz: string;
  public get spz(): string {
    return this._spz;
  }
  public set spz(value: string) {
    this._spz = value;
  }

  private _car_type: string;
  public get car_type(): string {
    return this._car_type;
  }
  public set car_type(value: string) {
    this._car_type = value;
  }
  
  private _spz_trailer: string;
  public get spz_trailer(): string {
    return this._spz_trailer;
  }
  public set spz_trailer(value: string) {
    this._spz_trailer = value;
  }
  
  private _subnumber: number;
  public get subnumber(): number {
    return this._subnumber;
  }
  public set subnumber(value: number) {
    this._subnumber = value;
  }
  
  private _status: string;
  public get status(): string {
    return this._status;
  }
  public set status(value: string) {
    this._status = value;
    this._backgroundColorClass = OrderTools.setBackground(this._status);
    this._backgroundColorRowClass = OrderTools.setBackgroundTableRow(this._status);
  }

  // custom property for color in list of obligations according to status
  private _backgroundColorClass: string = '';
  get backgroundColorClass(): string {
    return this._backgroundColorClass;
  }
  set backgroundColorClass(value: string) {
    this._backgroundColorClass = value;
  }
  
  private _backgroundColorRowClass: string = '';
  get backgroundColorRowClass(): string {
    return this._backgroundColorRowClass;
  }
  set backgroundColorRowClass(value: string) {
    this._backgroundColorRowClass = value;
  }

  private _updated_by: string;
  public get updated_by(): string {
    return this._updated_by;
  }
  public set updated_by(value: string) {
    this._updated_by = value;
  }
  
  private _updated_time: string;
  public get updated_time(): string {
    return this._updated_time;
  }
  public set updated_time(value: string) {
    this._updated_time = value;
  }

  private _year: number = new Date().getFullYear();
  public get year(): number {
    return this._year;
  }
  public set year(value: number) {
    this._year = value;
  }

  // custom property for checkbox binding
  private _usePriceToObligation: boolean = true;
  public get usePriceToObligation(): boolean {
    return this._usePriceToObligation;
  }
  public set usePriceToObligation(value: boolean) {
    this._usePriceToObligation = value;
  }

  public get orderNumberFormatted(): string {
    if (!this._order_number_standard) {
      return '';
    }
    return 'OB' + this._order_number_standard;
  }
  
  private _previewPDF: any;
  public get previewPDF(): any {
    return this._previewPDF;
  }
  public set previewPDF(value: any) {
    this._previewPDF = value;
  }

  // custom temporary placeholder for express route
  private _express_route: ExpressRoute = null;
  public get express_route(): ExpressRoute {
    return this._express_route;
  }
  public set express_route(value: ExpressRoute) {
    this._express_route = value;
  }

  // custom date object of last sent reminder
  private _last_reminder_sent_date: Date = null;
  public get last_reminder_sent_date(): Date {
    return this._last_reminder_sent_date;
  }
  public set last_reminder_sent_date(value: Date) {
    this._last_reminder_sent_date = value;
  }
  
  public get apiObject(): any {
    let obj: any = {
      accept_token: this._accept_token,
      allow_einvoicing: this._allow_einvoicing,
      contract_terms: this._contract_terms,
      contracted_price: this._contracted_price,
      currency: this._currency,
      driver_name: this._driver_name,
      driver_phone: this._driver_phone,
      delivery_type: this.delivery_type,
      favourite: this._favourite,
      invoice_maturity: this._invoice_maturity,
      note: this._note,
      note_price: this._note_price,
      order_comp_book_key: this._order_comp_book_key,
      order_comp_name_long: this._order_comp_name_long,
      order_contact: this._order_contact,
      order_email: this._order_email,
      order_phone: this._order_phone,
      person_key: this._person_key,
      series: this._series,
      spz: this._spz,
      spz_trailer: this._spz_trailer,
      car_type: this._car_type,
      subnumber: 1,
      status: this._status,
      year: this._year
    };

    if (this._number) {
      obj.number = this._number;
    }

    return obj;
  }
}
