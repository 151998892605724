import { AddressBookItem } from "./address-book-item.object";
import { Invoice } from "./invoice.object";
import { Itinerary } from "./itinerary.object";
import { Order } from "./order.object";
import { Vehicle } from "./vehicle.object";
import { Attachment } from "./attachment.object";
import { DateTools } from "../tools/DateTools";
import { ServiceEventObject } from './service-event.object';
import { TrackingEvent } from './tracking-event.object';
import { WoodSystemLog } from './wood-system-log.object';
import { Email } from "./email.object";
import { Person } from "./person.object";
import { ObligationTools } from "../tools/ObligationTools";
import { WarehouseEvent } from "./warehouse-event.object";
import { AddressFavorite } from "./address-favorite.object";
import { ExpressRoute } from "./express-route.object";
import { ItineraryType, ObligationStatus } from "../config";

export class Obligation {
  private _obligation_key: number;
  get obligation_key(): number {
    return this._obligation_key;
  }
  set obligation_key(value: number) {
    this._obligation_key = value;
  }

  private _express_route_key: number = null;
  public get express_route_key(): number {
    return this._express_route_key;
  }
  public set express_route_key(value: number) {
    this._express_route_key = value;
  }

  // custom attribute for express route that could be filled
  private _express_route: ExpressRoute = null;
  public get express_route(): ExpressRoute {
    return this._express_route;
  }
  public set express_route(value: ExpressRoute) {
    this._express_route = value;
  }

  private _attachment: string;
  get attachment(): string {
    return this._attachment;
  }
  set attachment(value: string) {
    this._attachment = value;
  }

  private _attachments: Array<Attachment> = [];
  public get attachments(): Array<Attachment> {
    return this._attachments;
  }
  public set attachments(value: Array<Attachment>) {
    this._attachments = value;
  }

  private _attachments_verified: boolean = null;
  public get attachments_verified(): boolean {
    return this._attachments_verified;
  }
  public set attachments_verified(value: boolean) {
    this._attachments_verified = value;
  }

  private _attachment_link: string = null;
  get attachment_link(): string {
    return this._attachment_link;
  }
  set attachment_link(value: string) {
    this._attachment_link = value;
  }

  private _calculated_price: number = null;
  get calculated_price(): number {
    return this._calculated_price;
  }
  set calculated_price(value: number) {
    this._calculated_price = value;
  }

  private _car: Vehicle;
  get car(): Vehicle {
    return this._car;
  }
  set car(value: Vehicle) {
    this._car = value;
  }
  
  private _car_adr: boolean;
  get car_adr(): boolean {
    return this._car_adr;
  }
  set car_adr(value: boolean) {
    this._car_adr = value;
  }

  private _car_height: number;
  get car_height(): number {
    return this._car_height;
  }
  set car_height(value: number) {
    this._car_height = value;
  }

  private _car_key: number = null;
  get car_key(): number {
    return this._car_key;
  }
  set car_key(value: number) {
    this._car_key = value;
    // set temp car_key
    this._car_key_temp = value;
  }

  // custom
  private _car_key_temp: number = null;
  public get car_key_temp(): number {
    return this._car_key_temp;
  }
  public set car_key_temp(value: number) {
    this._car_key_temp = value;
  }

  private _car2_key: number = null;
  public get car2_key(): number {
    return this._car2_key;
  }
  public set car2_key(value: number) {
    this._car2_key = value;
  }

  // custom
  private _car2: Vehicle = null;
  public get car2(): Vehicle {
    return this._car2;
  }
  public set car2(value: Vehicle) {
    this._car2 = value;
  }

  // custom
  private _car2_key_temp: number = null;
  public get car2_key_temp(): number {
    return this._car2_key_temp;
  }
  public set car2_key_temp(value: number) {
    this._car2_key_temp = value;
  }

  private _car_length: number;
  get car_length(): number {
    return this._car_length;
  }
  set car_length(value: number) {
    this._car_length = value;
  }

  private _car_lifting_arm: boolean;
  get car_lifting_arm(): boolean {
    return this._car_lifting_arm;
  }
  set car_lifting_arm(value: boolean) {
    this._car_lifting_arm = value;
  }

  private _car_lifting_tailboard: boolean;
  get car_lifting_tailboard(): boolean {
    return this._car_lifting_tailboard;
  }
  set car_lifting_tailboard(value: boolean) {
    this._car_lifting_tailboard = value;
  }

  private _car_loading_type: string;
  public get car_loading_type(): string {
    return this._car_loading_type;
  }
  public set car_loading_type(value: string) {
    this._car_loading_type = value;
  }

  private _car_more_drivers: boolean;
  get car_more_drivers(): boolean {
    return this._car_more_drivers;
  }
  set car_more_drivers(value: boolean) {
    this._car_more_drivers = value;
  }

  private _car_oversize: boolean;
  get car_oversize(): boolean {
    return this._car_oversize;
  }
  set car_oversize(value: boolean) {
    this._car_oversize = value;
  }

  private _car_tonnage: number;
  get car_tonnage(): number {
    return this._car_tonnage;
  }
  set car_tonnage(value: number) {
    this._car_tonnage = value;
  }

  private _car_trailer: string;
  public get car_trailer(): string {
    return this._car_trailer;
  }
  public set car_trailer(value: string) {
    this._car_trailer = value;
  }
  
  private _car_type: string;
  public get car_type(): string {
    return this._car_type;
  }
  public set car_type(value: string) {
    this._car_type = value;
  }

  private _car_width: number;
  get car_width(): number {
    return this._car_width;
  }
  set car_width(value: number) {
    this._car_width = value;
  }

  private _cargo_gather: boolean;
  get cargo_gather(): boolean {
    return this._cargo_gather;
  }
  set cargo_gather(value: boolean) {
    this._cargo_gather = value;
  }

  private _code: string;
  get code(): string {
    return this._code;
  }
  set code(value: string) {
    this._code = value;
  }

  private _company_key: number;
  get company_key(): number {
    return this._company_key;
  }
  set company_key(value: number) {
    this._company_key = value;
  }

  private _contracted_price: number = null;
  get contracted_price(): number {
    return this._contracted_price;
  }
  set contracted_price(value: number) {
    this._contracted_price = value;
  }

  private _delivery_note: string = null;
  public get delivery_note(): string {
    return this._delivery_note;
  }
  public set delivery_note(value: string) {
    this._delivery_note = value;
  }

  // custom attribute for exchanging price to home currency
  private _exchange_price: number = 0;
  public get exchange_price(): number {
    return this._exchange_price;
  }
  public set exchange_price(value: number) {
    this._exchange_price = value;
  }

  // custom attribut for profit
  private _price_profit: number = 0;
  public get price_profit(): number {
    return this._price_profit;
  }
  public set price_profit(value: number) {
    this._price_profit = value;
  }

  // custom attribut for profit percents
  private _price_profit_percent: number = 0;
  public get price_profit_percent(): number {
    return this._price_profit_percent;
  }
  public set price_profit_percent(value: number) {
    this._price_profit_percent = value;
  }

  private _created_time: string;
  get created_time(): string {
    return this._created_time;
  }
  set created_time(value: string) {
    this._created_time = value;
    if (value) {
      this.created_time_input = DateTools.isoForDatetimeInput(DateTools.apiIsoToDate(value));
      this.created_time_date = DateTools.apiIsoToDate(value);
    }
  }

  private _created_time_date: Date;
  get created_time_date(): Date {
    return this._created_time_date;
  }
  set created_time_date(value: Date) {
    this._created_time_date = value;
  }

  // using in company_obligation
  private _created_time_input: string;
  public get created_time_input(): string {
    return this._created_time_input;
  }
  public set created_time_input(value: string) {
    this._created_time_input = value;
  }

  private _currency: string = null;
  get currency(): string {
    return this._currency;
  }
  set currency(value: string) {
    this._currency = value;
  }
  
  private _custom_order_number: string;
  public get custom_order_number(): string {
    return this._custom_order_number;
  }
  public set custom_order_number(value: string) {
    this._custom_order_number = value;
  }

  private _edoc_url: string;
  public get edoc_url(): string {
    return this._edoc_url;
  }
  public set edoc_url(value: string) {
    this._edoc_url = value;
  }

  // custom
  private _edoc_required_itinerary: boolean;
  public get edoc_required_itinerary(): boolean {
    return this._edoc_required_itinerary;
  }
  public set edoc_required_itinerary(value: boolean) {
    this._edoc_required_itinerary = value;
  }

  // custom
  private _edoc_required_documents: boolean;
  public get edoc_required_documents(): boolean {
    return this._edoc_required_documents;
  }
  public set edoc_required_documents(value: boolean) {
    this._edoc_required_documents = value;
  }

  // custom
  private _edoc_required_invoice: boolean;
  public get edoc_required_invoice(): boolean {
    return this._edoc_required_invoice;
  }
  public set edoc_required_invoice(value: boolean) {
    this._edoc_required_invoice = value;
  }
  
  // custom
  private _edoc_required_agreement: boolean;
  public get edoc_required_agreement(): boolean {
    return this._edoc_required_agreement;
  }
  public set edoc_required_agreement(value: boolean) {
    this._edoc_required_agreement = value;
  }

  // headers has been added to api GET call
  private _email_headers: Array<Email> = [];
  public get email_headers(): Array<Email> {
    return this._email_headers;
  }
  public set email_headers(value: Array<Email>) {
    this._email_headers = value;
  }

  private _email_msg_ids: Array<string> = [];
  public get email_msg_ids(): Array<string> {
    return this._email_msg_ids;
  }
  public set email_msg_ids(value: Array<string>) {
    this._email_msg_ids = value;
  }

  private _express_delivery: boolean;
  get express_delivery(): boolean {
    return this._express_delivery;
  }
  set express_delivery(value: boolean) {
    this._express_delivery = value;
  }
  
  private _exported_to: Array<any> = [];
  public get exported_to(): Array<any> {
    return this._exported_to;
  }
  public set exported_to(value: Array<any>) {
    this._exported_to = value;
    if (this._exported_to) {
      if (this.exported_to.find(e => e.export_type == 'TRNSE')) {
        this._transExported = true;
      }
      if (this.exported_to.find(e => e.export_type == 'TIMOC')) {
        this._timocomExported = true;
      }
      if (this.exported_to.find(e => e.export_type == 'RAALT')) {
        this._raalExported = true;
      }
    }
  }

  // custom getters
  private _transExported: boolean = false;
  public get transExported(): boolean {
    return this._transExported;
  }
  
  private _timocomExported: boolean = false;
  public get timocomExported(): boolean {
    return this._timocomExported;
  }
  
  private _raalExported: boolean = false;
  public get raalExported(): boolean {
    return this._raalExported;
  }

  private _invoices: Array<Invoice> = [];
  get invoices(): Array<Invoice> {
    return this._invoices;
  }
  set invoices(value: Array<Invoice>) {
    this._invoices = value;
  }

  private _itinerary: Array<Itinerary> = [];
  get itinerary(): Array<Itinerary> {
    return this._itinerary;
  }
  set itinerary(value: Array<Itinerary>) {
    this._itinerary = value;

    if (this._itinerary && this._itinerary.length) {
      let it = this._itinerary[this._itinerary.length - 1];
      if (it && it.arrival_time) {
        this._last_itinerary_month = it.arrival_time.getMonth();
      }
    }
  }

  get first_itinerary(): Itinerary {
    if (this._itinerary && this._itinerary.length) {
      let first: Itinerary = this._itinerary.find(
        i => i.type == ItineraryType.LOADING || i.type == ItineraryType.UNLOADING
      );
      if (first) {
        return first;
      }
    }
    return null;
  }

  get first_itinerary_time(): Date {
    if (this._itinerary && this._itinerary.length) {
      let first: Itinerary = this._itinerary.find(
        i => i.type == ItineraryType.LOADING || i.type == ItineraryType.UNLOADING
      );
      if (first) {
        return first.arrival_time;
      }
    }
    return null;
  }

  get last_itinerary_time(): Date {
    if (this._itinerary && this._itinerary.length) {
      let it = this._itinerary[this._itinerary.length - 1];
      return it.arrival_time;
    }
    return null;
  }

  get last_itinerary(): Itinerary {
    if (this._itinerary && this._itinerary.length) {
      return this._itinerary[this._itinerary.length - 1];
    }
    return null;
  }

  private _last_itinerary_month: number;
  public get last_itinerary_month(): number {
    return this._last_itinerary_month;
  }
  public set last_itinerary_month(value: number) {
    this._last_itinerary_month = value;
  }

  // custom
  private _last_itinerary_exchange_rate: number = 1;
  public get last_itinerary_exchange_rate(): number {
    return this._last_itinerary_exchange_rate;
  }
  public set last_itinerary_exchange_rate(value: number) {
    this._last_itinerary_exchange_rate = value;
  }

  /*******************************************************/
  /* Warehousing itinerary */
  /*******************************************************/
  get warehouse_itinerary(): Itinerary {
    if (this._itinerary && this._itinerary.length) {
      let w: Itinerary = this._itinerary.find(i => i.type == ItineraryType.WAREHOUSE || i.type == ItineraryType.WAREHOUSE_AUTO);
      if (w) {
        return w;
      }
    }
    return null;
  }

  get warehouse_itinerary_time(): Date {
    if (this._itinerary && this._itinerary.length) {
      let w: Itinerary = this._itinerary.find(i => i.type == ItineraryType.WAREHOUSE || i.type == ItineraryType.WAREHOUSE_AUTO);
      if (w) {
        return w.arrival_time;
      }
    }
    return null;
  }

  // 1st itinerary to warehouse itinerary
  get itinerary_warehouse_collection(): Array<Itinerary> {
    let idx: number = this.itinerary.indexOf(this.warehouse_itinerary);
    if (idx > -1) {
      return this._itinerary.slice(0, idx + 1);
    }
    return this._itinerary;
  }
  
  // warehouse itinerary to delivery itinerary
  get itinerary_warehouse_delivery(): Array<Itinerary> {
    let idx: number = this.itinerary.indexOf(this.warehouse_itinerary);
    if (idx > -1) {
      return this._itinerary.slice(idx);
    }
    return this._itinerary;
  }


  /*******************************************************/
  /* Transshipment itinerary */
  /*******************************************************/
  get transshipment_itinerary(): Itinerary {
    if (this._itinerary && this._itinerary.length) {
      let t: Itinerary = this._itinerary.find(i => i.type == ItineraryType.TRANSSHIPMENT);
      if (t) {
        return t;
      }
    }
    return null;
  }

  get transshipment_itinerary_time(): Date {
    if (this._itinerary && this._itinerary.length) {
      let w: Itinerary = this._itinerary.find(i => i.type == ItineraryType.TRANSSHIPMENT);
      if (w) {
        return w.arrival_time;
      }
    }
    return null;
  }

  // 1st itinerary to transshipment itinerary
  get itinerary_transshipment_collection(): Array<Itinerary> {
    let idx: number = this.itinerary.indexOf(this.transshipment_itinerary);
    if (idx > -1) {
      return this._itinerary.slice(0, idx + 1);
    }
    return this._itinerary;
  }
  
  // transshipment itinerary to delivery itinerary
  get itinerary_transshipment_delivery(): Array<Itinerary> {
    let idx: number = this.itinerary.indexOf(this.transshipment_itinerary);
    if (idx > -1) {
      return this._itinerary.slice(idx);
    }
    return this._itinerary;
  }


  // custom property, that could be set according to warehouse itinerary.line_key
  private _delivery_line_key: number;
  public get delivery_line_key(): number {
    return this._delivery_line_key;
  }
  public set delivery_line_key(value: number) {
    this._delivery_line_key = value;
  }

  // custom property, that could be set according to warehouse itinerary.line_key
  private _delivery_line: AddressFavorite;
  public get delivery_line(): AddressFavorite {
    return this._delivery_line;
  }
  public set delivery_line(value: AddressFavorite) {
    this._delivery_line = value;
  }

  private _favourite: boolean;
  get favourite(): boolean {
    return this._favourite;
  }
  set favourite(value: boolean) {
    this._favourite = value;
  }

  private _files: Array<Attachment> = [];
  public get files(): Array<Attachment> {
    return this._files;
  }
  public set files(value: Array<Attachment>) {
    this._files = value;
  }


  private _length: number = null;
  get length(): number {
    return this._length;
  }
  set length(value: number) {
    this._length = value;
  }

  private _note: string = null;
  get note(): string {
    return this._note;
  }
  set note(value: string) {
    this._note = value;
  }

  private _note_price: string = null;
  get note_price(): string {
    return this._note_price;
  }
  set note_price(value: string) {
    this._note_price = value;
  }

  private _number: number;
  public get number(): number {
    return this._number;
  }
  public set number(value: number) {
    this._number = value;
  }

  private _orders: Array<Order> = [];
  get orders(): Array<Order> {
    return this._orders;
  }
  set orders(value: Array<Order>) {
    this._orders = value;
    if (this._orders) {
      this._collection_order = this._orders.find(o => o.delivery_type == 'P');
      this._delivery_order = this._orders.find(o => o.delivery_type == 'D');
    }
  }

  // custom
  private _collection_order: Order = null;
  public get collection_order(): Order {
    return this._collection_order;
  }
  
  // custom
  private _delivery_order: Order = null;
  public get delivery_order(): Order {
    return this._delivery_order;
  }

  private _order_comp_book: AddressBookItem;
  get order_comp_book(): AddressBookItem {
    return this._order_comp_book;
  }
  set order_comp_book(value: AddressBookItem) {
    this._order_comp_book = value;
    if (this._order_comp_book && this._order_comp_book instanceof AddressBookItem) {
      // set edoc properties
      if (value.edoc_required_itinerary) {
        this.edoc_required_itinerary = value.edoc_required_itinerary;
      } 
      else {
        this.edoc_required_itinerary = false;
      }

      if (value.edoc_required_documents) {
        this.edoc_required_documents = value.edoc_required_documents;
      } 
      else {
        this.edoc_required_documents = false;
      }

      if (value.edoc_required_invoice) {
        this.edoc_required_invoice = value.edoc_required_invoice;
      } 
      else {
        this.edoc_required_invoice = false;
      }
      
      if (value.edoc_required_agreement) {
        this.edoc_required_agreement = value.edoc_required_agreement;
      } 
      else {
        this.edoc_required_agreement = false;
      }
    }
  }

  get order_comp_name(): string {
    return this._order_comp_book ? this._order_comp_book.company : '';
  }
  
  // private _order_comp_name_long: string;
  // get order_comp_name_long(): string {
  //     return this._order_comp_name_long;
  // }
  // set order_comp_name_long(value: string) {
  //     this._order_comp_name_long = value;
  // }

  private _order_comp_book_key: number;
  get order_comp_book_key(): number {
    return this._order_comp_book_key;
  }
  set order_comp_book_key(value: number) {
    this._order_comp_book_key = value;
  }

  private _order_number: string;
  get order_number(): string {
    return this._order_number;
  }
  set order_number(value: string) {
    this._order_number = value;
  }

  // type "any" because of autocompleters ngbtypeahead
  private _payer_contact: any;
  get payer_contact(): any {
    return this._payer_contact;
  }
  set payer_contact(value: any) {
    this._payer_contact = value;
  }

  private _payer_email: any;
  get payer_email(): any {
    return this._payer_email;
  }
  set payer_email(value: any) {
    this._payer_email = value;
  }

  private _payer_phone: any;
  get payer_phone(): any {
    return this._payer_phone;
  }
  set payer_phone(value: any) {
    this._payer_phone = value;
  }

  private _piece_shipment: boolean = null;
  get piece_shipment(): any {
    return this._piece_shipment;
  }
  set piece_shipment(value: any) {
    this._piece_shipment = value;
  }

  // piece shipment with only delivery (mode Rozvoz) - only custom temp attribute (not in db)
  private _piece_shipment_delivery: boolean = null;
  public get piece_shipment_delivery(): boolean {
    return this._piece_shipment_delivery;
  }
  public set piece_shipment_delivery(value: boolean) {
    this._piece_shipment_delivery = value;
  }

  private _order_number_standard: string;
  get order_number_standard(): string {
    return this._order_number_standard;
  }
  set order_number_standard(value: string) {
    this._order_number_standard = value;
  }

  private _route_length: number = null;
  get route_length(): number {
    return this._route_length;
  }
  set route_length(value: number) {
    this._route_length = value;
    if (value) {
      this._route_length_rounded = Math.round(value);
    }
  }

  private _route_length_rounded: number = 0;
  public get route_length_rounded(): number {
    return this._route_length_rounded;
  }

  private _route_length_real: number = null;
  public get route_length_real(): number {
    return this._route_length_real;
  }
  public set route_length_real(value: number) {
    this._route_length_real = value;
    if (value) {
      this._route_length_real_rounded = Math.round(value);
    }
  }

  private _route_length_real_rounded: number = 0;
  public get route_length_real_rounded(): number {
    return this._route_length_real_rounded;
  }

  // custom
  private _route_length_airline: number = 0;
  public get route_length_airline(): number {
    return this._route_length_airline;
  }
  public set route_length_airline(value: number) {
    this._route_length_airline = value;
  }

  // custom
  private _route_length_estimated: number = 0;
  public get route_length_estimated(): number {
    return this._route_length_estimated;
  }
  public set route_length_estimated(value: number) {
    this._route_length_estimated = value;
  }

  private _route_map_url: string;
  get route_map_url(): string {
    return this._route_map_url;
  }
  set route_map_url(value: string) {
    this._route_map_url = value;
  }

  private _series: number = null;
  get series(): number {
    return this._series;
  }
  set series(value: number) {
    this._series = value;
  }

  private _status: string = "E";
  get status(): string {
    return this._status;
  }
  set status(value: string) {
    this._status = value;
    this._backgroundColorClass = ObligationTools.setBackground(this._status);
    this._backgroundColorRowClass = ObligationTools.setBackgroundTableRow(this._status);
    
    switch (this._status) {
      case ObligationStatus.K_VYRIZENI:
        this._statusToString = $localize`K vyřízení`; break;
      case ObligationStatus.POPTAVKA:
        this._statusToString = $localize`Poptávka`; break;
      case ObligationStatus.ZAHAJENA:
        this._statusToString = $localize`Zahájená`; break;
      case ObligationStatus.SKLAD_SVOZ:
        this._statusToString = $localize`Sklad - Svoz`; break;
      case ObligationStatus.SKLAD:
        this._statusToString = $localize`Sklad`; break;
      case ObligationStatus.SKLAD_K_ROZVOZU:
        this._statusToString = $localize`Sklad - K rozvozu`; break;
      case ObligationStatus.SKLAD_ROZVOZ:
        this._statusToString = $localize`Sklad - rozvoz`; break;
      case ObligationStatus.K_FAKTURACI:
        this._statusToString = $localize`K fakturaci`; break;
      case ObligationStatus.FAKTUROVANA:
        this._statusToString = $localize`Fakturovaná`; break;
      case ObligationStatus.DOKONCENA:
        this._statusToString = $localize`Dokončená`; break;
      case ObligationStatus.MIMO_PROVOZ:
        this._statusToString = $localize`Mimo provoz`; break;
      case ObligationStatus.STORNOVANA:
        this._statusToString = $localize`Stornovaná`; break;
      default:
        this._statusToString = ''; break;
    }
  }

  private _statusToString: string;
  public get statusToString(): string {
    return this._statusToString;
  }
  public set statusToString(value: string) {
    this._statusToString = value;
  }
  
  // custom property for color in list of obligations according to status
  private _backgroundColorClass: string = '';
  get backgroundColorClass(): string {
    return this._backgroundColorClass;
  }
  set backgroundColorClass(value: string) {
    this._backgroundColorClass = value;
  }
  
  private _backgroundColorRowClass: string = '';
  get backgroundColorRowClass(): string {
    return this._backgroundColorRowClass;
  }
  set backgroundColorRowClass(value: string) {
    this._backgroundColorRowClass = value;
  }

  // custom
  private _allow_subnumber: boolean = false;
  public get allow_subnumber(): boolean {
    return this._allow_subnumber;
  }
  public set allow_subnumber(value: boolean) {
    this._allow_subnumber = value;
  }
  
  // custom
  private _subnumber_interval: number = 0;
  public get subnumber_interval(): number {
    return this._subnumber_interval;
  }
  public set subnumber_interval(value: number) {
    this._subnumber_interval = value;
  }

  private _subnumber: number = 1;
  public get subnumber(): number {
    return this._subnumber;
  }
  public set subnumber(value: number) {
    this._subnumber = value;
  }

  private _updated_by: string;
  get updated_by(): string {
    return this._updated_by;
  }
  set updated_by(value: string) {
    this._updated_by = value;
  }

  private _updated_time: string;
  get updated_time(): string {
    return this._updated_time;
  }
  set updated_time(value: string) {
    this._updated_time = value;
  }
  
  private _transit_length_real: number = null;
  public get transit_length_real(): number {
    return this._transit_length_real;
  }
  public set transit_length_real(value: number) {
    this._transit_length_real = value;
    if (value) {
      this._transit_length_real_rounded = Math.round(value);
    }
  }

  private _transit_length_real_rounded: number = 0;
  public get transit_length_real_rounded(): number {
    return this._transit_length_real_rounded;
  }

  private _volume: number = null;
  get volume(): number {
    return this._volume;
  }
  set volume(value: number) {
    this._volume = value;
  }

  private _ware_type: string = null;
  get ware_type(): string {
    return this._ware_type;
  }
  set ware_type(value: string) {
    this._ware_type = value;
  }

  private _weight: number = null;
  get weight(): number {
    return this._weight;
  }
  set weight(value: number) {
    this._weight = value;
  }

  private _web_origin: string = null;
  public get web_origin(): string {
    return this._web_origin;
  }
  public set web_origin(value: string) {
    this._web_origin = value;
  }

  private _year: number = new Date().getFullYear();
  get year(): number {
    return this._year;
  }
  set year(value: number) {
    this._year = value;
  }

  // custom property for multiple obligations invoicing
  private _marked: boolean = false;
  public get marked(): boolean {
    return this._marked;
  }
  public set marked(value: boolean) {
    this._marked = value;
  }


  // custom arrays for creating obligations from service/tracking events
  private _serviceEvents: Array<ServiceEventObject> = [];
  public get serviceEvents(): Array<ServiceEventObject> {
    return this._serviceEvents;
  }
  public set serviceEvents(value: Array<ServiceEventObject>) {
    this._serviceEvents = value;
  }

  private _trackingEvents: Array<TrackingEvent> = [];
  public get trackingEvents(): Array<TrackingEvent> {
    return this._trackingEvents;
  }
  public set trackingEvents(value: Array<TrackingEvent>) {
    this._trackingEvents = value;
  }

  private _serviceEventsStrings: Array<string> = [];
  public get serviceEventsStrings(): Array<string> {
    return this._serviceEventsStrings;
  }
  public set serviceEventsStrings(value: Array<string>) {
    this._serviceEventsStrings = value;
  }
  
  private _serviceEventsPrints: Array<string> = [];
  public get serviceEventsPrints(): Array<string> {
    return this._serviceEventsPrints;
  }
  public set serviceEventsPrints(value: Array<string>) {
    this._serviceEventsPrints = value;
  }

  private _loadingServiceEvents: boolean = false;
  public get loadingServiceEvents(): boolean {
    return this._loadingServiceEvents;
  }
  public set loadingServiceEvents(value: boolean) {
    this._loadingServiceEvents = value;
  }

  private _loadingTrackingEvents: boolean = false;
  public get loadingTrackingEvents(): boolean {
    return this._loadingTrackingEvents;
  }
  public set loadingTrackingEvents(value: boolean) {
    this._loadingTrackingEvents = value;
  }
  
  private _woodLogs: Array<WoodSystemLog> = [];
  public get woodLogs(): Array<WoodSystemLog> {
    return this._woodLogs;
  }
  public set woodLogs(value: Array<WoodSystemLog>) {
    this._woodLogs = value;
  }

  // true if all logs are sent and confirmed
  private _woodLogsSentConfirmed: boolean = false;
  public get woodLogsSentConfirmed(): boolean {
    return this._woodLogsSentConfirmed;
  }
  public set woodLogsSentConfirmed(value: boolean) {
    this._woodLogsSentConfirmed = value;
  }
  
  // true if some log has been manually repaired
  private _woodLogsManuallySent: boolean = false;
  public get woodLogsManuallySent(): boolean {
    return this._woodLogsManuallySent;
  }
  public set woodLogsManuallySent(value: boolean) {
    this._woodLogsManuallySent = value;
  }

  public get obligationNumberFormatted(): string {
    if (!this._order_number_standard) {
      return '';
    }
    return 'ZA' + this._order_number_standard;
  }

  // flag used in dispatcher board component
  private _isOutOfInterval: boolean = false;
  public get isOutOfInterval(): boolean {
    return this._isOutOfInterval;
  }
  public set isOutOfInterval(value: boolean) {
    this._isOutOfInterval = value;
  }

  private _uploadingObligation: boolean = false;
  public get uploadingObligation(): boolean {
    return this._uploadingObligation;
  }
  public set uploadingObligation(value: boolean) {
    this._uploadingObligation = value;
  }

  // custom warehouse logic attributes
  private _warehouse_event_loading: WarehouseEvent = null;
  get warehouse_event_loading(): WarehouseEvent {
    return this._warehouse_event_loading;
  }
  set warehouse_event_loading(value: WarehouseEvent) {
    this._warehouse_event_loading = value;
  }
  
  private _warehouse_event_unloading: WarehouseEvent = null;
  get warehouse_event_unloading(): WarehouseEvent {
    return this._warehouse_event_unloading;
  }
  set warehouse_event_unloading(value: WarehouseEvent) {
    this._warehouse_event_unloading = value;
  }

  private _warehouseTimePlan: Date;
  get warehouseTimePlan(): Date {
    return this._warehouseTimePlan;
  }
  set warehouseTimePlan(value: Date) {
    this._warehouseTimePlan = value;
  }

  private _deliveryTimePlan: Date;
  get deliveryTimePlan(): Date {
    return this._deliveryTimePlan;
  }
  set deliveryTimePlan(value: Date) {
    this._deliveryTimePlan = value;
  }

  // custom attribute placeholder for obligation fuel service event price
  private _obligationFuelPrice: number;
  public get obligationFuelPrice(): number {
    return this._obligationFuelPrice;
  }
  public set obligationFuelPrice(value: number) {
    this._obligationFuelPrice = value;
  }
  
  private _obligationFuelCurrency: string;
  public get obligationFuelCurrency(): string {
    return this._obligationFuelCurrency;
  }
  public set obligationFuelCurrency(value: string) {
    this._obligationFuelCurrency = value;
  }

  // custom property for preview/print PDF 
  private _previewPDF: any = null;
  public get previewPDF(): any {
    return this._previewPDF;
  }
  public set previewPDF(value: any) {
    this._previewPDF = value;
  }

  // custom property for DHL obligations
  private _isDHL: boolean = false;
  public get isDHL(): boolean {
    return this._isDHL;
  }
  public set isDHL(value: boolean) {
    this._isDHL = value;
  }
  
  // custom property for Wienerberger obligations
  private _isWienerberger: boolean = false;
  public get isWienerberger(): boolean {
    return this._isWienerberger;
  }
  public set isWienerberger(value: boolean) {
    this._isWienerberger = value;
  }

  private _wienebergerZone: number = null;
  public get wienebergerZone(): number {
    return this._wienebergerZone;
  }
  public set wienebergerZone(value: number) {
    this._wienebergerZone = value;
  }
  
  // transshipment
  private _transshipment: boolean = false;
  public get transshipment(): boolean {
    return this._transshipment;
  }
  public set transshipment(value: boolean) {
    this._transshipment = value;
  }


  get apiObjectCreate(): any {
    // get values - conditions because of type Person/string
    let contact: string = this.payer_contact instanceof Person ? this.payer_contact.name : this.payer_contact;
    let email: string = this.payer_email instanceof Person ? this.payer_email.email : this.payer_email;
    let phone: string = '';
    if (this.payer_phone instanceof Person) {
      phone = this.payer_phone.mobile ? this.payer_phone.mobile : this.payer_phone.phone;
    }
    else {
      phone = this.payer_phone;
    }

    // initialize data
    let data = {
      series: this.series,
      status: this.status,
      year: this.year,
      order_number: this.order_number,
      code: this.code,
      delivery_note: this.delivery_note,
      edoc_url: this.edoc_url,
      order_comp_book_key: this.order_comp_book_key,
      payer_contact: contact,
      payer_email: email,
      payer_phone: phone,
      car_key: this.car_key,
      car2_key: this.car2_key,
      car_height: this.car_height,
      car_width: this.car_width,
      car_length: this.car_length,
      car_tonnage: this.car_tonnage,
      car_type: this.car_type,
      car_trailer: this.car_trailer,
      subnumber: 1,
      contracted_price: this.contracted_price,
      currency: this.currency,
      calculated_price: this.calculated_price,
      note_price: this.note_price,
      length: this.length,
      weight: this.weight,
      route_length: this.route_length,
      note: this.note,
      favourite: this.favourite,
      custom_order_number: this.custom_order_number,
      route_length_real: this.route_length_real,
      transit_length_real: this.transit_length_real,
      piece_shipment: this.piece_shipment,
      express_delivery: this.express_delivery,
      express_route_key: this.express_route_key,
      web_origin: this._web_origin,
      transshipment: this.transshipment
    };
    
    if (this.created_time_input) {
      data['created_time'] = DateTools.toIsoWithoutMilisec(new Date(this.created_time_input));
    }

    return data;
  }

  get apiObjectUpdate(): any {
    // get values - conditions because of type Person/string
    let contact: string = this.payer_contact instanceof Person ? this.payer_contact.name : this.payer_contact;
    let email: string = this.payer_email instanceof Person ? this.payer_email.email : this.payer_email;
    let phone: string = '';
    if (this.payer_phone instanceof Person) {
      phone = this.payer_phone.mobile ? this.payer_phone.mobile : this.payer_phone.phone;
    }
    else {
      phone = this.payer_phone;
    }

    // initialize data
    let data = {
      attachments_verified: this.attachments_verified,
      series: this.series,
      status: this.status,
      year: this.year,
      order_number: this.order_number,
      code: this.code,
      delivery_note: this.delivery_note,
      edoc_url: this.edoc_url,
      order_comp_book_key: this.order_comp_book_key,
      payer_contact: contact,
      payer_email: email,
      payer_phone: phone,
      car_key: this.car_key,
      car2_key: this.car2_key,
      car_height: this.car_height,
      car_width: this.car_width,
      car_length: this.car_length,
      car_tonnage: this.car_tonnage,
      car_type: this.car_type,
      car_trailer: this.car_trailer,
      // number: this.number,
      // subnumber: this.subnumber, umoznime zmenit subnumber?
      contracted_price: this.contracted_price,
      currency: this.currency,
      calculated_price: this.calculated_price,
      note_price: this.note_price,
      length: this.length,
      weight: this.weight,
      route_length: this.route_length,
      note: this.note,
      favourite: this.favourite,
      custom_order_number: this.custom_order_number,
      route_length_real: this.route_length_real,
      transit_length_real: this.transit_length_real,
      piece_shipment: this.piece_shipment,
      express_delivery: this.express_delivery,
      express_route_key: this.express_route_key,
      exported_to: this.exported_to,
      transshipment: this.transshipment
    };

    if (this.created_time_input) {
      data['created_time'] = DateTools.toIsoWithoutMilisec(new Date(this.created_time_input));
    }
    return data;
  }
}
