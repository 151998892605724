import { AddressBookItem } from '../model/address-book-item.object';
import { Invoice } from '../model/invoice.object';
import { Obligation } from '../model/obligation.object';
import { Order } from '../model/order.object';
import { InvoiceItem } from '../model/invoice-item.object';
import { InvoiceReminder } from '../model/invoice-reminder.object';
import { Email } from '../model/email.object';

import { ObligationTools } from './ObligationTools';
import { OrderTools } from './OrderTools';
import { Attachment } from '../model/attachment.object';

// Tools for building invoice objects (used in more services)
export class InvoiceTools {
    
  // method for creating invoice objects from api objects
  static buildInvoicesFromData(data: Array<any>): any {
    let invoices: Array<Invoice> = [];
    data.forEach(
      i => {
        let invoice = InvoiceTools.buildInvoice(i);
        invoices.push(invoice);
      }
    );
    return invoices;
  }

  // method for creating a single invoice object
  static buildInvoice(i: any): Invoice {
    if (!i) return;
    
    let invoice: Invoice = new Invoice();

    if (i.attachment) {
      let attachment = new Attachment();
      for (let key in i.attachment) {
        attachment[key] = i.attachment[key];
      }
      i.attachment = attachment;
    }
    if (i.comp_book) {
      let comp_book = new AddressBookItem();
      for (let key in i.comp_book) {
        comp_book[key] = i.comp_book[key];
      }
      i.comp_book = comp_book;
    }
    // nested json objects
    if (i.items && i.items.length) {
      let itemsArr = new Array<InvoiceItem>();
      // sort by item_key by default
      i.items.sort((a, b) => (a.item_key > b.item_key) ? 1 : -1);
      i.items.forEach(
        x => {
          let item = new InvoiceItem();
          for (let key in x) {
            item[key] = x[key];
          }
          itemsArr.push(item);
        }
      );
      i.items = itemsArr;
    }
    // we prefer array here, not null
    if (!i.email_msg_ids) i.email_msg_ids = [];
    if (!i.email_headers) i.email_headers = [];
    if (i.email_headers && i.email_headers.length) {
      let headersArr = new Array<Email>();
      i.email_headers.forEach(
        (x, index) => {
          let header = new Email();
          for (let key in x) {
            header[key] = x[key];
          }
          // save also id of email
          if (i.email_msg_ids[index]) {
            header.email_msg_id = i.email_msg_ids[index];
          }
          headersArr.push(header);
        }
      );
      i.email_headers = headersArr;
    }
    if (i.obligations && i.obligations.length) {
      i.obligations = ObligationTools.buildObligationsFromData(i.obligations);
      i.obligations.sort((a, b) => (a.number > b.number) ? 1 : -1);
    }
    if (i.orders && i.orders.length) {
      i.orders = OrderTools.buildOrdersFromData(i.orders);
    }
    if (i.reminders && i.reminders.length) {
      let remindersArr = new Array<InvoiceReminder>();
      i.reminders.forEach(
        x => {
          let reminder = new InvoiceReminder();
          for (let key in x) {
            reminder[key] = x[key];
          }
          remindersArr.push(reminder);
        }
      );
      i.reminders = remindersArr;
    }

    for (let key in i) {
      invoice[key] = i[key];
      // set background color
      invoice.backgroundColorClass = InvoiceTools.setInvoiceBackground(invoice);
      invoice.backgroundColorRowClass = InvoiceTools.setInvoiceBackgroundTableRow(invoice);
    }

    return invoice;
  }


  // method for creating a single invoice item object
  static buildInvoiceItem(i: any): InvoiceItem {
    if (!i) return null;
    
    let item: InvoiceItem = new InvoiceItem();
    for (let key in i) {
      item[key] = i[key];
    }
    return item;
  }
  
  // method for creating a single invoice email header object
  static buildInvoiceEmail(header: any): Email {
    let invoiceEmail: Email = new Email();
    for (let key in header) {
      invoiceEmail[key] = header[key];
    }

    return invoiceEmail;
  }
  
  // method for setting background color according to status of obligation
  static setInvoiceBackground(inv: Invoice): string {
    let TODAY: Date = new Date();

    if (inv.maturityDate && inv.maturityDate < TODAY && !inv.paymentDate) {
      return 'redBackground';
    }
    else if (inv.maturityDate && inv.paymentDate && inv.paymentDate <= inv.maturityDate && (!inv.reminders || !inv.reminders.length)) {
      return 'greenBackground';
    }
    else if (inv.maturityDate && inv.paymentDate && inv.maturityDate < inv.paymentDate) {
      return 'purpleBackground';
    }
    // TODO co nejak oznacit i aktualni faktury?
    return 'whiteDefaultRow';
    // return '';
  }

  // method for setting background color of table row according to payment/maturity (with alpha)
  static setInvoiceBackgroundTableRow(inv: Invoice): string {
    let TODAY: Date = new Date();

    if (inv.maturityDate && inv.maturityDate < TODAY && !inv.paymentDate) {
      if (!inv.reminders || !inv.reminders.length) {
        return 'redBackgroundRow20';
      }
      else if (inv.reminders.length == 1) {
        return 'redBackgroundRow40';
      }
      else {
        return 'redBackgroundRow70';
      }
    }
    else if (inv.maturityDate && inv.paymentDate && inv.paymentDate <= inv.maturityDate && (!inv.reminders || !inv.reminders.length)) {
      return 'greenBackgroundRow';
    }
    else if (inv.paymentDate && inv.maturityDate && inv.maturityDate < inv.paymentDate) {
      if (!inv.reminders || !inv.reminders.length) {
        return 'purpleBackgroundRow20';
      }
      else if (inv.reminders.length == 1) {
        return 'purpleBackgroundRow40';
      }
      else {
        return 'purpleBackgroundRow70';
      }
    }
    return 'whiteDefaultRow';
  }
}
