import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { IS_DEMO, ItineraryType, ObligationStatus, OrderStatus } from 'src/app/config';
import { TruckManagerLayoutService } from 'src/app/service/truck-manager-layout.service';
import { ObligationService } from 'src/app/service/obligation.service';
import { WebsocketService } from 'src/app/service/websocket.service';
import { WarehouseService } from 'src/app/service/warehouse.service';
import { Ta1ObligationEmailingComponent } from '../ta1-obligation-emailing/ta1-obligation-emailing.component';
import { WebsocketResponse } from 'src/app/interface/websocket-response.interface';
import { ItineraryDiaryObligationCollection } from 'src/app/model/itinerary-diary-obligation-collection.object';
import { Attachment } from 'src/app/model/attachment.object';
import { Email } from 'src/app/model/email.object';
import { Itinerary } from 'src/app/model/itinerary.object';
import { Obligation } from 'src/app/model/obligation.object';
import { WarehouseEvent } from 'src/app/model/warehouse-event.object';
import { DateTools } from 'src/app/tools/DateTools';
import { NotificationService } from 'src/app/service/notification-service';
import { Order } from 'src/app/model/order.object';
import { OrderService } from 'src/app/service/order.service';

// declare let gtag: Function;
declare var $: any;

@Component({
  selector: 'app-r-it-obligation-diary',
  templateUrl: './r-it-obligation-diary.component.html',
  styleUrls: ['./r-it-obligation-diary.component.css'],
  providers: [
    ObligationService,
    OrderService,
    WarehouseService
  ]
})
export class RItObligationDiaryComponent implements OnInit, OnDestroy {

  private _subscribed: Array<Subscription> = [];
  private _TODAY: Date = new Date();

  // switch flag for sorting according to hour or grouped obligation
  private _sortByHour: boolean = true;
  public get sortByHour(): boolean {
    return this._sortByHour;
  }
  public set sortByHour(value: boolean) {
    this._sortByHour = value;
    if (this._sortByHour) {
      this.sortedItinerary.markByHour();
    }
    else {
      this.sortedItinerary.markByObligation();
    }
  }
  
  private _obligations: Array<Obligation> = [];

  // main collection of itinerary
  private _sortedItinerary: ItineraryDiaryObligationCollection = new ItineraryDiaryObligationCollection([]);
  get sortedItinerary(): ItineraryDiaryObligationCollection {
    return this._sortedItinerary;
  }
  
  // yesterday/today/tomorrow management
  private _visibleToday: number = 0;
  private _visibleTomorrow: number = 1;
  private _visibleYesterday: number = -1;
  private _visible: number = this._visibleToday;
  
  // reloading obligations after itinerary
  private _reloading: boolean = false;
  public get reloading(): boolean {
    return this._reloading;
  }

  constructor(
    private _router: Router,
    private _layout: TruckManagerLayoutService,
    private _webSocketServ: WebsocketService,
    private _obligationServ: ObligationService,
    private _orderServ: OrderService,
    private _warehouseServ: WarehouseService,
    private _notificationServ: NotificationService,
    private _changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    // if (!window.location.href.includes('app2') && !window.location.href.includes('localhost')) {
    //   gtag('config', 'UA-116483982-1', { 'page_path': 'diar_nakladek_vykladek' });
    // }

    // load obligations and build collection
    this.loadObligations();

    // websocket observing
    this._subscribed.push(
      this._webSocketServ.companyMessage.subscribe(
        (wsMessage: WebsocketResponse) => {
          // if (wsMessage.relation === WebsocketService.RELATIONS.obligation_itinerary) {
          //   this.reloadObligations();
          // }
          // if (wsMessage.relation === WebsocketService.RELATIONS.obligation) {
          //   this.reloadObligations();
          // }
          if (wsMessage.relation === WebsocketService.RELATIONS.obligation_itinerary) {
            this.reloadObligations();
          }
          else if (wsMessage.relation === WebsocketService.RELATIONS.order) {
            this.reloadObligations();
          }
          // file from scanning app (authored by Tom V.)
          else if (wsMessage.relation == WebsocketService.RELATIONS.obligation_msg && wsMessage.operation == WebsocketService.OPERATIONS.update) {
            this.reloadObligations();
          }
        }
      )
    );
  }

  ngOnDestroy() {
    this._subscribed.forEach(
      subscriber => subscriber.unsubscribe()
    );
    this._subscribed = [];
  }

  reloadObligations(): void {
    if (!this._reloading) {
      this._reloading = true;
      window.setTimeout(
        () => {
          // load obligations and build collection
          this.loadObligations();
          this._reloading = false;
        },
        3000
      );
    }
  }

  loadObligations(): void {
    let todayBefore10days: Date = new Date(this._TODAY.getTime() - 10*24*60*60*1000);
    let itin_tf: string = DateTools.toApiFormat(todayBefore10days);

    let todayPlus2days: Date = new Date(this._TODAY.getTime() + 2*24*60*60*1000);
    let itin_tt: string = DateTools.toApiFormat(todayPlus2days);

    let filterObj: any = { itin_tf: itin_tf, itin_tt: itin_tt, has_order: true };
    // year: this._TODAY.getFullYear(),
    let sortObj: any = { itin_arrival: 'desc' }; // created: 'desc'
    let page: number = 0;
    let size: number = 100;

    this._sortedItinerary = new ItineraryDiaryObligationCollection([]);

    this._subscribed.push(
      this._obligationServ.getObligationsFilteredRequest(filterObj, sortObj, page, size).subscribe(
        response => {
          if (response && response.length) {
            let new_itineraries: Array<Itinerary> = [];
            this._obligations = response;
            // keep only obligations with order
            this._obligations = this._obligations.filter(o => o.orders && o.orders.length);
            this._obligations = this._obligations.filter(
              o => o.status != ObligationStatus.K_VYRIZENI && o.status != ObligationStatus.STORNOVANA
            );


            this._obligations.forEach(
              o => {
                if (o.orders && o.orders.length) {
                  let tmp_itinerary: Array<Itinerary> = [];
                  // piece shipment logic - collection order or delivery order
                  if (o.piece_shipment && o.orders.length) {
                    o.orders.forEach(
                      order => {
                        // use setter to create order obligations - itinerary array
                        order.obligations = [o];

                        if (order.delivery_type) {
                          if (order.delivery_type == 'P') {
                            // let warehouse_itinerary = o.warehouse_itinerary;
                            // warehouse_itinerary.warehouse_in_itinerary = true;
                            // itinerary = o.itinerary_warehouse_collection;
                            let warehouse_itinerary = order.itinerary.find(
                              it => it.type == ItineraryType.WAREHOUSE || it.type == ItineraryType.WAREHOUSE_AUTO
                            );
                            if (warehouse_itinerary) {
                              // if already exist ~ order for collection and delivery as well
                              if (tmp_itinerary.find(it => it.itinerary_key == warehouse_itinerary.itinerary_key)) {
                                // deep copy and working with copy in warehouse_itinerary variable
                                let it: Itinerary = warehouse_itinerary.itineraryCopy;
                                it.warehouse_out_event = null;
                                it.warehouse_out_itinerary = false;
                                warehouse_itinerary = it;
                                order.itinerary[0] = warehouse_itinerary;
                              }
                              warehouse_itinerary.warehouse_in_itinerary = true;
                              tmp_itinerary = tmp_itinerary.concat(order.itinerary);

                              // load info about warehouse events
                              let time_from_date: Date = warehouse_itinerary.arrival_time;
                              // resize tf interval (without that some events could be missing)
                              let sevenDaysLess: Date = new Date(time_from_date.getTime() - 7*24*60*60*1000);
                              let sevenDaysMore: Date = new Date(time_from_date.getTime() + 7*24*60*60*1000);
                              let filterObj: any = {
                                tf: DateTools.toCustomIsoApiFormat(sevenDaysLess),
                                tt: DateTools.toCustomIsoApiFormat(sevenDaysMore),
                                ok: order.order_key
                                // on: o.order_number_standard
                              };
                              // load info about warehouse events
                              this._subscribed.push(
                                this._warehouseServ.getEvents(filterObj).subscribe(
                                  response => {
                                    if (response) {
                                      // console.log(response);
                                      // find unloading (naskladneni)
                                      let collection: WarehouseEvent = response.find(
                                        e => e.type == ItineraryType.UNLOADING
                                      );
                                      if (collection) {
                                        warehouse_itinerary.warehouse_in_event = collection;
                                        warehouse_itinerary.arrival_time = collection.timeDate;
                                      }
                                    }
                                  }
                                )
                              );
                            }
                          }
                          else if (order.delivery_type == 'D') {
                            // let warehouse_itinerary = o.warehouse_itinerary;
                            // warehouse_itinerary.warehouse_out_itinerary = true;
                            // itinerary = o.itinerary_warehouse_delivery;
                            let warehouse_itinerary = order.itinerary.find(
                              it => it.type == ItineraryType.WAREHOUSE || it.type == ItineraryType.WAREHOUSE_AUTO
                            );
                            if (warehouse_itinerary) {
                              // if already exist ~ order for collection and delivery as well
                              if (tmp_itinerary.find(it => it.itinerary_key == warehouse_itinerary.itinerary_key)) {
                                // deep copy and working with copy in warehouse_itinerary variable
                                let it: Itinerary = warehouse_itinerary.itineraryCopy;
                                it.warehouse_in_event = null;
                                it.warehouse_in_itinerary = false;
                                warehouse_itinerary = it;
                                order.itinerary[0] = warehouse_itinerary;
                              }
                              warehouse_itinerary.warehouse_out_itinerary = true;
                              tmp_itinerary = tmp_itinerary.concat(order.itinerary);

                              // load info about warehouse events
                              let time_from_date: Date = warehouse_itinerary.arrival_time;
                              // resize tf interval (without that some events could be missing)
                              let sevenDaysLess: Date = new Date(time_from_date.getTime() - 7*24*60*60*1000);
                              let sevenDaysMore: Date = new Date(time_from_date.getTime() + 7*24*60*60*1000);
                              let filterObj: any = {
                                tf: DateTools.toCustomIsoApiFormat(sevenDaysLess),
                                tt: DateTools.toCustomIsoApiFormat(sevenDaysMore),
                                ok: order.order_key
                                // on: o.order_number_standard
                              };
        
                              // load info about warehouse events
                              this._subscribed.push(
                                this._warehouseServ.getEvents(filterObj).subscribe(
                                  response => {
                                    if (response) {
                                      // console.log(response);
                                      // find loading (vyskladneni)
                                      let delivery: WarehouseEvent = response.find(
                                        e => e.type == ItineraryType.LOADING
                                      );
                                      if (delivery) {
                                        warehouse_itinerary.warehouse_out_event = delivery;
                                        warehouse_itinerary.arrival_time = delivery.timeDate;
                                      }
                                    }
                                  }
                                )
                              );
                            }
                          }
                        }
                      }
                    );
                  }
                  else {
                    tmp_itinerary = o.itinerary;
                    
                    o.orders.forEach(
                      order => {
                        // use setter to create order obligations - itinerary array
                        order.obligations = [o];
                      }
                    );
                  }

                  // init itinerary from obligation/order
                  if (tmp_itinerary) {
                    tmp_itinerary.forEach(
                      it => {
                        // save reference to each itinerary
                        it.obligation = o;
                        it.accepted_by = o.orders[0].accepted_by;
                        it.number_plate = o.orders[0].spz;
                        it.driver_name = o.orders[0].driver_name;
                        it.driver_phone = o.orders[0].driver_phone;
                        if (o.order_comp_book) {
                          it.order_company = o.order_comp_book.company;
                        }
                        new_itineraries.push(it);
                      }
                    );
                  }
                }
              }
            );
            
            this._sortedItinerary = new ItineraryDiaryObligationCollection(new_itineraries);
            console.log(this._sortedItinerary);
          }
        }
      )
    );
  }


  /*******************************************/
  /* General getters */
  /*******************************************/
  // alias constant
  get ItineraryType(): any { 
    return ItineraryType;
  }
  
  // get flag if user has valid access = true, expired access = false
  get validAccess(): boolean {
    if (IS_DEMO) return true;
    if (!this._layout.user || !this._layout.user.admittanceDateExtended) return false;
    return this._layout.user.admittanceDateExtended > this._TODAY;
  }

  get loadingObligations(): boolean {
    return this._obligationServ.loadingObligations2;
  }

  
  /*******************************************/
  /* Day managing */
  /*******************************************/
  get current_selected_text(): string {
    switch (true) {
      case this.isTodayVisible:
        return $localize`Dnes`;
      case this.isTomorrowVisible:
        return $localize`Zítra`;
      case this.isYesterdayVisible:
        return $localize`Včera`;
      default:
        return '';
    }
  }
  
  get isYesterdayVisible(): boolean {
    return this._visible === this._visibleYesterday;
  }

  get isTomorrowVisible(): boolean {
    return this._visible === this._visibleTomorrow;
  }

  get isTodayVisible(): boolean {
    return this._visible === this._visibleToday;
  }

  get selectedDayItinerary(): any {
    if (this._sortByHour) {
      if (this.isYesterdayVisible) {
        return this.sortedItinerary.byDay.yesterday;
      }
      else if (this.isTodayVisible) {
        return this.sortedItinerary.byDay.today;
      }
      else {
        return this.sortedItinerary.byDay.tomorrow;
      } 
    }
    else {
      if (this.isYesterdayVisible) {
        return this.sortedItinerary.byDayObligation.yesterday;
      }
      else if (this.isTodayVisible) {
        return this.sortedItinerary.byDayObligation.today;
      }
      else {
        return this.sortedItinerary.byDayObligation.tomorrow;
      } 
    }
  }

  moveToPast() {
    if (this.isTomorrowVisible) {
      this._visible = this._visibleToday;
    } 
    else if (this.isTodayVisible) {
      this._visible = this._visibleYesterday;
    }
    // this.hideMapDetail();
  }

  moveToFuture() {
    if (this.isYesterdayVisible) {
      this._visible = this._visibleToday;
    } 
    else if (this.isTodayVisible) {
      this._visible = this._visibleTomorrow;
    }
    // this.hideMapDetail();
  }

  isCurrentHour(hour: number): boolean {
    let now = new Date();
    return now.getHours() === hour;
  }


  /************************************************************/
  /* Methods for sending email of obligation */
  /************************************************************/
  @ViewChild('myEmailingChild', {static: false}) private _myEmailingChild: Ta1ObligationEmailingComponent;

  // method for customizing width of popover div
  widthPopoverOfFiles(obligation: Obligation): string {
    let len: number = obligation.files.length;
    if (len < 2) return '140px';
    else if (len < 3) return '280px';
    else if (len < 4) return '420px';
    else return '550px';
  }

  // download all thumbnails of files
  downloadThumbnails(obligation: Obligation): void {
    if (obligation.attachments) {
      obligation.attachments.forEach(
        f => {
          this._obligationServ.downloadThumbnail(f);
        }
      );
    }
  }
  
  // flag for detecting if file/attachment is currently downloading
  public get downloadingFile(): boolean {
    return this._obligationServ.downloadingAttachment;
  }

  // method for updating attributes of specified obligation
  downloadAttachment(attachment: Attachment): void {
    this._obligationServ.openAttachmentNewTab(attachment);
  }

  private _obligationToSend: Obligation = null;
  public get obligationToSend(): Obligation {
    return this._obligationToSend;
  }
  public set obligationToSend(value: Obligation) {
    this._obligationToSend = value;
    // init also attachments for emailing
    this.initAttachments();
    // reinit child component
    if (this._myEmailingChild) {
      this._myEmailingChild.reinitComponent();
    }
  }

  private _attachmentsToSend: Array<Attachment> = [];
  public get attachmentsToSend(): Array<Attachment> {
    return this._attachmentsToSend;
  }

  /* Thumbnails part */
  initAttachments(): void {
    this._attachmentsToSend = [];
    if (this._obligationToSend.attachments) {
      this._attachmentsToSend = this._obligationToSend.attachments;
    }

    this._attachmentsToSend.forEach(
      att => {
        this._obligationServ.downloadThumbnail(att);
      }
    );
  }

  // method for loading detail html page about email sent
  showEmailDetail(email_msg_id: string): void {
    this._obligationServ.getEmailShow(email_msg_id).subscribe(
      response => {
        if (response) {
          // let parser = new DOMParser();
          // let doc = parser.parseFromString(response, "text/html");
          // using this solution now: https://stackoverflow.com/a/10573430
          let newWindow = window.open();
          if (newWindow) {
            newWindow.document.write(response);
          }
        }
      }
    );
  }

  // output event binding method
  eventAfterEmailSent(event) {
    if (event) {
      // init if any email has not been sent yet
      if (!this._obligationToSend.email_headers) this._obligationToSend.email_headers = [];
      
      this._subscribed.push(
        this._obligationServ.getEmailHeader(event).subscribe(
          (header: Email) => {
            if (header) {
              header.email_msg_id = event;
              this._obligationToSend.email_headers.push(header);
            }
          }
        )
      );
    }
  }

  
  /************************************************************/
  /* Methods for creating service event without TM */
  /************************************************************/
  public itineraryToComplete: Itinerary = null;

  completeItinerary(): void {
    let type: string = '';
    let itinerary_key: number = null;
    let obligation_key: number = null;
    let accept_token: string = '';
    let order: Order = null;

    if (this.itineraryToComplete && this.itineraryToComplete.obligation) {
      itinerary_key = this.itineraryToComplete.itinerary_key;
      obligation_key = this.itineraryToComplete.obligation.obligation_key;
      if (this.itineraryToComplete.obligation.piece_shipment || this.itineraryToComplete.obligation.transshipment) {
        if (this.itineraryToComplete.type == 'L' || this.itineraryToComplete.warehouse_in_itinerary) {
          order = this.itineraryToComplete.obligation.orders.find(o => o.delivery_type == 'P');
          if (order) {
            accept_token = order.accept_token;
          }
        }
        else if (this.itineraryToComplete.type == 'U' || this.itineraryToComplete.warehouse_out_itinerary) {
          order = this.itineraryToComplete.obligation.orders.find(o => o.delivery_type == 'D');
          if (order) {
            accept_token = order.accept_token;
          }
        }
      }
      else if (this.itineraryToComplete.obligation.orders && this.itineraryToComplete.obligation.orders[0]) {
        order = this.itineraryToComplete.obligation.orders[0];
        accept_token = this.itineraryToComplete.obligation.orders[0].accept_token;
      }
    }

    // warehouse obligation -> create warehouse event
    if ([ItineraryType.WAREHOUSE, ItineraryType.WAREHOUSE_AUTO].includes(this.itineraryToComplete.type)) {
      // creating warehouse event + service event
      let wevent: WarehouseEvent = new WarehouseEvent();
      wevent.ware_pcs = this.itineraryToComplete.ware_pcs;
      wevent.ware_type = this.itineraryToComplete.ware_type;
      wevent.weight = this.itineraryToComplete.weight;
      wevent.order_number = this.itineraryToComplete.obligation.order_number_standard;
      wevent.pos_gps = this.itineraryToComplete.gps_coord.gps_coord;
      wevent.timeInput = this.itineraryToComplete.arrival_time_custom;
      wevent.car_key = null;

      if (this.itineraryToComplete.obligation && this.itineraryToComplete.obligation.orders) {
        if (this.itineraryToComplete.warehouse_in_itinerary) {
          let collection = this.itineraryToComplete.obligation.orders.find(o => o.delivery_type == 'P');
          if (collection) {
            // set order_key
            wevent.order_key = collection.order_key;
            // possibly update order status - should be last itinerary of order
            collection.status = OrderStatus.VYLOZENA;
            this._subscribed.push(
              this._orderServ.updateOrder(collection).subscribe()
            );
          }

          // set type - warehouse collection -> unloading
          wevent.type = ItineraryType.UNLOADING;

          // update obligation status
          let obligation: Obligation = this.itineraryToComplete.obligation;
          if (obligation && obligation.status == ObligationStatus.K_VYRIZENI || obligation.status == ObligationStatus.SKLAD_SVOZ) {
            obligation.status = ObligationStatus.SKLAD;
            this._subscribed.push(
              this._obligationServ.updateObligation(obligation).subscribe()
            );
          }
        }
        else if (this.itineraryToComplete.warehouse_out_itinerary) {
          let delivery = this.itineraryToComplete.obligation.orders.find(o => o.delivery_type == 'D');
          if (delivery) {
            // set order_key
            wevent.order_key = delivery.order_key;
            // possibly update order status - should be first itinerary of order
            delivery.status = OrderStatus.NALOZENA;
            this._subscribed.push(
              this._orderServ.updateOrder(delivery).subscribe()
            );
          }
          
          // set type - warehouse delivery -> loading
          wevent.type = ItineraryType.LOADING;

          // update obligation status
          let obligation: Obligation = this.itineraryToComplete.obligation;
          if (obligation && obligation.status == ObligationStatus.SKLAD || obligation.status == ObligationStatus.SKLAD_K_ROZVOZU) {
            obligation.status = ObligationStatus.SKLAD_ROZVOZ;
            this._subscribed.push(
              this._obligationServ.updateObligation(obligation).subscribe()
            );
          }
        }
      }

      this._subscribed.push(
        this._warehouseServ.createEvent(wevent).subscribe(
          response => {
            if (response) {
              // save warehouse event 
              if (this.itineraryToComplete.warehouse_in_itinerary) {
                this.itineraryToComplete.warehouse_in_event = wevent;
              }
              else if (this.itineraryToComplete.warehouse_out_itinerary) {
                this.itineraryToComplete.warehouse_out_event = wevent;
              }
            }
          }
        )
      );
    }
    else {
      // method used from ext2 logic of itinerary confirmation
      if (itinerary_key && obligation_key && accept_token) {
        let data: any = {
          time: DateTools.toIsoWithoutMilisec(new Date(this.itineraryToComplete.arrival_time_custom)),
          weight: this.itineraryToComplete.weight,
          length: this.itineraryToComplete.length,
          descr: this.itineraryToComplete.note,
          type: this.itineraryToComplete.type
          // containers_in: this.itineraryToComplete.this.it_containers_in,
          // containers_out: this.itineraryToComplete.this.it_containers_out,
        };

        this._subscribed.push(
          this._obligationServ.completeOrderItinerary(accept_token, obligation_key, itinerary_key, data).subscribe({
            next: (response) => {
              // NOCONTENT -> itinerary has been already completed
              if (response.status == 204) {
                let alert: string = (type == "L" ? $localize`Nakládka` : $localize`Vykládka`) 
                alert += $localize` byla již dokončena.`;
                this._notificationServ.alert(alert, 'success', 4000);
              }
              else {
                let alert: string = (type == "L" ? $localize`Nakládka` : $localize`Vykládka`) 
                alert += $localize` byla dokončena.`;
                this._notificationServ.alert(alert, 'success', 4000);
              }
            },
            error: (err) => {
              console.log(err);
              let alert: string = $localize`Nastala chyba při dokončení.`;
              this._notificationServ.alert(alert, 'success', 4000);
            }
          })
        );

        // possibly update order status - first and last itinerary of order
        if (order && order.itinerary && order.itinerary.length) {
          let current_itinerary: Itinerary = order.itinerary.find(
            it => it.itinerary_key == this.itineraryToComplete.itinerary_key
          );
          let current_itinerary_idx = order.itinerary.indexOf(current_itinerary);
          if (current_itinerary_idx == 0 || current_itinerary_idx == order.itinerary.length - 1) {
            if (current_itinerary_idx == 0) {
              order.status = OrderStatus.NALOZENA;
            }
            else if (current_itinerary_idx == order.itinerary.length - 1) {
              order.status = OrderStatus.VYLOZENA;
            }
              
            this._subscribed.push(
              this._orderServ.updateOrder(order).subscribe()
            );
          }
        }
      }
      
      // locally just update completed status - no need to reload obligations
      this.itineraryToComplete.completed = true;

      // this._subscribed.push(
      //   this._obligationServ.updateItinerary(
      //     this.itineraryToComplete.obligation, 
      //     this.itineraryToComplete
      //   ).subscribe(
      //     response => {
      //       if (response) {
      //         let type: string = this.itineraryToComplete.type;
      //         if (type == ItineraryType.LOADING || type == ItineraryType.UNLOADING) {
      //           let alert: string = type == ItineraryType.LOADING ? 'Nakládka' : 'Vykládka';
      //           alert += '  byla dokončena';
      //           this._notificationServ.alert(alert, 'success', 4000);
      //         }
      //       }
      //     }
      //   )
      // );
    }

    // set status "K FAKTURACI"
    let obligation: Obligation = this.itineraryToComplete.obligation;
    if (obligation && this.itineraryToComplete.position == obligation.itinerary.length) {
      // update obligation status
      if (obligation && obligation.status == ObligationStatus.ZAHAJENA || obligation.status == ObligationStatus.SKLAD_ROZVOZ) {
        obligation.status = ObligationStatus.K_FAKTURACI;
        this._subscribed.push(
          this._obligationServ.updateObligation(obligation).subscribe()
        );
      }
    }
  
    (<any>$('#completeItineraryModal')).modal('hide');
  }

  
  /******************************************************/
  /* Detail opening */
  /******************************************************/
  openObligationDetail(itinerary: Itinerary): void {
    // toggle
    if (itinerary.diary_detail) {
      itinerary.diary_detail = false;
      return;
    }

    if (this.selectedDayItinerary && this.selectedDayItinerary.length) {
      this.selectedDayItinerary.forEach(
        element => {
          if (element.itineraries) {
            element.itineraries.forEach(
              it => {
                it.diary_detail = false;
              }
            );
          }
        }
      );
    }
    // set active detail
    itinerary.diary_detail = true;
  }
  
  
  /*************************************************/
  /* Order completion */
  /*************************************************/
  public orderToCompletedEmail: Order = null;
  public itineraryToCompletedEmail: Itinerary = null;
  setItineraryToCompleted(it: Itinerary): void {
    this.itineraryToCompletedEmail = it;

    if (it && it.obligation && it.obligation.orders && it.obligation.orders[0]) {
      it.obligation.orders[0].obligations = [ it.obligation ];
      this.orderToCompletedEmail = it.obligation.orders[0];
      (<any>$('#sendOrderCompletedModal')).modal('show');
    }
  }
  

  /******************************************************/
  /* Routing */
  /******************************************************/
  openOrder(order_key: number) {
    let queryParams: any = {
      order_key: order_key, 
      reloadPossible: true
    }
    let url = this._router.serializeUrl(
      this._router.createUrlTree([{outlets: {left: 'ta1-order/company_order'}}], {queryParams: queryParams})
    );
    this._router.navigateByUrl(url);
  }
  
  openObligation(obligation_key: number) {
    let queryParams: any = {
      obligation_key: obligation_key, 
      reloadPossible: true
    }
    let url = this._router.serializeUrl(
      this._router.createUrlTree([{outlets: {left: 'ta1-obligation/company_obligation'}}], {queryParams: queryParams})
    );
    this._router.navigateByUrl(url);
    // window.open('#' + url, '_blank');
  }
  
  openSubsNewTab() {
    let url: any = null;
    let queryParams: any = {
      reloadPossible: true
    }
    url = this._router.serializeUrl(
      this._router.createUrlTree([
        {outlets: {left: 'general/subscription', right: 'itinerary-dairy'}}], 
        {queryParams: queryParams}
      )
    );
    this._router.navigateByUrl(url);
  }
}
