<!-- <div *ngIf="onTheWay" class="row">
  <div class="col-sm-6">
    <div class="form-group">
      <label>Maximální vzdálenost</label>
      <input class="form-control" type="number" name="serchrangeStartOnWay"/>
      <input class="form-control" type="number" name="serchrangeEndOnWay"/>
    </div>
  </div>
</div>

<div *ngIf="backHome" class="row">
  <div class="col-sm-6">
    <div class="form-group">
      <label>Maximální vzdálenost xxxxxxxxxxxx</label>
      <input class="form-control" type="number" name="serchrangeStartHome"/>
      <input class="form-control" type="number" name="serchrangeEndHome"/>
    </div>
  </div>
</div> -->

<div *ngIf="response?.payload">
  <table class="table table-striped">
    <thead>
      <tr>
        <th>Datum</th>
        <th>Z</th>
        <th>Přejezd</th>
        <th>Do</th>
        <th>Přejezd</th>
        <th>Typ návěsu</th>
        <th>Délka</th>
        <th>Hmotnost</th>
        <th>Odkaz</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of response.payload">
        <!-- <td>{{item.loadingPlaces[0].earliestLoadingDateObject}}</td> -->
        <td>{{item.earliestLoadingDateObject ? (item.earliestLoadingDateObject | date: 'dd.MM.yyyy') : ''}}</td>
        <td>{{item.loadingPlaces[0].address.city}}</td>
        <td>{{this.computeTransit(item)}}Km</td>
        <td>{{item.loadingPlaces[1].address.city}}</td>
        <td>{{this.computeTransit2(item)}}Km</td>
        <td>{{item.vehicleProperties.body[0]}}</td>
        <td>{{item.length_m}}m</td>
        <td>{{item.weight_t}}t</td>
        <td>
          <a [href]="item.deeplink" target="_blank">
            <img src="assets/icons-new/Timocom_mini.svg"  height="18px">
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</div>