import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { HttpClientService } from "./http-client.service";
import { AuthenticationService } from "./authentication.service";
import { NotificationService } from "./notification-service";
import { IS_DEMO, ServiceConfiguration } from "../config";
import { Invoice } from "../model/invoice.object";
import { Email } from "../model/email.object";
import { InvoiceItem } from "../model/invoice-item.object";
import { InvoiceReminder } from "../model/invoice-reminder.object";
import { Order } from "../model/order.object";
import { Obligation } from "../model/obligation.object";
import { InvoiceTools } from "../tools/InvoiceTools";
import { connections } from "../connect";
import { Attachment } from "../model/attachment.object";

@Injectable({
  providedIn: 'root',
})
export class InvoiceService {

  private _invoices: BehaviorSubject<Array<Invoice>> = new BehaviorSubject([]);
  private _invoicesCache: Array<Invoice> = [];
  private _loadingInvoices: boolean = false;
  private _loadingNextPage: boolean = false;
  private _loadingInvoicesRequest: boolean = false;
  
  private _invoiceVarSymbols: BehaviorSubject<Array<Invoice>> = new BehaviorSubject([]);
  private _invoiceVarSymbolsCache: Array<Invoice> = [];
  private _loadingInvoiceVarSymbols: boolean = false;
  
  private _invoice: BehaviorSubject<Invoice> = new BehaviorSubject(null);
  private _invoiceCache: Invoice = null;
  private _loadingInvoice: boolean = false;

  private _filters: any = {};
  private _sorts: any = {};
  private _page: number = 0;

  private _filterNames: Array<string> = [
    'issued_from',
    'issued_to',
    'fullfilment_from',
    'fullfilment_to',
    'maturity_from',
    'maturity_to',
    'year',
    'series',
    'type',
    'number',
    'overdue',
    'overdue_date',
    'company',
    'company_key',
    'updated',
    'currency',
    'var_sym',
    'var_sym_exact',
    'price',
    'favourite',
    'mode' // mode = light
  ];

  private _sortNames: Array<string> = [
    'number',
    'var_sym',
    'series',
    'type',
    'company',
    'issued',
    'maturity',
    'fullfilment'
  ];

  constructor(
    private _http: HttpClientService,
    private _authService: AuthenticationService,
    private _notificationService: NotificationService
  ) {
  }

  public get loadingInvoices(): boolean {
    return this._loadingInvoices;
  }
  
  public get loadingNextPage(): boolean {
    return this._loadingNextPage;
  }

  public get loadingInvoicesRequest(): boolean {
    return this._loadingInvoicesRequest;
  }

  public get loadingInvoice(): boolean {
    return this._loadingInvoice;
  }
  
  public get loadingInvoiceVarSymbols(): boolean {
    return this._loadingInvoiceVarSymbols;
  }

  public clearInvoicesCache(): void {
    this._invoicesCache = [];
  }

  public clearInvoiceCache(): void {
    this._invoiceCache = null;
  }

  public invoicesFilteredTotalRecords: number = 0;
  public invoicesFilteredTotalPages: number = 0;

  // method for getting invoices with given filter object
  getInvoicesFiltered(filterObj: any, sortObj: any, page: number = 0, size: number = 50): Observable<Array<Invoice>> {
    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      // init attributtes
      this._filters = filterObj;
      this._sorts = sortObj;
      this._page = page;
      // default url without any filter
      let url: string = ServiceConfiguration.invoices.api + '?page=' + this._page + '&size=' + size;
      // any filters were defined
      if (filterObj) url += this.filterUrl(filterObj);
      // any sort parameters were defined
      if (sortObj) url += this.sortUrl(sortObj);

      // console.log(url);
      // set loading flags
      this._loadingInvoices = true;
      // if (this._page > 0) {
      //   this._loadingNextPage = true;
      // }

      // handling full response (with headers) not just body as usual
      let httpOptions = {
        observe: 'response' as 'body'
      };

      this._http.get(url, httpOptions).subscribe(
        response => {
          if (response && response.body) {
            if (response.headers) {
              if (response.headers.get('X-TM-API-Total-Records')) {
                this.invoicesFilteredTotalRecords = response.headers.get('X-TM-API-Total-Records');
              }
              if (response.headers.get('X-TM-API-Total-Pages')) {
                this.invoicesFilteredTotalPages = response.headers.get('X-TM-API-Total-Pages');
              }
            } 
            this._invoicesCache = InvoiceTools.buildInvoicesFromData(response.body);
            this._invoices.next(this._invoicesCache);
            this._loadingInvoices = false;
            this._loadingNextPage = false;
          }
        },
        error => {
          // handle error
          console.log(error);
          this._invoicesCache = [];
          this._invoices.next(this._invoicesCache);
          this._loadingInvoices = false;
          this._loadingNextPage = false;
        }
      );
    }

    return this._invoices.asObservable();
  }

  // method for getting invoices with given filter object
  // same as above but no paging and not using observable attribute
  getInvoicesFilteredRequest(filterObj: any, sortObj: any, page: number = 0, size: number = 50): Observable<Array<Invoice>> {
    let result: BehaviorSubject<Array<Invoice>> = new BehaviorSubject(null);

    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      // default url without any filter
      let url: string = ServiceConfiguration.invoices.api + '?page=' + page + '&size=' + size;
      // any filters were defined
      if (filterObj) url += this.filterUrl(filterObj);
      // any sort parameters were defined
      if (sortObj) url += this.sortUrl(sortObj);

      // set loading flag
      this._loadingInvoicesRequest = true;

      this._http.get(url).subscribe(
        response => {
          let invoices = InvoiceTools.buildInvoicesFromData(response);
          result.next(invoices);
          this._loadingInvoicesRequest = false;
        },
        error => {
          // handle error
          console.log(error);
          this._loadingInvoicesRequest = false;
        }
      );
    }

    return result.asObservable();
  }

  // method for getting invoices with given filter object
  // same as above but for further processing in component
  getInvoicesFilteredRequestToComponent(filterObj: any, sortObj: any, page: number = 0, size: number = 50): Observable<Array<any>> {
    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      // default url without any filter
      let url: string = ServiceConfiguration.invoices.api + '?page=' + page + '&size=' + size;
      // any filters were defined
      if (filterObj) url += this.filterUrl(filterObj);
      // any sort parameters were defined
      if (sortObj) url += this.sortUrl(sortObj);

      return this._http.get(url);
    }
  }

  // method for creating url string for filtering
  filterUrl(filterObj: any) {
    let result = '';
    let filterKeys: Array<string> = Object.keys(filterObj);
    filterKeys.forEach(
      key => {
        // check possible filters for obligations
        if (this._filterNames.includes(key)) {
          result += '&' + key + '=' + filterObj[key];
        }
      }
    );
    return result;
  }
  
  // method for creating url string for sorting
  sortUrl(sortObj: any) {
    let result = '';
    let sortKeys: Array<string> = Object.keys(sortObj);
    sortKeys.forEach(
      key => {
        // check possible sorts for obligations (should be always only one sort param)
        if (this._sortNames.includes(key)) {
          result += '&sort=' + key + ',' + sortObj[key];
        }
      }
    );
    return result;
  }



  // method for getting the invoice with given key
  getInvoice(invoice_key: number): Observable<Invoice> {
    this._loadingInvoice = true;
    let url: string = ServiceConfiguration.invoices.apiInvoiceKey.replace('%INVOICE_KEY%', invoice_key.toString());

    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      this._http.get(url).subscribe(
        response => {
          if (response) {
            this._invoiceCache = InvoiceTools.buildInvoice(response);
            this._invoice.next(this._invoiceCache);
          }
          this._loadingInvoice = false;
        },
        error => {
          // handle error
          console.log(error);
          this._loadingInvoice = false;
        }
      );
    }

    return this._invoice.asObservable();
  }
  
  getInvoiceRequest(invoice_key: number): Observable<Invoice> {
    let result: BehaviorSubject<Invoice> = new BehaviorSubject(null);

    this._loadingInvoice = true;
    let url: string = ServiceConfiguration.invoices.apiInvoiceKey;
    url = url.replace('%INVOICE_KEY%', invoice_key.toString());

    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      this._http.get(url).subscribe(
        response => {
          if (response) {
            let invoice: Invoice = InvoiceTools.buildInvoice(response);
            result.next(invoice);
          }
          this._loadingInvoice = false;
        },
        error => {
          // handle error
          console.log(error);
          this._loadingInvoice = false;
        }
      );
    }

    return result.asObservable();
  }

  // method for getting invoices with given number(var. symbol)
  getInvoiceVarSymbols(var_sym: string): Observable<Array<Invoice>> {
    this._loadingInvoiceVarSymbols = true;
    if (var_sym && (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated()))) {
      this._http.get(ServiceConfiguration.invoices.api + '?var_sym=' + var_sym).subscribe(
        response => {
          this._invoiceVarSymbolsCache = InvoiceTools.buildInvoicesFromData(response);
          // slice number of obligations to max constant value 5
          if (this._invoiceVarSymbolsCache.length > 5) {
            this._invoiceVarSymbolsCache = this._invoiceVarSymbolsCache.slice(0, 5);
          }
          this._invoiceVarSymbols.next(this._invoiceVarSymbolsCache);
          this._loadingInvoiceVarSymbols = false;
        },
        error => {
          // handle error
          console.log(error);
          this._invoiceVarSymbolsCache = [];
          this._invoiceVarSymbols.next(this._invoiceVarSymbolsCache);
          this._loadingInvoiceVarSymbols = false;
        }
      );
    }

    return this._invoiceVarSymbols.asObservable();
  }

  // method for getting invoices with given number(var. symbol)
  getInvoiceVarSymbolsRequest(var_sym: string): Observable<Array<Invoice>> {
    let result: BehaviorSubject<Array<Invoice>> = new BehaviorSubject([]);

    this._loadingInvoiceVarSymbols = true;
    if (var_sym && (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated()))) {
      this._http.get(ServiceConfiguration.invoices.api + '?var_sym=' + var_sym).subscribe(
        response => {
          let invoices = InvoiceTools.buildInvoicesFromData(response);
          // slice number of obligations to max constant value 5
          if (invoices.length > 5) {
            invoices = invoices.slice(0, 5);
          }
          result.next(invoices);
          this._loadingInvoiceVarSymbols = false;
        },
        error => {
          // handle error
          console.log(error);
          result.next([]);
          this._loadingInvoiceVarSymbols = false;
        }
      );
    }

    return result.asObservable();
  }

  // method for getting invoice with given number(var. symbol) - using Subject instance instead of attribute
  getInvoiceVarSymbolNoCache(var_sym: string): Observable<Array<Invoice>> {
    // this._loadingInvoiceVarSymbols = true;
    let invoiceSubject: BehaviorSubject<Array<Invoice>> = new BehaviorSubject([]);

    if (var_sym && (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated()))) {
      this._http.get(ServiceConfiguration.invoices.api + '?var_sym_exact=' + var_sym).subscribe(
        response => {
          let arr: Array<Invoice> = InvoiceTools.buildInvoicesFromData(response);
          invoiceSubject.next(arr);
        },
        error => {
          // handle error
          console.log(error);
        }
      );
    }

    return invoiceSubject.asObservable();
  }

  // method for updating invoice properties
  updateInvoice(invoice: Invoice): Observable<Invoice> {
    let updateInvoice: BehaviorSubject<Invoice> = new BehaviorSubject(null);

    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      let url: string = ServiceConfiguration.invoices.apiInvoiceKey;
      url = url.replace('%INVOICE_KEY%', invoice.invoice_key.toString());

      let data: any = invoice.apiObject;

      // CUSTOM FIX OF USER ERRORS INVOICING
      // data.number = 173;

      // put request
      this._http.put(url, data).subscribe(
        response => {
          // alert
          let alertSuccess: string = $localize`Parametry faktury %Invoice byly úspěšně upraveny.`;
          alertSuccess = alertSuccess.replace('%Invoice', invoice.invoiceNumberFormatted);
          this._notificationService.alert(alertSuccess, 'success', 4000);
          
          // observable next
          let updated = InvoiceTools.buildInvoice(response);
          updateInvoice.next(updated);
        },
        error => {
          // handle error
          console.log(error);
          // alert
          let alertError: string = $localize`Chyba při úpravě parametrů faktury %Invoice.`;
          alertError = alertError.replace('%Invoice', invoice.invoiceNumberFormatted);
          this._notificationService.alert(alertError, 'error', 4000);
        }
      );
    }

    return updateInvoice.asObservable();
  }

  // method for creating new invoice
  createInvoice(invoice: Invoice): Observable<Invoice> {
    let newInvoice: BehaviorSubject<Invoice> = new BehaviorSubject(null);

    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      console.log(invoice.apiObject);
      // post data
      this._http.post(ServiceConfiguration.invoices.api, invoice.apiObject).subscribe(
        response => {
          if (response && response.invoice_key) {
            // console.log(response);
            let created = InvoiceTools.buildInvoice(response);
            newInvoice.next(created);
  
            // success alert
            let alertSuccess: string = $localize`Faktura %Invoice byla úspěšně vytvořena.`;
            alertSuccess = alertSuccess.replace('%Invoice', created.invoiceNumberFormatted);
            this._notificationService.alert(alertSuccess, 'success', 4000);
          }
          else {
            // should not happen
            console.log(response);
            // error alert
            let alertError: string = $localize`Chyba při vytváření faktury.`;
            this._notificationService.alert(alertError, 'error', 4000);
          }
        },
        error => {
          console.log(error);
          // error alert
          let alertError: string = $localize`Chyba při vytváření faktury.`;
          this._notificationService.alert(alertError, 'error', 4000);
        }
      );
    }

    return newInvoice.asObservable();
  }

  // method for deleting invoice
  deleteInvoice(invoice: Invoice): void {
    if (invoice && (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated()))) {
      let url: string = ServiceConfiguration.invoices.apiInvoiceKey.replace('%INVOICE_KEY%', invoice.invoice_key.toString());
      // delete request
      this._http.delete(url).subscribe(
        response => {
          // OK <=> 204 no content
          let alertSuccess: string = $localize`Faktura %Invoice byla úspěšně odstraněna.`;
          alertSuccess = alertSuccess.replace('%Invoice', invoice.invoiceNumberFormatted);
          this._notificationService.alert(alertSuccess, 'success', 4000);
          
          // new get request for current filters of obligations and subscribe
          this.getInvoicesFiltered(this._filters, this._sorts, 0).subscribe();
        },
        error => {
          // handle error
          console.log(error);
          let alertError: string = $localize`Chyba při mazání faktury %Invoice.`;
          alertError = alertError.replace('%Invoice', invoice.invoiceNumberFormatted);
          this._notificationService.alert(alertError, 'error', 4000);
        }
      );
    }
  }


  // method for creating association between invoice and obligation
  associateInvoiceWithObligation(invoice: Invoice, obligation: Obligation): Observable<any> {
    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      let url: string = ServiceConfiguration.invoices.apiInvoiceObligation.replace('%INVOICE_KEY%', invoice.invoice_key.toString());
      let data: any = {
        invoice_key: invoice.invoice_key,
        obligation_key: obligation.obligation_key
      }

      // post data
      return this._http.post(url, data); 

      // return this._http.post(url, data).subscribe(
      //   response => {
      //     subject.next({'success': true});
      //   },
      //   error => {
      //     console.log(error);
      //     // error alert
      //     let alertError: string = 'create-invoice-obligation-error';
      //     alertError = alertError.replace('%Invoice', invoice.invoiceNumberFormatted);
      //     alertError = alertError.replace('%Oblig', obligation.obligationNumberFormatted);
      //     this._notificationService.alert(alertError, 'error', 4000);
      //   }
      // );
    }
  }

  // method for creating association between order and invoice
  associateOrderWithInvoice(order: Order, invoice: Invoice): Observable<any> {
    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      let url: string = ServiceConfiguration.orders.apiOrderInvoice;
      url = url.replace('%ORDER_KEY%', order.order_key.toString());

      let data: any = { order_key: order.order_key, invoice_key: invoice.invoice_key };
      // post data
      return this._http.post(url, data);

      // .subscribe(
      //   response => {
      //     // console.log(response);
      //   },
      //   error => {
      //     console.log(error);
      //     // error alert
      //     let alertError: string = 'create-order-invoice-error';
      //     alertError = alertError.replace('%ORDER%', order.orderNumberFormatted);
      //     alertError = alertError.replace('%INVOICE%', invoice.invoiceNumberFormatted);
      //     this._notificationService.alert(alertError, 'error', 4000);
      //   }
      // );
    }
  }

  // method for deleting association between invoice and obligation
  deleteAssociationInvoiceWithObligation(invoice: Invoice, obligation: Obligation): Observable<any> {
    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      let url: string = ServiceConfiguration.invoices.apiInvoiceObligationDel;
      url = url.replace('%INVOICE_KEY%', invoice.invoice_key.toString());
      url = url.replace('%OBLIGATION_KEY%', obligation.obligation_key.toString());

      // delete
      return this._http.delete(url);
      // .subscribe(
      //   response => {
      //     // console.log(response);
      //   },
      //   error => {
      //     console.log(error);
      //     // error alert
      //     let alertError: string = 'delete-invoice-obligation-error';
      //     alertError = alertError.replace('%Invoice', invoice.invoiceNumberFormatted);
      //     alertError = alertError.replace('%Oblig', obligation.obligationNumberFormatted);
      //     this._notificationService.alert(alertError, 'error', 4000);
      //   }
      // );
    }
  }
  
  // method for deleting association between order and invoice
  deleteAssociationInvoiceWithOrder(invoice: Invoice, order: Order): Observable<any> {
    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      let url: string = ServiceConfiguration.orders.apiOrderInvoiceDel;
      url = url.replace('%ORDER_KEY%', order.order_key.toString());
      url = url.replace('%INVOICE_KEY%', invoice.invoice_key.toString());

      // delete
      return this._http.delete(url);
      // .subscribe(
      //   response => {
      //     // console.log(response);
      //   },
      //   error => {
      //     console.log(error);
      //     // error alert
      //     let alertError: string = 'delete-order-invoice-error';
      //     alertError = alertError.replace('%ORDER%', order.orderNumberFormatted);
      //     alertError = alertError.replace('%INVOICE%', invoice.invoiceNumberFormatted);
      //     this._notificationService.alert(alertError, 'error', 4000);
      //   }
      // );
    }
  }


  
  /**********************************************************************************/
  /* Invoice items service methods */
  /**********************************************************************************/
  // method for creating new invoice item
  createInvoiceItem(invoice:Invoice, item: InvoiceItem): Observable<InvoiceItem> {
    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      let url: string = ServiceConfiguration.invoices.apiInvoiceItems.replace('%INVOICE_KEY%', invoice.invoice_key.toString());
      // post data
      return this._http.post(url, item.apiObject);

      // this._http.post(url, item.apiObject).subscribe(
      //   response => {
      //     let created = InvoiceTools.buildInvoiceItem(response);
      //     newItem.next(created);
      //   },
      //   error => {
      //     console.log(error);
      //     // error alert
      //     let alertError: string = 'create-invoice-item-error';
      //     this._notificationService.alert(alertError, 'error', 4000);
      //     newItem.next(undefined);
      //   }
      // );
    }
  }
  
  // method for updating invoice item properties
  updateInvoiceItem(invoice: Invoice, item: InvoiceItem): Observable<InvoiceItem> {
    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      let url: string = ServiceConfiguration.invoices.apiInvoiceItemsKey;
      url = url.replace('%INVOICE_KEY%', invoice.invoice_key.toString());
      url = url.replace('%ITEM_KEY%', item.item_key.toString());
      // put data
      return this._http.put(url, item.apiObject);

    //   this._http.put(url, item.apiObject).subscribe(
    //     response => {
    //       // observable next
    //       let updated = InvoiceTools.buildInvoiceItem(response);
    //       updateItem.next(updated);
    //     },
    //     error => {
    //       // handle error
    //       console.log(error);
    //       // alert
    //       let alertError: string = 'update-invoice-item-error';
    //       this._notificationService.alert(alertError, 'error', 4000);
    //       updateItem.next(undefined);
    //     }
    //   );
    }
  }

  // method for deleting invoice item
  deleteInvoiceItem(invoice: Invoice, item: InvoiceItem): Observable<any> {
    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      let url: string = ServiceConfiguration.invoices.apiInvoiceItemsKey;
      url = url.replace('%INVOICE_KEY%', invoice.invoice_key.toString());
      url = url.replace('%ITEM_KEY%', item.item_key.toString());
      // delete request
      return this._http.delete(url);

      // this._http.delete(url).subscribe(
      //   response => {
      //     //console.log(response);
      //     deletedSubject.next({'success': true});
      //   },
      //   error => {
      //     console.log(error);
      //     // error alert
      //     let alertError: string = 'delete-invoice-item-error';
      //     this._notificationService.alert(alertError, 'error', 4000);
      //     deletedSubject.next(undefined);
      //   }
      // );
    }
  }

  /**********************************************************************************/
  /* Preview PDF methods */
  /**********************************************************************************/
  // method for downloading/opening preview of invoice pdf
  getPreview(invoice: Invoice, lng: string): Observable<Blob> {
    // let url: string = ServiceConfiguration.invoices.apiInvoicePreview.replace('%INVOICE_KEY%', invoice.invoice_key.toString());
    // return this._http.post(url, {language: lng.toUpperCase().trim()}, {responseType: 'blob'});

    let url: string = ServiceConfiguration.invoices.apiInvoicePreview;
    url = url.replace('%INVOICE_KEY%', invoice.invoice_key.toString());
    url = url.replace('%LANG%', lng.toUpperCase().trim());

    return this._http.get(url, {responseType: 'blob'});
  }
  
  
  /**********************************************************************************/
  /* Print PDF methods */
  /**********************************************************************************/
  private _openingPrintedPDF: boolean = false;
  public get openingPrintedPDF(): boolean {
    return this._openingPrintedPDF;
  }

  // method for print invoice pdf to server storage
  printApiPDF(invoice: Invoice, lng: string, filesArr: Array<string>): Observable<string> {    
    let email_id: BehaviorSubject<string> = new BehaviorSubject(null);

    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      let url: string = ServiceConfiguration.invoices.apiInvoicePrint;
      url = url.replace('%INVOICE_KEY%', invoice.invoice_key.toString());

      this._http.post(url, {language: lng.toUpperCase().trim(), files: filesArr }).subscribe(
        response => {
          console.log(response);
          email_id.next(response);
        }, 
        error => {
          console.log(error);
          this._notificationService.alert(
            $localize`Chyba při tisku PDF faktury ` + invoice.invoiceNumberFormatted, 'error', 3500
          );
        }
      );
    }

    return email_id.asObservable();
  }

  // method for downloading/opening pdf from server storage
  getPrintedPDF(email_id: string): Observable<Blob> {
    let url: string = ServiceConfiguration.invoices.apiInvoicePrintOpen.replace('%EMAIL_ID%', email_id);
    return this._http.get(url, {responseType: 'blob'});
  }

  // method for downloading and opening printed PDF
  openPrintedPDF(email_id: string, invoice: Invoice): void {
    if (!email_id) return;

    this._openingPrintedPDF = true;

    this.getPrintedPDF(email_id).subscribe(
      response => {
        if (response) {
          let newBlob: any = new Blob([response], { type: (response.type ? response.type : 'application/pdf') });
          // IE doesn't allow using a blob object directly as link href
          // instead it is necessary to use msSaveOrOpenBlob
          if (window.navigator && (navigator as any).msSaveOrOpenBlob) {
            (navigator as any).msSaveOrOpenBlob(newBlob);
            return;
          }
      
          // For other browsers: 
          // Create a link pointing to the ObjectURL containing the blob.
          const data = window.URL.createObjectURL(newBlob);
      
          // var link = document.createElement('a');
          // link.href = data;
          // link.download = attachment.name;
          // // this is necessary as link.click() does not work on the latest firefox
          // link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      
          // set flag after success loading
          this._openingPrintedPDF = false;

          // open new tab (does not work when ad block is activated)
          let w = window.open(data, '_blank');
          if (w) {
            setTimeout(() => w.document.title = invoice.invoiceNumberFormatted, 500);
          }
      
          // setTimeout(function () {
          //     // For Firefox it is necessary to delay revoking the ObjectURL
          //     window.URL.revokeObjectURL(data);
          //     link.remove();
          // }, 100);
        }
        else {
          console.log(response);
        }
      },
      error => {
        console.log(error);
        // set flag after error loading
        this._openingPrintedPDF = false;
        this._notificationService.alert('Chyba při stahování PDF faktury - ' + invoice.invoiceNumberFormatted, 'error', 3500);
      }
    );
  }


  /**********************************************************************************/
  /* Invoice emailing service methods */
  /**********************************************************************************/
  /* Methods for email invoicing */
  private _sendingEmail: boolean = false;
  public get sendingEmail(): boolean {
    return this._sendingEmail;
  }

  private _sendCompleted: boolean = false;
  public set sendCompleted(value: boolean) {
    this._sendCompleted = value;
  }
  public get sendCompleted(): boolean {
    return this._sendCompleted;
  }

  private _sendOK: boolean = false;
  public set sendOK(value: boolean) {
    this._sendOK = value;
  }
  public get sendOK(): boolean {
    return this._sendOK;
  }

  // send email
  sendEmail(emailObj: any, combineAttachments: boolean = true, combineAll: boolean = false, combineName: string = 'documents.pdf'): Observable<any> {
    let email: BehaviorSubject<any> = new BehaviorSubject(null);
    // console.log(emailObj);

    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      this._sendingEmail = true;
      
      // post data
      // this._http.post(ServiceConfiguration.emailing.invoice, emailObj).subscribe(
      let url: string = ServiceConfiguration.invoices.apiInvoiceEmail.replace('%INVOICE_KEY%', emailObj.invoice_key);
      if (combineAttachments) {
        url += '?combine=attachments';
        // add combine name - if different of defaults
        if (combineName && combineName != 'documents.pdf') {
          url += '&combine_name=' + combineName;
        }
      }
      else if (combineAll) {
        url += '?combine=all';
        // add combine name - if different of defaults
        if (combineName && combineName != 'documents.pdf') {
          url += '&combine_name=' + combineName;
        }
      }

      this._http.post(url, emailObj).subscribe(
        response => {
          console.log(response);
          this._sendingEmail = false;
          this._sendCompleted = true;
          this._sendOK = true;
          // success alert
          let alertSuccess: string = $localize`Email byl úspěšně odeslán.`;
          this._notificationService.alert(alertSuccess, 'success', 3500);
          email.next(response);
        },
        error => {
          console.log(error);
          this._sendingEmail = false;
          this._sendCompleted = true;
          if (error.status != 502) {
            // error alert
            let alertError: string = $localize`Nastala chyba při odesílání emailu.`;
            this._notificationService.alert(alertError, 'error', 3500);
          }
        }
      );
    }
    
    return email.asObservable();
  }

  // method for email header
  getEmailHeader(message_id: string): Observable<Email> {
    let invoiceEmailHeader: BehaviorSubject<Email> = new BehaviorSubject(null);

    if (message_id && (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated()))) {
      let url: string = ServiceConfiguration.emailing.header.replace('%MESSAGE_ID', message_id);
      this._http.get(url).subscribe(
        response => {
          let header: Email = InvoiceTools.buildInvoiceEmail(response);
          invoiceEmailHeader.next(header);
        },
        error => {
          // handle error
          console.log(error);
        }
      );
    }

    return invoiceEmailHeader.asObservable();
  }

  // method for loading detail html page about email sent
  getEmailShow(message_id: string): Observable<any> {
    let showHtml: BehaviorSubject<any> = new BehaviorSubject(null);

    if (message_id && (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated()))) {
      let url: string = ServiceConfiguration.emailing.show.replace('%MESSAGE_ID', message_id);
      // let headers: HttpHeaders = new HttpHeaders();

      this._http.get(url, {responseType: "text"}).subscribe(
        response => {
          // console.log(response);
          showHtml.next(response);
        },
        error => {
          // handle error
          console.log(error);
        }
      );
    }

    return showHtml.asObservable();
  }


  
  /**********************************************************************************/
  /* Invoice reminders service methods */
  /**********************************************************************************/
  private _sendingReminders: boolean = false;
  public get sendingReminders(): boolean {
    return this._sendingReminders;
  }

  /* Methods for sending invoice reminders */
  sendReminderForInvoices(invoices: Array<Invoice>): Observable<any> {
    let result: BehaviorSubject<any> = new BehaviorSubject(null);

    if (invoices && invoices.length && (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated()))) {
      let url: string = ServiceConfiguration.invoices.apiBulkReminders + '?type=e&invoices=';
      if (invoices.length == 1) {
        url += invoices[0].invoice_key;
      }
      else {
        url += invoices[0].invoice_key;
        invoices.forEach(
          (invoice, index) => {
            if (index >= 1) {
              url += ',' + invoice.invoice_key;
            }
          }
        );
      }

      this._sendingReminders = true;

      this._http.get(url).subscribe(
        response => {
          console.log(response);
          if (invoices.length > 1) {
            this._notificationService.alert(
              $localize`Upomínky byly úspěšně odeslány.`, 'success', 3500
            );
          }
          else {
            this._notificationService.alert(
              $localize`Upomínka pro fakturu ` + invoices[0].invoiceNumberFormatted + $localize` byla úspěšně odeslána`,
              'success', 3500
            );
          }
          this._sendingReminders = false;
          result.next(response);
        },
        error => {
          // handle error
          console.log(error);
          if (invoices.length > 1) {
            this._notificationService.alert($localize`Chyba při odesílání upomínek.`, 'error', 3500);
          }
          else {
            this._notificationService.alert(
              $localize`Upomínku pro fakturu ` + invoices[0].invoiceNumberFormatted + $localize` se nepodařilo odeslat.`,
              'error', 3500
            );
          }
          this._sendingReminders = false;
        }
      );
    }

    return result.asObservable();
  }

  
  // method for deleting invoice
  deleteReminder(invoice: Invoice, reminder: InvoiceReminder): void {
    if (invoice && (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated()))) {
      let url: string = ServiceConfiguration.invoices.apiRemindersDelete;
      url = url.replace('%INVOICE_KEY%', invoice.invoice_key.toString());
      url = url.replace('%NUMBER%', reminder.number.toString());

      // delete request
      this._http.delete(url).subscribe(
        response => {
        },
        error => {
          // handle error
          console.log(error);
        }
      );
    }
  }

  
  /**********************************************************************************/
  /* Invoice attachmentss */
  /**********************************************************************************/
  // method for adding attachment to invoice
  addAttachment(invoice_key: number, file: File): Observable<any> {
    const KEY: string = '%INVOICE_KEY%';

    let formData = new FormData();
    formData.append('file', file);

    let url: string = ServiceConfiguration.invoices.apiAttachment.replace(KEY, invoice_key.toString());
    return this._http.post(url, formData);
  }

  // method for downloading/opening attachment of invoice
  getAttachment(attachment_link: string): Observable<Blob> {
    // "https://app2.truckmanager.eu/nettedev/api/v1/files/company/52135-Objednavka1.pdf?name=Objednavka1.pdf"
    return this._http.get(connections.apiUrl + encodeURI(attachment_link), {responseType: 'blob'});
  }

  // method for deleting attachment from invoice
  deleteAttachment(invoice: Invoice, attachment: Attachment): void {
    if (!invoice || !attachment) return;
    
    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      let url: string = ServiceConfiguration.invoices.apiAttachment;
      url = url.replace('%INVOICE_KEY%', invoice.invoice_key.toString());
      // url = url.replace('<ID>', attachment.id.toString());
      this._http.delete(url).subscribe(
        response => {
          console.log(response);
          let alert: string = $localize`Přiložený soubor %Name byl úspěšně odstraněn.`;
          alert = alert.replace('%Name', attachment.name);
          this._notificationService.alert(alert, 'success', 3500);
        },
        error => {
          console.log(error);
          // error alert
          let alert: string = $localize`Chyba při odstranění přiloženého souboru %Name.`;
          alert = alert.replace('%Name', attachment.name);
          this._notificationService.alert(alert, 'error', 3500);
        }
      );
    }
  }
}
