import { Obligation } from "./obligation.object";
import { Attachment } from "./attachment.object";
import { ServiceTask } from "./service-task.object";
import { Vehicle } from "./vehicle.object";
import { Trailer } from "./trailer.object";

export class InvoiceItem {
  private _item_key: number;
  public get item_key(): number {
    return this._item_key;
  }
  public set item_key(value: number) {
    this._item_key = value;
  }

  private _invoice_key: number;
  get invoice_key(): number {
    return this._invoice_key;
  }
  set invoice_key(value: number) {
    this._invoice_key = value;
  }

  private _company_key: number;
  public get company_key(): number {
    return this._company_key;
  }
  public set company_key(value: number) {
    this._company_key = value;
  }

  private _amount: number;
  public get amount(): number {
    return this._amount;
  }
  public set amount(value: number) {
    this._amount = value;
  }
  
  private _discount: number = 0;
  public get discount(): number {
    return this._discount;
  }
  public set discount(value: number) {
    this._discount = value;
  }
  
  private _price: number;
  public get price(): number {
    return this._price;
  }
  public set price(value: number) {
    this._price = value;
  }

  // custom just for view
  public get price_discount(): number {
    return this._price - (this._price * (this._discount / 100));
  }

  public get price_all_discount(): number {
    let price = this._amount * (this._price - (this._price * (this._discount / 100)));
    return Math.round(price * 100) / 100;
  }

  private _price_total: number;
  public get price_total(): number {
    return this._price_total;
  }
  public set price_total(value: number) {
    this._price_total = value;
  }

  private _name: string;
  public get name(): string {
    return this._name;
  }
  public set name(value: string) {
    this._name = value;
  }
  
  // name formatting to html
  public get getItemNameFormatted(): string {
    // https://stackoverflow.com/a/784547/8226013
    return this._name.replace(/(?:\r\n|\r|\n)/g, '<br/>');
  }
  
  private _vat: number;
  public get vat(): number {
    return this._vat;
  }
  public set vat(value: number) {
    this._vat = value;
  }

  private _vat_rate: number;
  public get vat_rate(): number {
    return this._vat_rate;
  }
  public set vat_rate(value: number) {
    this._vat_rate = value;
  }

  // custom property for association of item with obligation (issued invoice)
  private _obligation: Obligation;
  public get obligation(): Obligation {
    return this._obligation;
  }
  public set obligation(value: Obligation) {
    this._obligation = value;
  }

  // there could be multiple tariffs - obligation details showed only once
  private _showObligationSetup: boolean = true;
  public get showObligationSetup(): boolean {
    return this._showObligationSetup;
  }
  public set showObligationSetup(value: boolean) {
    this._showObligationSetup = value;
  }

  // flag for checking if obligation item should be invoiced(changed its status as well)
  private _change_obligation_status: boolean = true;
  public get change_obligation_status(): boolean {
    return this._change_obligation_status;
  }
  public set change_obligation_status(value: boolean) {
    this._change_obligation_status = value;
  }

  // custom property for binding invoice item to dial item
  private _dial_item_key: string = null;
  public get dial_item_key(): string {
    return this._dial_item_key;
  }
  public set dial_item_key(value: string) {
    this._dial_item_key = value;
  }

  // custom booleans used in invoice name formatting
  private _intro_flag: boolean = true;
  public get intro_flag(): boolean {
    return this._intro_flag;
  }
  public set intro_flag(value: boolean) {
    this._intro_flag = value;
  }

  private _order_number_flag: boolean = true;
  public get order_number_flag(): boolean {
    return this._order_number_flag;
  }
  public set order_number_flag(value: boolean) {
    this._order_number_flag = value;
  }
  
  private _code_flag: boolean = true;
  public get code_flag(): boolean {
    return this._code_flag;
  }
  public set code_flag(value: boolean) {
    this._code_flag = value;
  }
  
  private _delivery_note_flag: boolean = true;
  public get delivery_note_flag(): boolean {
    return this._delivery_note_flag;
  }
  public set delivery_note_flag(value: boolean) {
    this._delivery_note_flag = value;
  }
  
  private _obligation_number_flag: boolean = true;
  public get obligation_number_flag(): boolean {
    return this._obligation_number_flag;
  }
  public set obligation_number_flag(value: boolean) {
    this._obligation_number_flag = value;
  }

  private _number_plate_flag: boolean = true;
  public get number_plate_flag(): boolean {
    return this._number_plate_flag;
  }
  public set number_plate_flag(value: boolean) {
    this._number_plate_flag = value;
  }

  private _info_flag: boolean = true;    
  public get info_flag(): boolean {
    return this._info_flag;
  }
  public set info_flag(value: boolean) {
    this._info_flag = value;
  }
  
  private _it_mark_flag: boolean = true;    
  public get it_mark_flag(): boolean {
    return this._it_mark_flag;
  }
  public set it_mark_flag(value: boolean) {
    this._it_mark_flag = value;
  }

  private _route_length_plan_flag: boolean = false;
  public get route_length_plan_flag(): boolean {
    return this._route_length_plan_flag;
  }
  public set route_length_plan_flag(value: boolean) {
    this._route_length_plan_flag = value;
    // only one route length should be switched off
    if (value) {
      this._route_length_real_flag = false;
      this._route_length_total_flag = false;
      this._route_length_airline_flag = false;
    }
  }

  private _route_length_real_flag: boolean = true;
  public get route_length_real_flag(): boolean {
    return this._route_length_real_flag;
  }
  public set route_length_real_flag(value: boolean) {
    this._route_length_real_flag = value;
    // only one route length should be switched off
    if (value) {
      this._route_length_plan_flag = false;
      this._route_length_total_flag = false;
      this._route_length_airline_flag = false;
    }
  }

  private _route_length_total_flag: boolean = false;
  public get route_length_total_flag(): boolean {
    return this._route_length_total_flag;
  }
  public set route_length_total_flag(value: boolean) {
    this._route_length_total_flag = value;
    // only one route length should be switched off
    if (value) {
      this._route_length_plan_flag = false;
      this._route_length_real_flag = false;
      this._route_length_airline_flag = false;
    }
  }
  
  private _route_length_airline_flag: boolean = false;
  public get route_length_airline_flag(): boolean {
    return this._route_length_airline_flag;
  }
  public set route_length_airline_flag(value: boolean) {
    this._route_length_airline_flag = value;
    // only one route length should be switched off
    if (value) {
      this._route_length_plan_flag = false;
      this._route_length_real_flag = false;
      this._route_length_total_flag = false;
    }
  }
  
  private _it_address_flag: boolean = true;
  public get it_address_flag(): boolean {
    return this._it_address_flag;
  }
  public set it_address_flag(value: boolean) {
    this._it_address_flag = value;
  }

  private _it_date_flag: boolean = true;
  public get it_date_flag(): boolean {
    return this._it_date_flag;
  }
  public set it_date_flag(value: boolean) {
    this._it_date_flag = value;
  }

  private _it_time_flag: boolean = true;
  public get it_time_flag(): boolean {
    return this._it_time_flag;
  }
  public set it_time_flag(value: boolean) {
    this._it_time_flag = value;
  }
  
  private _it_times_flag: boolean = true;
  public get it_times_flag(): boolean {
    return this._it_times_flag;
  }
  public set it_times_flag(value: boolean) {
    this._it_times_flag = value;
  }
  
  private _it_note_flag: boolean = false;
  public get it_note_flag(): boolean {
    return this._it_note_flag;
  }
  public set it_note_flag(value: boolean) {
    this._it_note_flag = value;
  }

  // custom property for invoicing code string
  private _invoicingCode: string = '';
  public get invoicingCode(): string {
    return this._invoicingCode;
  }
  public set invoicingCode(value: string) {
    this._invoicingCode = value;
  }

  // custom property for attachments and files from obligation
  private _attachments: Array<Attachment> = [];
  public get attachments(): Array<Attachment> {
    return this._attachments;
  }
  public set attachments(value: Array<Attachment>) {
    this._attachments = value;
  }

  // custom flags for tariffs
  private _tunaTariff: boolean = false;
  public get tunaTariff(): boolean {
    return this._tunaTariff;
  }
  public set tunaTariff(value: boolean) {
    this._tunaTariff = value;
  }
  
  private _trasaTariff: boolean = false;
  public get trasaTariff(): boolean {
    return this._trasaTariff;
  }
  public set trasaTariff(value: boolean) {
    this._trasaTariff = value;
  }

  private _loadingLimitTariff: boolean = false;
  public get loadingLimitTariff(): boolean {
    return this._loadingLimitTariff;
  }
  public set loadingLimitTariff(value: boolean) {
    this._loadingLimitTariff = value;
  }
  
  private _fuelTariff: boolean = false;
  public get fuelTariff(): boolean {
    return this._fuelTariff;
  }
  public set fuelTariff(value: boolean) {
    this._fuelTariff = value;
  }

  private _type: string = null;
  public get type(): string {
    return this._type;
  }
  public set type(value: string) {
    this._type = value;
  }

  private _obligation_key: number = null;
  public get obligation_key(): number {
    return this._obligation_key;
  }
  public set obligation_key(value: number) {
    this._obligation_key = value;
  }

  private _order_key: number = null;
  public get order_key(): number {
    return this._order_key;
  }
  public set order_key(value: number) {
    this._order_key = value;
  }

  private _driver_key: number = null;
  public get driver_key(): number {
    return this._driver_key;
  }
  public set driver_key(value: number) {
    this._driver_key = value;
  }

  private _car_key: number = null;
  public get car_key(): number {
    return this._car_key;
  }
  public set car_key(value: number) {
    this._car_key = value;
  }

  // custom object
  private _car: Vehicle = null;
  public get car(): Vehicle {
    return this._car;
  }
  public set car(value: Vehicle) {
    this._car = value;
  }
  
  private _trailer_key: number = null;
  public get trailer_key(): number {
    return this._trailer_key;
  }
  public set trailer_key(value: number) {
    this._trailer_key = value;
  }

  // custom object
  private _trailer: Trailer = null;
  public get trailer(): Trailer {
    return this._trailer;
  }
  public set trailer(value: Trailer) {
    this._trailer = value;
  }

  private _task_key: number = null;
  public get task_key(): number {
    return this._task_key;
  }
  public set task_key(value: number) {
    this._task_key = value;
  }
  
  // custom object
  private _task: ServiceTask = null;
  public get task(): ServiceTask {
    return this._task;
  }
  public set task(value: ServiceTask) {
    this._task = value;
  }

  // custom
  private _itemIndex: number = 0;
  public get itemIndex(): number {
    return this._itemIndex;
  }
  public set itemIndex(value: number) {
    this._itemIndex = value;
  }
  
  // custom
  private _itemSubIndex: number = 0;
  public get itemSubIndex(): number {
    return this._itemSubIndex;
  }
  public set itemSubIndex(value: number) {
    this._itemSubIndex = value;
  }

  // custom
  private _startBorderTop: boolean = false;
  public get startBorderTop(): boolean {
    return this._startBorderTop;
  }
  public set startBorderTop(value: boolean) {
    this._startBorderTop = value;
  }
  
  // custom
  private _endBorderBottom: boolean = false;
  public get endBorderBottom(): boolean {
    return this._endBorderBottom;
  }
  public set endBorderBottom(value: boolean) {
    this._endBorderBottom = value;
  }


  public get apiObject(): any {
    return {
      amount: this.amount,
      discount: this.discount,
      price: this.price,
      price_total: this.price_total,
      name: this.name,
      vat: this.vat,
      vat_rate: this.vat_rate,
      obligation_key: this.obligation_key,
      order_key: this.order_key,
      car_key: this.car_key,
      driver_key: this.driver_key,
      trailer_key: this.trailer_key,
      task_key: this.task_key,
      type: this.type
    };
  }
}