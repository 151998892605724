import { DatePipe } from '@angular/common';
import { AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { AetrCollectionPartInterface } from 'src/app/interface/aetr-collection-part.interface';
import { UserConfigurationInterface } from 'src/app/interface/user-configuration.interface';
import { VehicleListConfigurationInterface } from 'src/app/interface/vehicle-list-configuration.interface';
import { AetrDataCollectionObject } from 'src/app/model/aetr-data-collection.object';
import { Company } from 'src/app/model/company.object';
import { ExternalImportAvailable } from 'src/app/model/external-import-available.object';
import { ExternalImport } from 'src/app/model/external-import.object';
import { GanttDriveDiagramEvent } from 'src/app/model/gantt-drive-diagram-event.object';
import { GoogleMapMarker } from 'src/app/model/google-map-marker.object';
import { Vehicle } from 'src/app/model/vehicle.object';
import { CompanyService } from 'src/app/service/company.service';
import { EmailingService } from 'src/app/service/emailing.service';
import { ExternalService } from 'src/app/service/external.service';
import { NotificationService } from 'src/app/service/notification-service';
import { MessageService } from 'src/app/service/message.service';
import { WebsocketService } from 'src/app/service/websocket.service';
import { TruckManagerLayoutService } from 'src/app/service/truck-manager-layout.service';
import { UserConfigurationService } from 'src/app/service/user-configuration.service';
import { WebConnectivityService } from 'src/app/service/web.connectivity.service';
import { VehicleNewService } from 'src/app/service/vehicle-new.service';
import { SmsService } from 'src/app/service/sms.service';
import { DateTools } from 'src/app/tools/DateTools';
import { IS_DEMO, RECAPTCHA_SITE_KEY, ServiceType } from 'src/app/config';
import { WebsocketResponse } from 'src/app/interface/websocket-response.interface';
import { ServiceEventObject } from 'src/app/model/service-event.object';
import { Itinerary } from 'src/app/model/itinerary.object';
import { TmAgendaService } from 'src/app/service/tm-agenda.service';
import { ObligationService } from 'src/app/service/obligation.service';
import { ServiceEventService } from 'src/app/service/service-event.service';

// declare let gtag: Function;
declare var $: any;

@Component({
  selector: 'app-r-vehicle-list-light',
  templateUrl: './r-vehicle-list-light.component.html',
  styleUrls: ['./r-vehicle-list-light.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    VehicleNewService,
    EmailingService,
    ServiceEventService,
    SmsService,
    ObligationService
  ]
})
export class RVehicleListLightComponent implements OnInit, OnDestroy, AfterViewChecked {

  private _subscribed: Array<Subscription> = [];

  // user config
  private _userConfig: UserConfigurationInterface;
  private _vehiclesConfig: VehicleListConfigurationInterface;
  public get vehiclesConfig(): VehicleListConfigurationInterface {
    return this._vehiclesConfig;
  }
  
  private _vehicles: Array<Vehicle> = [];
  public get vehicles(): Array<Vehicle> {
    return this._vehicles;
  }
  vehiclesTrackBy(index: number, item: Vehicle) {
    return item.car_key;
  }

  private _company: Company = null;;

  // our available fms external sources
  private _externalImportsAvailable: Array<ExternalImportAvailable> = [];
  // possibly external fms external sources for all cars
  private _externalImportsCars: Array<ExternalImport> = [];
  // user authenticated fms external sources setup
  private _externalImportsSetup: Array<ExternalImport> = [];
  
  // flag for connection
  private _notConnected: boolean = false;
  get notConnected(): boolean {
    return this._notConnected;
  }

  // time of last loaded connection 
  private _lastLoadDate: Date = new Date();
  public get lastLoadDate(): Date {
    return this._lastLoadDate;
  }

  private _delayedLoading: boolean = false;
  public get delayedLoading(): boolean {
    return this._delayedLoading;
  }

  constructor(
    private _configurationServ: UserConfigurationService,
    private _webConnectivityServ: WebConnectivityService,
    private _notificationServ: NotificationService,
    private _vehicleService: VehicleNewService,
    private _webSocketServ: WebsocketService,
    private _layout: TruckManagerLayoutService,
    private _externalServ: ExternalService,
    private _emailingServ: EmailingService,
    private _smsServ: SmsService,
    private _serviceEventServ: ServiceEventService,
    private _obligationServ: ObligationService,
    private _messageServ: MessageService,
    private _companyServ: CompanyService,
    private _datePipe: DatePipe,
    private _router: Router,
    private _cdr: ChangeDetectorRef
  ) {
    // if (!window.location.href.includes('app2') && !window.location.href.includes('localhost')) {
    //   gtag('config', 'UA-116483982-1', { 'page_path': 'dashboard_auta_light' });
    // }

    // load user configuration  
    this._userConfig = JSON.parse(JSON.stringify(this._configurationServ.configuration));
    this._vehiclesConfig = this._configurationServ.configuration.defaultVehicleListConfiguration;

    // set fms status each 3 minutes
    let MINUTE_INTERVAL: number = 180000;
    window.setInterval(
      () => {
        if (this._vehicles.length) {
          this._vehicles.forEach(
            v => {
              this.setFmsStatus(v);
            }
          );
        }
      }, MINUTE_INTERVAL  
    );

    // custom change detection strategy
    this._cdr.detach();
    setInterval(
      () => {
        this._vehicles.forEach(
          v => {
            if (v.last_cargo_activity) {
              v.last_cargo_activity.set_event_took_parse();
            }
          }
        );
        this._cdr.detectChanges();
      }, 5000
    );
  }

  ngOnInit(): void {
    this.loadData();
  }

  ngOnDestroy(): void {
    this._subscribed.forEach(
      s => { s.unsubscribe(); }
    );
    this._subscribed = [];
  }

  ngAfterViewChecked(): void {
    // Called after every check of the component's view. Applies to components only.
    // HOT FIX for ExpressionChangedAfterItHasBeenCheckedError
    // this._vehicles.forEach(
    //   v => {
    //     if (v.last_cargo_activity) {
    //       v.last_cargo_activity.set_event_took_parse();
    //     }
    //   }
    // );
    // console.log('Here we go');
    // this._cdr.detectChanges();
  } 
  
  detectChanges(): void {
    // detect changes 500 ms after change
    window.setTimeout(
      () => {
        this._cdr.detectChanges();
      }, 100
    );
  }


  /********************************************************/
  /* Loading logic */
  /********************************************************/
  private defaultInitialize(): void {
  }

  private loadData(): void {
    this.defaultInitialize();

    this._subscribed.push(
      this._vehicleService.getFilteredVehiclesCache().subscribe(
        (vehicles: Array<Vehicle>) => {
          if (vehicles && vehicles.length && vehicles != this._vehicles) {
            this._vehicles = vehicles;
            this.findExternalImports();

            // set flag for delayed loading spinner
            this._delayedLoading = true;
            this.detectChanges();

            // little delay for loading aetr and health data
            let TIMEOUT_DELAY: number = 1000 + this._vehicles.length * 20;
            window.setTimeout(
              () => {
                this._vehicles.forEach(
                  v => {
                    v.aetr_lazyload = this._vehicleService.createAetrLazyloader(v);
                    v.latestAetrLazyLoad = this._vehicleService.createLatestAetrLazyload(v);
                    v.health_lazyload = this._vehicleService.createVehicleHealthLazyload(v);
                    // invoke health getter
                    let tmp = v.health;
    
                    // add AETR observables
                    this._subscribed.push(
                      v.aetr_loaded.subscribe(
                        aetr => {
                          if (aetr) {
                            this._aetr_collection = null;
                            this._aetr_collection = aetr;
                            this.runWorkloadCurrent();
                          }
                        }
                      )
                    );
                  }
                );
                
                // unset flag for delayed loading spinner
                this._delayedLoading = false;
                this.detectChanges();
              }, TIMEOUT_DELAY
            );

            // little more delay for loading destination routes
            let TIMEOUT_DELAY2: number = 2000 + this._vehicles.length * 30;
            window.setTimeout(
              () => {
                this._vehicles.forEach(
                  v => {
                    this._vehicleService.loadDestinationRoute(v);
                  }
                );
              }, TIMEOUT_DELAY2
            );
          }
          else {
            this._vehicles = [];
          }
          // custom detection strategy
          this.detectChanges();
        }
      ),
      this._companyServ.getCompanyFullObservable().subscribe(
        company => {
          if (company) {
            this._company = company;
            // custom detection strategy
            this.detectChanges();
          } 
        }
      ),
      this._externalServ.getExternalImportsAvailable().subscribe(
        imports => {
          if (imports && imports.length) {
            this._externalImportsAvailable = imports;
            // console.log(this._externalImportsAvailable);
            
            // load existing external import setups
            this._subscribed.push(
              this._externalServ.getExternalImports().subscribe(
                imports2 => {
                  if (imports2 && imports2.length) {
                    this._externalImportsSetup = imports2;
                    // console.log(this._externalImportsSetup);
                    // handle fms imports 
                    this.findExternalImports();
                  }
                }
              )
            )

            // load existing external import cars
            this._externalImportsAvailable.forEach(
              i => {
                this._subscribed.push(
                  this._externalServ.getExternalImportsCars(i.import_type).subscribe(
                    imports3 => {
                      if (imports3 && imports3.length) {
                        this._externalImportsCars = this._externalImportsCars.concat(imports3);
                        // console.log(imports3);
                        // handle fms imports 
                        this.findExternalImports();
                      }
                    }
                  )
                )
              }
            );
          }
        }
      ),
      // handle websockets of creating/updating/deleting obligation -> for purpose in gannt-drive-diagram
      this._webSocketServ.companyMessage.subscribe(
        (ws: WebsocketResponse) => {
          let record = ws.record;
          if (ws.relation === WebsocketService.RELATIONS.obligation) {
            if (ws.operation == WebsocketService.OPERATIONS.delete) {
              // just complete vehicles reload
              this._vehicleService.getFilteredVehiclesCache(true, true).subscribe();
            }
            else {
              // set some timeout because of possible itinerary creating 
              window.setTimeout(
                () => {
                  this._vehicleService.reloadVehicleAgenda(record.car_key, record.obligation_key);
                },
                2000
              );
            }
          }
          else if (ws.relation === WebsocketService.RELATIONS.car && ws.operation == WebsocketService.OPERATIONS.insert) {
            this._vehicleService.getFilteredVehiclesCache(true).subscribe();
          }
          else if (ws.relation === WebsocketService.RELATIONS.external_service_import) {
            // operation update
            let setup = this._externalImportsSetup.find(s => s.import_type == record.import_type);
            if (setup) {
              setup.last_import_time = record.last_import_time;
              // reinit import in vehicle object
              this.findExternalImports();
              // custom detection strategy
              this.detectChanges();
            }
          }
          else if (ws.relation === WebsocketService.RELATIONS.external_service_import_car) {
            // operation update
            let fms_import = this._externalImportsCars.find(
              i => i.car_key == record.car_key || i.ext_id == record.ext_id
            );
            if (fms_import) {
              fms_import.last_import_time = record.last_import_time;
              fms_import.last_imported_rec_time = record.last_imported_rec_time;
              // reinit import in vehicle object
              this.findExternalImports();
              // custom detection strategy
              this.detectChanges();
            }
          }
        }
      ),
      this._webConnectivityServ.isConnectionUp().subscribe(
        lastUpTime => {
          this._lastLoadDate = lastUpTime;
          this._notConnected = false;
        }
      ),
      this._webConnectivityServ.connectionDown.subscribe(
        notConnected => {
          this._notConnected = notConnected;
        }
      )
    );
  }
  

  /********************************************************/
  /* Refresh */
  /********************************************************/
  refreshVehicles(): void {
    this._vehicleService.getFilteredVehiclesCache(true).subscribe();
  }


  /********************************************************/
  /* Getters */
  /********************************************************/
  get loadingVehicles(): boolean {
    return this._vehicleService.loadingVehicles;
  }
  
  get lastVehicleLoad(): Date {
    return this._vehicleService.lastLoadDate;
  }

  private readonly _DEMO_CAR_KEYS: Array<number> = [
    2856, 2853, 2859, 2858, 2854, 1710, 1737, 2855, 2862, 2863
  ];
  // demo cars should be always OK, they do not send health connection stuff
  isDemoCar(vehicle: Vehicle): boolean {
    // demo car_keys are 
    return this._DEMO_CAR_KEYS.includes(vehicle.car_key);
  }

  get icons(): any {
    return GoogleMapMarker.icons;
  }
  
  getVehicleDetailHeight(vehicle: Vehicle): string {
    return this._layout.getVehicleDetailHeight(vehicle);
  }
  
  // switch for version of dashboard - full
  setDashboardFull() {
    this._userConfig.defaultVehicleListConfiguration.dashboard = 'full';
    this._configurationServ.saveUserConfiguration(this._userConfig);
  }
  
  get tariffOnlyAgenda(): boolean {
    return this._company && (this._company.tariff == '17' || this._company.tariff == '23');
  }
  
  private TODAY: Date = new Date();
  // get flag if user has valid access = true, expired access = false
  get validAccess(): boolean {
    if (IS_DEMO) return true;
    if (!this._layout.user || !this._layout.user.admittanceDateExtended) return false;
    return this._layout.user.admittanceDateExtended > this.TODAY;
  }



  /*******************************************/
  /* External FMS imports stuff */
  /*******************************************/
  findExternalImports(): void {
    // fms
    if (this._vehicles.length && this._externalImportsCars.length && this._externalImportsSetup.length) {
      this._vehicles.forEach(
        v => {
          let i = this._externalImportsCars.find(
            i => i.ext_id == v.vin || i.car_key == v.car_key
          );
          if (i) {
            let setup = this._externalImportsSetup.find(s => i.import_type == s.import_type);
            if (setup) {
              i.last_service_import_time = setup.last_import_time;
            }
            // save it to vehicle
            v.fmsSource = i.import_type;
            v.fmsImport = i;
            this.setFmsStatus(v);
            // custom detection strategy
            this.detectChanges();
          }
        }
      );
    }
  }

  // set FMS_OK/ERROR/NO_CONNECTION
  setFmsStatus(v: Vehicle) {
    if (v && v.fmsImport) {
      v.fmsImport.setFmsStatus();
      // custom detection strategy
      this.detectChanges();
    }
  }


  /*******************************************/
  /* Control vehicle details */
  /*******************************************/
  controlShowVehicleDiagnosticsDetail(vehicle: Vehicle) {
    this.hideAllDetails(vehicle);
    this.setNewDetail(vehicle, Vehicle.DETAIL_TYPE_DIAGNOSTICS);
  }

  controlShowMap(vehicle: Vehicle) {
    this.hideAllDetails(vehicle);
    this.setNewDetail(vehicle, Vehicle.DETAIL_TYPE_LAST_POSITION_MAP);
    
    if (!vehicle.trackingDataLazyload) {
      vehicle.trackingDataLazyload = this._vehicleService.createVehicleTrackingEventsLazyload(vehicle);
    }
    if (!vehicle.latestServiceEventsLazyLoad) {
      vehicle.latestServiceEventsLazyLoad = this._vehicleService.createLatestServiceEventsLazyload(vehicle);
    }
  }

  controlShowMapCargo(vehicle: Vehicle) {
    this.hideAllDetails(vehicle);
    this.setNewDetail(vehicle, Vehicle.DETAIL_TYPE_LAST_CARGO_MAP);
  }

  controlShowVehicleMessages(vehicle: Vehicle) {
    this.hideAllDetails(vehicle);
    this.setNewDetail(vehicle, Vehicle.DETAIL_TYPE_VEHICLE_MESSAGES);
  }

  controlShowVehicleActivity(vehicle: Vehicle) {
    this.hideAllDetails(vehicle);
    this.setNewDetail(vehicle, Vehicle.DETAIL_TYPE_ACTIVITY_HISTORY);
  }

  controlAgendaDetail(gantDiagramEvent: GanttDriveDiagramEvent) {
    this._vehicles.forEach(
      v => {
        if (v.agenda && v.agenda.indexOf(gantDiagramEvent.agenda) > -1) {
          this.hideAllDetails(v);
          v.detail_type = Vehicle.DETAIL_TYPE_AGENDA_DETAIL;
          v.detail_visible = v.agenda_detail !== gantDiagramEvent.agenda || !v.is_detail_visible;
          v.setAgendaDetail(gantDiagramEvent.agenda.obligation_key);
          return true;
        }
      }
    );
  }

  hideAllDetails(currentVehicle?: Vehicle) {
    this._vehicles.forEach(
      vehicle => {
        if (currentVehicle !== vehicle) {
          this.hideVehicleDetail(vehicle);
        }
      }
    );
  }
  
  private hideVehicleDetail(vehicle: Vehicle, mustHaveDetailType?: string): boolean {
    if (mustHaveDetailType && vehicle.detail_type != mustHaveDetailType) {
      return false;
    }
    vehicle.detail_visible = false;
    vehicle.detail_type = null;
    // custom detection strategy
    this.detectChanges();
    return true;
  }

  private setNewDetail(vehicle: Vehicle, type: string) {
    vehicle.detail_visible = !vehicle.is_detail_visible || vehicle.detail_type !== type;
    if (vehicle.is_detail_visible) {
      vehicle.detail_type = type;
    } 
    else {
      vehicle.detail_type = null;
    }
    // custom detection strategy
    this.detectChanges();
  }


  /*******************************************/
  /* Popover menu features */
  /*******************************************/
  controlShowVehicleInfo(vehicle: Vehicle) {
    let url: string = this._layout.staticContentResource + 'manager/car_detail.php?car_key=' + vehicle.car_key;
    window.open(url, 'popup', 'width=580,height=650');
  }

  configureManualTracking(vehicle: Vehicle) {
    this._router.navigate([{outlets: {left: ['manual-tracking-full', vehicle.car_key]}}]);
  }


  /*******************************************/
  /* Hotline email/sms methods */
  /*******************************************/
  // set the vehicle hotline
  public vehicleHotline: Vehicle = null;
  public driverName: string = '';
  public driverPhone: string = '';

  get companyName(): string {
    return this._companyServ.company;
  }

  // method for initializing vehicle object
  initVehicleHotline(vehicle: Vehicle): void {
    if (vehicle) {
      this.vehicleHotline = vehicle;
      this.driverName = vehicle.driver_name;
      this.driverPhone = vehicle.driver_phone;
      // custom detection strategy
      this.detectChanges();
    }
  }


  // method for sending email with all data
  sendEmailSMS() : void {
    // defaults (our TM)
    let subject: string = 'Hotline - firma: ' + this._companyServ.company_id;
    let emails_to: Array<string> = [ 'info@truckmanager.eu' ];

    let text: string = $localize`GPS jednotka nemá datové spojení.\n\n`;
    text += $localize`Název firmy      : ` + this._companyServ.company + '\n';
    text += $localize`ID firmy         : ` + this._companyServ.company_id + '\n';
    text += $localize`SPZ vozidla      : ` + this.vehicleHotline.number_plate + '\n';
    text += $localize`Řidič            : ` + this.driverName + '\n';
    text += $localize`Telefon řidiče   : ` + this.driverPhone + '\n';
    text += $localize`GPS jednotka     : ` + this.vehicleHotline.trackingDeviceCodenameFormatted + '\n';
    text += $localize`Poslední restart : ` + this.vehicleHotline.trackingDeviceRuntimeFormatted + '\n';
    text += $localize`Běh aplikace     : ` + this.vehicleHotline.trackingTmRuntimeFormatted + '\n';
    text += $localize`Stav baterie     : ` + this.vehicleHotline.trackingDeviceBatteryChargeLevelFormatted + '\n';
    text += $localize`Poslední přenos  : ` + this.vehicleHotline.trackingLastTimeFormatted + '\n';
    text += $localize`Verze TM         : ` + this.vehicleHotline.trackingTmVersionFormatted + '\n';

    // specific for VOLVO / RENAULT
    if (this.vehicleHotline.fmsSource == 'VOLVO' || this.vehicleHotline.fmsSource == 'RENLT') {
      subject = $localize`Výpadek VOLVO Connect - firma: ` + this._companyServ.company;
      
      emails_to.push('volvoconnect.cz@volvo.com');

      text = $localize`GPS jednotka nemá datové spojení.\n\n`;
      text += $localize`Název firmy      : ` + this._company.company + '\n';
      text += $localize`ID firmy         : ` + this._company.id + '\n';
      text += $localize`IČ firmy         : ` + this._company.cin + '\n';
      text += $localize`SPZ vozidla      : ` + this.vehicleHotline.number_plate + '\n';
      text += 'VIN              : ' + this.vehicleHotline.vin + '\n';
      if (this.vehicleHotline.fmsImport && this.vehicleHotline.fmsImport.lastRecTimeDate) {
        text += $localize`Poslední odpověď`;
        text += (this.vehicleHotline.fmsSource == 'VOLVO' ? 'VOLVO' : 'RENAULT') + ' : ';
        text += this._datePipe.transform(
          this.vehicleHotline.fmsImport.lastRecTimeDate, 'dd.MM.yyyy HH:mm'
        );
        text += '\n';
      } 
      text += $localize`Datum a čas nahlášení: `;
      text += this._datePipe.transform(new Date(), 'dd.MM.yyyy HH:mm');
      text += '\n';
    }


    let emailObj: any = {
      from: 'info@truckmanager.eu',
      to: emails_to,
      reply_to: 'info@truckmanager.eu',
      subject: subject,
      body: text,
      content_type: 'text/plain'
    };

    this._subscribed.push(
      this._emailingServ.sendEmail(emailObj).subscribe()
    );

    if (this.vehicleHotline.fmsSource != 'VOLVO' && this.vehicleHotline.fmsSource != 'RENLT') {
      // send also SMS
      this.sendSMS();
    }
  }

  public readonly RECAPTCHA_KEY: string = RECAPTCHA_SITE_KEY;

  public recaptcha_resolved = null; 
  recaptchaResolved(response: string) {
    this.recaptcha_resolved = response;
  }

  // method for sending sms to driver
  sendSMS(): void {
    // send sms
    if (!this.driverPhone) return;
    let msg: string = '#msg#Od TruckManager: p. ';
    msg += this.driverName;
    msg += ', vas TM neprenasi data! Restartujte prosim telefon popr. volejte Hotline +420737288090 !';

    if (this.recaptcha_resolved) {
      this._smsServ.createSMS(msg, this.driverPhone, this.recaptcha_resolved).subscribe();
    }
    else {
      console.log('Info SMS has not been sent (no recaptcha).');
      this._notificationServ.alert($localize`Kliknutím potvrďte prosím reCaptcha`, 'error', 4000);
    }
  }
  

  /********************************************************/
  /* Aetr light version */
  /********************************************************/
  /* Stuff same as in aetr-row component */
  private _aetr_collection: AetrDataCollectionObject;
  public get aetr_collection(): AetrDataCollectionObject {
    return this._aetr_collection;
  }

  private _workloadCurrent: string = '0:00';
  public get workloadCurrent(): string {
    return this._workloadCurrent;
  }
  
  // method for reloading from api again
  getAetr(v: Vehicle): void {
    // reinit local properties
    this._aetr_collection = null;
    this._workloadCurrent = null;

    v.aetr_lazyload = null;
    v.aetr_lazyload = this._vehicleService.createAetrLazyloader(v);
  }

  runWorkloadCurrent(): void {
    if (this._aetr_collection && this._aetr_collection.pastSegment) {
      let segment: AetrCollectionPartInterface = this._aetr_collection.pastSegment;
      let now: Date = new Date();
      // div miliseconds with 1000 and 60 to minutes, and again 60 to hours
      let hours: number = (now.getTime() - segment.date_stop.getTime()) / (1000*60*60);
      let minutes: number = ((now.getTime() - segment.date_stop.getTime()) / (1000*60)) % 60;
      hours = Math.floor(hours);
      minutes = Math.floor(minutes);

      this._workloadCurrent = ((hours < 10 ? '0' : '') + hours.toString()) + ':' + ((minutes < 10 ? '0' : '') + minutes.toString());
      // custom change detection
      this.detectChanges();
    }
  }
  
  duration(segment: AetrCollectionPartInterface): string {
    return DateTools.getHumanReadHoursAndMinutes(segment.length * 1000);
  }
  
  doneDriving(segment: AetrCollectionPartInterface): string {
    return Math.round(segment.distance) + UserConfigurationService.global_length_unit;
  }
  
  distanceDriving(segment: AetrCollectionPartInterface): string {
    return Math.round(segment.distance) + ' ' + UserConfigurationService.global_length_unit;
  }
  

  /********************************************************/
  /* Vehicle status chart */
  /********************************************************/
  private _vehicleToChart: Vehicle = null;
  public get vehicleToChart(): Vehicle {
    return this._vehicleToChart;
  }
  public set vehicleToChart(value: Vehicle) {
    this._vehicleToChart = value;
    // custom detection strategy
    this.detectChanges();
  }

  sendInfoMessage(order: string): void {
    let message: string = '';
    message += $localize`Prosím prověřte, zda nemáte v aplikaci ještě naloženou zakázku číslo ` + order + '. ';
    message += $localize`Na hlavní obrazovce aplikace klikněte ve třetím textovém řádku "Nákl." `;
    message += $localize`na zelené číslo přepravované hmotnosti a zobrazí se vám seznam zakázek. `;
    message += $localize`Vyberte ty, které již nevezete a vyložte je. `;
    message += $localize`Případně volejte hotline +420737288090. Děkuji.`;

    this._subscribed.push(
      this._messageServ.sendMessageToVehicle(message, this.vehicleToChart).subscribe(
        message => {
          console.log(message);
          this._notificationServ.alert(
            $localize`Zpráva byla úspěšně odeslána - ` + this.vehicleToChart.number_plate, 'success', 3000
          );
        },
        error => {
          console.log(error);
          this._notificationServ.alert(
            $localize`Chyba při odesílání zprávy - ` + this.vehicleToChart.number_plate, 'error', 3000
          );
        }
      )
    );
  }


  /************************************************************/
  /* Methods for creating service event without TM */
  /************************************************************/
  public newServiceEvent: ServiceEventObject = new ServiceEventObject();

  finishOldOrders(car_key: number): void {
    let msg: string = $localize`Dispečer si vyžádal odstranění všech starých zakázek `; 
    msg += ' <#ord:%|||D>';
    this._subscribed.push(
      this._messageServ.sendMessageToVehicle2(msg, car_key).subscribe(
        message => {
          console.log(message);
          this._notificationServ.alert(
            $localize`Odstranění starých zakázek bude během několika minut provedeno.`, 'success', 5000
          );

          // hide modal for confirmation
          (<any>$('#vehicleChartModal')).modal('hide');
          // reload vehicles
          window.setTimeout(
            () => {
              this._vehicleService.getFilteredVehiclesCache(true).subscribe();
            }, 2000
          );
        },
        error => {
          console.log(error);
          this._notificationServ.alert(
            $localize`Nastala chyba při vyložení starých zakázek - kontaktujte podporu.`, 'success', 5000
          );
        }
      )
    );
  }

  createServiceEvent(order_number: string, vehicle: Vehicle): void {
    if (order_number && vehicle) {
      this.newServiceEvent = new ServiceEventObject();
      this.newServiceEvent.order_number = order_number;
      this.newServiceEvent.car_key = vehicle.car_key;
      this.newServiceEvent.driver_key = vehicle.driver_key;
      this.newServiceEvent.type = ServiceType.UNLOADING_END;
      if (vehicle.last_position) {
        this.newServiceEvent.pos_gps = vehicle.last_position.pos_gps;
        this.newServiceEvent.weight = vehicle.last_position.cargo_weight;
      }
      this.newServiceEvent.length = 0;
      this.newServiceEvent.containers_in = 0;
      this.newServiceEvent.containers_out = 0;
      this.newServiceEvent.descr = $localize`Oprava staré zakázky!`;


      this._subscribed.push(
        this._obligationServ.getObligationOrderNumberToComponent(order_number).subscribe(
          obligation => {
            if (obligation) {
              // last itinerary and use its time
              if (obligation && obligation.itinerary && obligation.last_itinerary_time) {
                this.newServiceEvent.time_custom = this._datePipe.transform(obligation.last_itinerary_time, 'yyyy-MM-ddTHH:mm');
                // this.newServiceEvent.time_custom = this._datePipe.transform(new Date(), 'yyyy-MM-ddTHH:mm');
              }
          
              this._subscribed.push(
                this._serviceEventServ.createServiceEvent(this.newServiceEvent).subscribe(
                  response => {
                    if (response) {
                      console.log(response);
                      // send also message to vehicle to dispose order
                      if (this.newServiceEvent && this.newServiceEvent.car_key && this.newServiceEvent.order_number) {
                        let msg: string = 'Dispečer si vyžádal odstranění staré zakázky ' + this.newServiceEvent.order_number; 
                        msg += ' <#ord:' + this.newServiceEvent.order_number + '|||D>';
                        this._subscribed.push(
                          this._messageServ.sendMessageToVehicle2(msg, this.newServiceEvent.car_key).subscribe(
                            message => {
                              console.log(message);
                            },
                            error => {
                              console.log(error);
                            }
                          )
                        );
                      }
                      // hide modal for confirmation
                      // (<any>$('#vehicleChartModal')).modal('hide');
                      // reload vehicles - no need
                      // this._vehicleService.getFilteredVehiclesCache(true).subscribe();
                    }
                  }
                )
              );
            }
          },
          error => {
            console.log(error);
            // set current time
            this.newServiceEvent.time_custom = this._datePipe.transform(new Date(), 'yyyy-MM-ddTHH:mm');
            // not founded obligation ~ driver specific order_number
            this._subscribed.push(
              this._serviceEventServ.createServiceEvent(this.newServiceEvent).subscribe(
                response => {
                  if (response) {
                    // send also message to vehicle to dispose order
                    if (this.newServiceEvent && this.newServiceEvent.car_key && this.newServiceEvent.order_number) {
                      let msg: string = 'Dispečer si vyžádal odstranění staré zakázky ' + this.newServiceEvent.order_number; 
                      msg += ' <#ord:' + this.newServiceEvent.order_number + '|||D>';
                      this._subscribed.push(
                        this._messageServ.sendMessageToVehicle2(msg, this.newServiceEvent.car_key).subscribe(
                          message => {
                            console.log(message);
                          },
                          error => {
                            console.log(error);
                          }
                        )
                      );
                    }
                    // hide modal for confirmation
                    // (<any>$('#vehicleChartModal')).modal('hide');
                    // reload vehicles - no need
                    // this._vehicleService.getFilteredVehiclesCache(true).subscribe();
                  }
                }
              )
            );
          }
        )
      );
    }
  }

  
  /********************************************************/
  /* Routing */
  /********************************************************/
  openSubsNewTab() {
    let url: any = null;
    let queryParams: any = {
      reloadPossible: true
    }
    url = this._router.serializeUrl(
      this._router.createUrlTree(
        [{outlets: {left: 'general/subscription', right: 'cars'}}], {queryParams: queryParams}
      )
    );
    this._router.navigateByUrl(url);
  }

}
